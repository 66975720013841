.div-login-admin {
    background: linear-gradient(180deg, #0885C7 0%, #0885C7 30%, #0675b0 70%, #0675b0 100%);
    min-width: 100%;
    min-height: 100%;
    position: fixed;
}

.form-login-admin {
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    width: 400px;
    position: fixed;
    top: 30%;
    left: calc(50% - 200px);
    padding: 20px 20px;
}

.p-titulo-login-admin {
    color: #0885C7;
    font-size: 24px;
    font-family: "Lato";
    font-weight: 600;
    text-align: center;
}

.form-field-row-admin {
    margin-top: 60px;
}