
.row-margin {
    margin-top: 5px;
}

.primera-angostura-button {
    height: 50px;
    width: 45px;
    background-color: #434343;
    position: fixed;
    top: 10%;
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 5px 0px;
}

.primera-angostura-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.primera-angostura-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.primera-angostura-icon {
    width: 30px;
    height: auto;
    margin-top: 8px;
}

.print-button {
    height: 50px;
    width: 45px;
    background-color: #FFCD00;
    position: fixed;
    top: calc(10% + 60px);
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 5px 0px;
}

.print-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.print-icon {
    width: 26px;
    height: auto;
    margin-top: 8px;
}

.print-text {
    color: #434343;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.pay-button {
    height: 50px;
    width: 45px;
    background-color: #0885C7;
    position: fixed;
    top: calc(10% + 120px);
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 5px 0px;
}

.pay-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.pay-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.pay-icon {
    width: 30px;
    height: auto;
    margin-top: 5px;
}

/*Magdalena*/


.magdalena-button {
    height: 50px;
    width: 45px;
    background-color: red;
    position: fixed;
    top: calc(10% + 180px);
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 5px 0px;
}

.magdalena-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.magdalena-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.magdalena-icon {
    width: 30px;
    height: auto;
    margin-top: 5px;
}

@media (min-width: 576px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .primera-angostura-button {
        height: 50px;
        width: 45px;
        background-color: #434343;
        position: fixed;
        top: 10%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 10px 10px 0px;
        opacity: 1;
        padding: 5px 0px;
    }
    
    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: none;
    }
    
    .primera-angostura-icon {
        width: 30px;
        height: auto;
        margin-top: 8px;
    }
    
    .print-button {
        height: 50px;
        width: 45px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(10% + 60px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 10px 10px 0px;
        opacity: 1;
        padding: 5px 0px;
    }
    
    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .print-icon {
        width: 26px;
        height: auto;
        margin-top: 8px;
    }
    
    .print-text {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: none;
    }
    
    .pay-button {
        height: 50px;
        width: 45px;
        background-color: #0885C7;
        position: fixed;
        top: calc(10% + 120px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 10px 10px 0px;
        opacity: 1;
        padding: 5px 0px;
    }
    
    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .pay-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: none;
    }
    
    .pay-icon {
        width: 30px;
        height: auto;
        margin-top: 5px;
    }

    /*Magdalena*/


.magdalena-button {
    height: 50px;
    width: 45px;
    background-color: red;
    position: fixed;
    top: calc(10% + 180px);
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 5px 0px;
}

.magdalena-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.magdalena-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.magdalena-icon {
    width: 30px;
    height: auto;
    margin-top: 5px;
}
}

@media (min-width: 768px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .primera-angostura-button {
        height: 90px;
        width: 60px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 9px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 12px;
        display: block;
    }
    
    .primera-angostura-icon {
        width: 28px;
        height: auto;
        margin-top: -6px;
    }
    
    .print-button {
        height: 90px;
        width: 60px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 100px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .print-text {
        color: #434343;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .print-icon {
        width: 28px;
        height: auto;
        margin-top: 0px;
    }
    
    .pay-button {
        height: 90px;
        width: 60px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 200px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }
    
    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .pay-text {
        color: #FFFFFF;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .pay-icon {
        width: 30px;
        height: auto;
        margin-top: 4px;
    }

    /*Magdalena*/


.magdalena-button {
    height: 90px;
    width: 60px;
    background-color: red;
    position: fixed;
    top: calc(20% + 300px);
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 15px 0px;
}

.magdalena-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.magdalena-text {
    color: #FFFFFF;
    font-size: 9px;
    font-family: 'Lato';
    font-weight: 600;
    line-height: 12px;
    display: block;
}

.magdalena-icon {
    width: 28px;
    height: auto;
    margin-top: -6px;
}
}

@media (min-width: 992px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .primera-angostura-button {
        height: 100px;
        width: 70px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .primera-angostura-icon {
        width: 32px;
        height: auto;
        margin-top: 0px;
    }
    
    .print-button {
        height: 100px;
        width: 70px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 110px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .print-text {
        color: #434343;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .print-icon {
        width: 32px;
        height: auto;
        margin-top: 2px;
    }
    
    .pay-button {
        height: 100px;
        width: 70px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 220px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }
    
    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .pay-text {
        color: #FFFFFF;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .pay-icon {
        width: 34px;
        height: auto;
        margin-top: 4px;
    }

    /*Magdalena*/
    .magdalena-button {
        height: 100px;
        width: 70px;
        background-color: red;
        position: fixed;
        top: calc(20% + 330px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .magdalena-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .magdalena-text {
        color: #FFFFFF;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .magdalena-icon {
        width: 32px;
        height: auto;
        margin-top: 2px;
    }
}

@media (min-width: 1200px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .primera-angostura-button {
        height: 120px;
        width: 80px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .primera-angostura-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }
    
    .print-button {
        height: 120px;
        width: 80px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 130px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .print-text {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .print-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }
    
    .pay-button {
        height: 120px;
        width: 80px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 260px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }
    
    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .pay-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .pay-icon {
        width: 42px;
        height: auto;
        margin-top: 8px;
    }

    /*Magdalena*/
    .magdalena-button {
        height: 120px;
        width: 80px;
        background-color: red;
        position: fixed;
        top: calc(20% + 390px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .magdalena-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .magdalena-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .magdalena-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }
}

@media (min-width: 1400px) { 
    .row-margin {
        margin-top: 5px;
    }
    
    .primera-angostura-button {
        height: 120px;
        width: 80px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .primera-angostura-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }
    
    .print-button {
        height: 120px;
        width: 80px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 130px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .print-text {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .print-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }
    
    .pay-button {
        height: 120px;
        width: 80px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 260px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }
    
    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .pay-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .pay-icon {
        width: 42px;
        height: auto;
        margin-top: 8px;
    }

     /*Magdalena*/
     .magdalena-button {
        height: 120px;
        width: 80px;
        background-color: red;
        position: fixed;
        top: calc(20% + 390px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .magdalena-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .magdalena-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .magdalena-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }
}

@media (min-width: 1600px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .primera-angostura-button {
        height: 130px;
        width: 100px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .primera-angostura-icon {
        width: 45px;
        height: auto;
        margin-top: 5px;
    }
    
    .print-button {
        height: 130px;
        width: 100px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 140px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }
    
    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .print-text {
        color: #434343;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .print-icon {
        width: 44px;
        height: auto;
        margin-top: 5px;
    }
    
    .pay-button {
        height: 130px;
        width: 100px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 280px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }
    
    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .pay-text {
        color: #FFFFFF;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }
    
    .pay-icon {
        width: 48px;
        height: auto;
        margin-top: 8px;
    }

    /*Magdalena*/
    .magdalena-button {
        height: 130px;
        width: 100px;
        background-color: red;
        position: fixed;
        top: calc(20% + 420px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }

    .magdalena-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .magdalena-text {
        color: #FFFFFF;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .magdalena-icon {
        width: 48px;
        height: auto;
        margin-top: 8px;
    }
}