.SolicitarUsuario {
    text-align: center;
}

.div-usuarios-s {
    width: 100%;
}

.img-usuarios-s {
    width: 100%;
    height: auto;
}

.div-usuarios-body {
    width: 100%;
    margin: 100px 0px 150px 0px;
}

.title-usuarios-s {
    color: #0885C7;
    font-size: 40px;
    font-family: 'Lato';
    font-weight: 600;
}

.row-content-ss-s {
    margin-bottom: 0px;
    text-align: justify;
}

.label-usuarios {
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    margin-top: 20px;
}

.form-field-usuarios {
    border: 1px #178DCB99 solid;
}

.form-field-usuarios-invalid {
    border: 1px red solid;
}

.form-text-usuarios-invalid {
    color: red;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-field-usuarios::placeholder {
    color: #64636399;
}

.btn-enviar-usuarios {
    width: 100%;
    background-color: #178DCB; 
    color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    padding: 10px;
    margin-top: 40px;
}

@media (min-width: 576px) {

    .div-usuarios-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-usuarios-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-ss-s {
        margin-bottom: 0px;
        text-align: justify;
    }
    
    .label-usuarios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 20px;
    }
    
    .form-field-usuarios {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-usuarios-invalid {
        border: 1px red solid;
    }
    
    .form-text-usuarios-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-usuarios::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-usuarios {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 40px;
    }
}

@media (min-width: 768px) {

    .div-usuarios-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-usuarios-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-ss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-usuarios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-usuarios {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-usuarios-invalid {
        border: 1px red solid;
    }
    
    .form-text-usuarios-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-usuarios::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-usuarios {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
}

@media (min-width: 992px) {

    .div-usuarios-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-usuarios-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-ss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-usuarios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-usuarios {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-usuarios-invalid {
        border: 1px red solid;
    }
    
    .form-text-usuarios-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-usuarios::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-usuarios {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
}

@media (min-width: 1200px) {

    .div-usuarios-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-usuarios-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-ss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-usuarios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-usuarios {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-usuarios-invalid {
        border: 1px red solid;
    }
    
    .form-text-usuarios-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-usuarios::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-usuarios {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
}

@media (min-width: 1400px) {

    .div-usuarios-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-usuarios-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-ss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-usuarios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-usuarios {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-usuarios-invalid {
        border: 1px red solid;
    }
    
    .form-text-usuarios-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-usuarios::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-usuarios {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
    
}

@media (min-width: 1600px) {

    .div-usuarios-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-usuarios-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-ss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-usuarios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-usuarios {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-usuarios-invalid {
        border: 1px red solid;
    }
    
    .form-text-usuarios-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-usuarios::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-usuarios {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
    
}