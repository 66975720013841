.Cruces {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-cruces-s {
    width: 100%;
}

.img-cruces-s {
    width: 100%;
    height: auto;
}

.div-cruces-body {
    width: 100%;
    margin: 60px 0px;
}

.row-cruces-s {
    text-align: left;
    margin-top: 30px;
}

.title-cruces-s {
    color: #0885C7;
    font-size: 24px;
    font-family: 'Lato';
    font-weight: 700;
    margin: 0;
    text-align: left;
}

.subtitle-cruces-s {
    color: #0885C7;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 0;
    text-align: left;
}

.table-cruces-s {
    width: 100%;
    margin-top: 10px;
}

.table-cruces-s > thead > tr > td {
    background-color: #0885C7;
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    border: 1px #FFFFFF solid;
    padding-left: 8px;
    line-height: 30px;
    width: 20%;
}

.table-cruces-s > tbody > tr > td {
    color: #646363;
    font-size: 9px;
    font-weight: 400;
    padding-left: 8px;
    line-height: 30px;
}

.table-cruces-s .bold {
    font-weight: 600;
}

.table-cruces-s > tbody > tr:nth-child(even) {
    background-color: #0885C711;
}

.tr-border {
    border-top: 1px #646363 solid;
}

.icono-estado {
    margin-top: -5px;
    margin-left: 0px;
    width: 14px;
    height: 14px;
}

@media (min-width: 576px) {
    .div-cruces-body {
        width: 100%;
        margin: 100px 0px;
    }
    
    .row-cruces-s {
        text-align: left;
        margin-top: 30px;
    }

    .title-cruces-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
        text-align: left;
    }

    .subtitle-cruces-s {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }

    .table-cruces-s {
        width: 100%;
        margin-top: 20px;
    }

    .table-cruces-s > thead > tr > td {
        background-color: #0885C7;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        border: 1px #FFFFFF solid;
        padding-left: 8px;
        line-height: 30px;
        width: 20%;
    }

    .table-cruces-s > tbody > tr > td {
        color: #646363;
        font-size: 11px;
        font-weight: 400;
        padding-left: 8px;
        line-height: 30px;
    }

    .table-cruces-s .bold {
        font-weight: 600;
    }

    .table-cruces-s > tbody > tr:nth-child(even) {
        background-color: #0885C711;
    }

    .tr-border {
        border-top: 1px #646363 solid;
    }

    .icono-estado {
        margin-top: -5px;
        margin-left: 10px;
        width: 16px;
        height: 16px;
    }
}

@media (min-width: 768px) {
    .div-cruces-body {
        width: 100%;
        margin: 60px 0px;
    }
    
    .row-cruces-s {
        text-align: left;
        margin-top: 40px;
    }

    .title-cruces-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
        text-align: left;
    }

    .subtitle-cruces-s {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }

    .table-cruces-s {
        width: 100%;
        margin-top: 20px;
    }

    .table-cruces-s > thead > tr > td {
        background-color: #0885C7;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        border: 1px #FFFFFF solid;
        padding-left: 8px;
        line-height: 30px;
        width: 20%;
    }

    .table-cruces-s > tbody > tr > td {
        color: #646363;
        font-size: 11px;
        font-weight: 400;
        padding-left: 8px;
        line-height: 30px;
    }

    .table-cruces-s .bold {
        font-weight: 600;
    }

    .table-cruces-s > tbody > tr:nth-child(even) {
        background-color: #0885C711;
    }

    .tr-border {
        border-top: 1px #646363 solid;
    }

    .icono-estado {
        margin-top: -5px;
        margin-left: 10px;
        width: 16px;
        height: 16px;
    }
}

@media (min-width: 992px) {
    .div-cruces-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .row-cruces-s {
        text-align: left;
        margin-top: 60px;
    }

    .title-cruces-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
        text-align: left;
    }

    .subtitle-cruces-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }

    .table-cruces-s {
        width: 100%;
        margin-top: 20px;
    }

    .table-cruces-s > thead > tr > td {
        background-color: #0885C7;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        border: 1px #FFFFFF solid;
        padding-left: 15px;
        line-height: 30px;
        width: 20%;
    }

    .table-cruces-s > tbody > tr > td {
        color: #646363;
        font-size: 14px;
        font-weight: 400;
        padding-left: 15px;
        line-height: 30px;
    }

    .table-cruces-s .bold {
        font-weight: 600;
    }

    .table-cruces-s > tbody > tr:nth-child(even) {
        background-color: #0885C711;
    }

    .tr-border {
        border-top: 1px #646363 solid;
    }

    .icono-estado {
        margin-top: -5px;
        margin-left: 10px;
        width: 18px;
        height: 18px;
    }
}

@media (min-width: 1200px) {
    .div-cruces-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .row-cruces-s {
        text-align: left;
        margin-top: 60px;
    }

    .title-cruces-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
        text-align: left;
    }

    .subtitle-cruces-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }

    .table-cruces-s {
        width: 100%;
        margin-top: 20px;
    }

    .table-cruces-s > thead > tr > td {
        background-color: #0885C7;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 600;
        border: 1px #FFFFFF solid;
        padding-left: 15px;
        line-height: 40px;
        width: 20%;
    }

    .table-cruces-s > tbody > tr > td {
        color: #646363;
        font-size: 16px;
        font-weight: 400;
        padding-left: 15px;
        line-height: 40px;
    }

    .table-cruces-s .bold {
        font-weight: 600;
    }

    .table-cruces-s > tbody > tr:nth-child(even) {
        background-color: #0885C711;
    }

    .tr-border {
        border-top: 1px #646363 solid;
    }

    .icono-estado {
        margin-top: -5px;
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }
}

@media (min-width: 1400px) {
    .div-cruces-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .row-cruces-s {
        text-align: left;
        margin-top: 60px;
    }

    .title-cruces-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
        text-align: left;
    }

    .subtitle-cruces-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }

    .table-cruces-s {
        width: 100%;
        margin-top: 20px;
    }

    .table-cruces-s > thead > tr > td {
        background-color: #0885C7;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 600;
        border: 1px #FFFFFF solid;
        padding-left: 15px;
        line-height: 40px;
        width: 20%;
    }

    .table-cruces-s > tbody > tr > td {
        color: #646363;
        font-size: 16px;
        font-weight: 400;
        padding-left: 15px;
        line-height: 40px;
    }

    .table-cruces-s .bold {
        font-weight: 600;
    }

    .table-cruces-s > tbody > tr:nth-child(even) {
        background-color: #0885C711;
    }

    .tr-border {
        border-top: 1px #646363 solid;
    }

    .icono-estado {
        margin-top: -5px;
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }
}

@media (min-width: 1600px) {
    .div-cruces-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .row-cruces-s {
        text-align: left;
        margin-top: 60px;
    }

    .title-cruces-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
        text-align: left;
    }

    .subtitle-cruces-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }

    .table-cruces-s {
        width: 100%;
        margin-top: 20px;
    }

    .table-cruces-s > thead > tr > td {
        background-color: #0885C7;
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 600;
        border: 1px #FFFFFF solid;
        padding-left: 15px;
        line-height: 40px;
        width: 20%;
    }

    .table-cruces-s > tbody > tr > td {
        color: #646363;
        font-size: 18px;
        font-weight: 400;
        padding-left: 15px;
        line-height: 40px;
    }

    .table-cruces-s .bold {
        font-weight: 600;
    }

    .table-cruces-s > tbody > tr:nth-child(even) {
        background-color: #0885C711;
    }

    .tr-border {
        border-top: 1px #646363 solid;
    }

    .icono-estado {
        margin-top: -5px;
        margin-left: 10px;
        width: 22px;
        height: 22px;
    }
}