.CRVIRItinerario {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.icon_info{
    color: #0885C7;
    width: 17%;
}

.div-crvir-itinerario-s {
    width: 100%;
}

.img-crvir-itinerario-s {
    width: 100%;
    height: auto;
}

.div-crvir-itinerario-body {
    width: 100%;
    margin: 120px 0px;
}

.title-crvir-itinerario-s {
    color: #0885C7;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 30px;
    text-align: left;
}

.subtitle-crvir-itinerario-s {
    color: #0885C7;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 40px 30px 10px 30px;
    text-align: left;
}

.p-crvir-itinerario-s {
    color: #646363;
    font-size: 11px;
    font-family: 'Lato';
    font-weight: 600;
    line-height: 22px;
    text-align: justify;
}

.crvir-itinerario-table {
    width: 90%;
    text-align: center;
    margin-bottom: 60px;
    margin-left: 5%;
}


.crvir-itinerario-table > thead::before {   
    content: "-";
    display: block;
    line-height: 10px;
    color: transparent;
} 

.crvir-itinerario-table .no-border {
    border: none !important;
}

.crvir-itinerario-table > tbody > tr {
    border-bottom: 1px #64636399 solid;
    color: #646363;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 50px;
}

.row-col-titulo {
    color: #434343;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 16px;
    background-color: #FFCD00;
    padding: 10px;
    border: 2px white solid;
} 

.crvir-itinerario-table .row-col-subtitulo {
    color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 30px;
    background-color: #0885C7;
    padding: 5px;
    border: 2px white solid;
} 

.crvir-itinerario-table .row-col-via {
    color: white;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 400;
    background-color: #646363;
    border: 2px white solid;
    line-height: 40px;
    padding: 3px;
} 

.crvir-itinerario-table .bold {
    font-weight: 800;
}

.consideraciones-crvir-itinerario {
    background: #FFCD00; 
    border-radius: 15px;
    padding: 15px 30px 15px 15px;
    margin-top: 20px;
}

.consideraciones-crvir-itinerario .title {
    color: #434343;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
}

.consideraciones-crvir-itinerario .p {
    color: #434343;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 600;
    text-align: justify;
    line-height: 20px;
}

.crvir-itinerario-table-w {
    width: 100%;
    text-align: center;
}

.crvir-itinerario-table-w .title {
    margin: 20px 0 10px 0 !important;
    text-align: center;
}

.crvir-itinerario-table-w .subtitle {
    text-align: center;
}

.crvir-itinerario-table-w .border-right {
    border-right: 2px #FFCD00 solid;
    margin-right: 20px;
}

@media (min-width: 576px) {

    .div-crvir-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-crvir-itinerario-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .subtitle-crvir-itinerario-s {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 30px 10px 30px;
        text-align: left;
    }
    
    .p-crvir-itinerario-s {
        color: #646363;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 22px;
        text-align: justify;
    }
    
    .crvir-itinerario-table {
        width: 70%;
        text-align: center;
        margin-bottom: 60px;
        margin-left: 15%;
    }
    
    
    .crvir-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .crvir-itinerario-table .no-border {
        border: none !important;
    }
    
    .crvir-itinerario-table > tbody > tr {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .row-col-titulo {
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: #FFCD00;
        padding: 10px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        background-color: #0885C7;
        padding: 5px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-via {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        background-color: #646363;
        border: 2px white solid;
        line-height: 40px;
        padding: 3px;
    } 
    
    .crvir-itinerario-table .bold {
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario {
        background: #FFCD00; 
        border-radius: 15px;
        padding: 15px 30px 15px 15px;
        margin-top: 20px;
    }
    
    .consideraciones-crvir-itinerario .title {
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario .p {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: justify;
        line-height: 20px;
    }
    
    .crvir-itinerario-table-w {
        width: 100%;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .title {
        margin: 20px 0 10px 0 !important;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .subtitle {
        text-align: center;
    }
    
    .crvir-itinerario-table-w .border-right {
        border-right: 2px #FFCD00 solid;
        margin-right: 20px;
    }
}

@media (min-width: 768px) {

    .div-crvir-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-crvir-itinerario-s {
        color: #0885C7;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .subtitle-crvir-itinerario-s {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 30px 10px 30px;
        text-align: left;
    }
    
    .p-crvir-itinerario-s {
        color: #646363;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 22px;
        text-align: justify;
    }
    
    .crvir-itinerario-table {
        width: 70%;
        text-align: center;
        margin-bottom: 60px;
        margin-left: 15%;
    }
    
    
    .crvir-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .crvir-itinerario-table .no-border {
        border: none !important;
    }

    .up{
        background-color:red;
        color: white !important;
        
    }
    
    .crvir-itinerario-table > tbody > tr {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .row-col-titulo {
        color: #434343;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        background-color: #FFCD00;
        padding: 10px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        background-color: #0885C7;
        padding: 5px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-via {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        background-color: #646363;
        border: 2px white solid;
        line-height: 40px;
        padding: 3px;
    } 
    
    .crvir-itinerario-table .bold {
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario {
        background: #FFCD00; 
        border-radius: 15px;
        padding: 15px 30px 15px 15px;
        margin-top: 20px;
    }
    
    .consideraciones-crvir-itinerario .title {
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario .p {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: justify;
        line-height: 20px;
    }
    
    .crvir-itinerario-table-w {
        width: 100%;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .title {
        margin: 20px 0 10px 0 !important;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .subtitle {
        text-align: center;
    }
    
    .crvir-itinerario-table-w .border-right {
        border-right: 2px #FFCD00 solid;
        margin-right: 20px;
    }
}

@media (min-width: 992px) {

    .div-crvir-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-crvir-itinerario-s {
        color: #0885C7;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .subtitle-crvir-itinerario-s {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 30px 10px 30px;
        text-align: left;
    }
    
    .p-crvir-itinerario-s {
        color: #646363;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 22px;
        text-align: justify;
    }
    
    .crvir-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
        margin-left: 0px;
    }
    
    
    .crvir-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .crvir-itinerario-table .no-border {
        border: none !important;
    }
    
    .crvir-itinerario-table > tbody > tr {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .row-col-titulo {
        color: #434343;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        background-color: #FFCD00;
        padding: 10px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        background-color: #0885C7;
        padding: 5px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-via {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        background-color: #646363;
        border: 2px white solid;
        line-height: 22px;
        padding: 3px;
    } 
    
    .crvir-itinerario-table .bold {
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario {
        background: #FFCD00; 
        border-radius: 15px;
        padding: 15px 30px 15px 15px;
        margin-top: 20px;
    }
    
    .consideraciones-crvir-itinerario .title {
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario .p {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: justify;
        line-height: 20px;
    }
    
    .crvir-itinerario-table-w {
        width: 100%;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .title {
        margin: 20px 0 10px 0 !important;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .subtitle {
        text-align: center;
    }
    
    .crvir-itinerario-table-w .border-right {
        border-right: 2px #FFCD00 solid;
        margin-right: 20px;
    }
}

@media (min-width: 1200px) {

    .div-crvir-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-crvir-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .subtitle-crvir-itinerario-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 30px 10px 30px;
        text-align: left;
    }
    
    .p-crvir-itinerario-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 25px;
        text-align: justify;
    }
    
    .crvir-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
        margin-left: 0px;
    }
    
    
    .crvir-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .crvir-itinerario-table .no-border {
        border: none !important;
    }
    
    .crvir-itinerario-table > tbody > tr {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .row-col-titulo {
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #FFCD00;
        padding: 10px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #0885C7;
        padding: 5px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-via {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        background-color: #646363;
        border: 2px white solid;
        line-height: 25px;
        padding: 5px;
    } 
    
    .crvir-itinerario-table .bold {
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario {
        background: #FFCD00; 
        border-radius: 15px;
        padding: 15px 30px 15px 15px;
        margin-top: 40px;
    }
    
    .consideraciones-crvir-itinerario .title {
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario .p {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: justify;
        line-height: 20px;
    }
    
    .crvir-itinerario-table-w {
        width: 100%;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .title {
        margin: 20px 0 10px 0 !important;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .subtitle {
        text-align: center;
    }
    
    .crvir-itinerario-table-w .border-right {
        border-right: 2px #FFCD00 solid;
        margin-right: 20px;
    }
}

@media (min-width: 1400px) {

    .div-crvir-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-crvir-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .subtitle-crvir-itinerario-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 30px 10px 30px;
        text-align: left;
    }
    
    .p-crvir-itinerario-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 25px;
        text-align: justify;
    }
    
    .crvir-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
        margin-left: 0px;
    }
    
    
    .crvir-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .crvir-itinerario-table .no-border {
        border: none !important;
    }
    
    .crvir-itinerario-table > tbody > tr {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .row-col-titulo {
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #FFCD00;
        padding: 10px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #0885C7;
        padding: 5px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-via {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        background-color: #646363;
        border: 2px white solid;
        line-height: 25px;
        padding: 5px;
    } 
    
    .crvir-itinerario-table .bold {
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario {
        background: #FFCD00; 
        border-radius: 15px;
        padding: 15px 30px 15px 15px;
        margin-top: 40px;
    }
    
    .consideraciones-crvir-itinerario .title {
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario .p {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: justify;
        line-height: 20px;
    }
    
    .crvir-itinerario-table-w {
        width: 100%;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .title {
        margin: 20px 0 10px 0 !important;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .subtitle {
        text-align: center;
    }
    
    .crvir-itinerario-table-w .border-right {
        border-right: 2px #FFCD00 solid;
        margin-right: 20px;
    }
}

@media (min-width: 1600px) {

    .div-crvir-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-crvir-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .subtitle-crvir-itinerario-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 30px 10px 30px;
        text-align: left;
    }
    
    .p-crvir-itinerario-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 25px;
        text-align: justify;
    }
    
    .crvir-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
        margin-left: 0px;
    }
    
    
    .crvir-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .crvir-itinerario-table .no-border {
        border: none !important;
    }
    
    .crvir-itinerario-table > tbody > tr {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .row-col-titulo {
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #FFCD00;
        padding: 10px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #0885C7;
        padding: 5px;
        border: 2px white solid;
    } 
    
    .crvir-itinerario-table .row-col-via {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        background-color: #646363;
        border: 2px white solid;
        line-height: 25px;
        padding: 5px;
    } 
    
    .crvir-itinerario-table .bold {
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario {
        background: #FFCD00; 
        border-radius: 15px;
        padding: 15px 30px 15px 15px;
        margin-top: 40px;
    }
    
    .consideraciones-crvir-itinerario .title {
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
    }
    
    .consideraciones-crvir-itinerario .p {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: justify;
        line-height: 20px;
    }
    
    .crvir-itinerario-table-w {
        width: 100%;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .title {
        margin: 20px 0 10px 0 !important;
        text-align: center;
    }
    
    .crvir-itinerario-table-w .subtitle {
        text-align: center;
    }
    
    .crvir-itinerario-table-w .border-right {
        border-right: 2px #FFCD00 solid;
        margin-right: 20px;
    }
}