
@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-Thin.ttf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-Black.ttf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url(/src/fonts/Lato-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.img-loading {
  width: 100%;
  height: auto;
}

.no-gutter {
  --bs-gutter-x: 0 !important;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.xs-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.button-servicio-s {
    border-radius: 8px !important;
    background-color: #F8F8F8 !important;
    color: #646363 !important;
    font-size: 12px !important;
    font-family: 'Lato' !important;
    font-weight: 600 !important;
    height: 35px !important;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15) !important;
    border: none !important;
    margin: 15px 5px !important;
    padding: 2px 10px !important;
}

.icono-servicio-itinerario {
  width: 16px;
  height: auto;
  margin: 0px 5px;
}

.icono-servicio-tarifas {
  width: 22px;
  height: auto;
  margin: 0px 5px;
}

.icono-servicio-informacion {
  width: 15px;
  height: auto;
  margin: 0px 5px;
}

.icono-servicio-manual {
  width: 16px;
  height: auto;
  margin: 0px 5px;
}

.icono-servicio-terminos {
  width: 13px;
  height: auto;
  margin: 0px 5px;
}

.logo-color-xs {
    width: 140px;
    height: auto;
    margin-bottom: 40px;
}

@media (min-width: 576px) {
  .no-gutter {
    --bs-gutter-x: inherit;
  }

  .xs-no-padding {
    padding-left: inherit !important;
    padding-right: inherit !important;
  }
    
  .button-servicio-s {
      border-radius: 8px !important;
      background-color: #F8F8F8 !important;
      color: #646363 !important;
      font-size: 16px !important;
      font-family: 'Lato' !important;
      font-weight: 600 !important;
      height: 45px !important;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15) !important;
      border: none !important;
      margin: 15px !important;
      padding: 5px 25px !important;
  }
    
  .icono-servicio-itinerario {
      width: 27px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-tarifas {
      width: 36px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-informacion {
      width: 23px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-manual {
      width: 27px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-terminos {
      width: 21px;
      height: auto;
      margin: 0px 5px;
  }
}

@media (min-width: 768px) {
  .no-gutter {
    --bs-gutter-x: inherit;
  }

  .xs-no-padding {
    padding-left: inherit !important;
    padding-right: inherit !important;
  }
    
  .button-servicio-s {
      border-radius: 8px !important;
      background-color: #F8F8F8 !important;
      color: #646363 !important;
      font-size: 16px !important;
      font-family: 'Lato' !important;
      font-weight: 600 !important;
      height: 45px !important;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15) !important;
      border: none !important;
      margin: 15px !important;
      padding: 5px 25px !important;
  }
    
  .icono-servicio-itinerario {
      width: 27px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-tarifas {
      width: 36px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-informacion {
      width: 23px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-manual {
      width: 27px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-terminos {
      width: 21px;
      height: auto;
      margin: 0px 5px;
  }
}

@media (min-width: 992px) {
  .no-gutter {
    --bs-gutter-x: inherit;
  }

  .xs-no-padding {
    padding-left: inherit !important;
    padding-right: inherit !important;
  }
    
  .button-servicio-s {
      border-radius: 8px !important;
      background-color: #F8F8F8 !important;
      color: #646363 !important;
      font-size: 16px !important;
      font-family: 'Lato' !important;
      font-weight: 600 !important;
      height: 45px !important;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15) !important;
      border: none !important;
      margin: 15px !important;
      padding: 5px 25px !important;
  }
    
  .icono-servicio-itinerario {
      width: 27px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-tarifas {
      width: 36px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-informacion {
      width: 23px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-manual {
      width: 27px;
      height: auto;
      margin: 0px 5px;
  }
  
  .icono-servicio-terminos {
      width: 21px;
      height: auto;
      margin: 0px 5px;
  }
}

@media (min-width: 1200px) {
  
}

@media (min-width: 1400px) {
  
}

@media (min-width: 1600px) {
  
}