.table-admin-servicios {
    width: 100%;
    font-size: 14px;
    font-family: "Lato";
}

.table-admin-servicios > thead {
    line-height: 45px;
    font-weight: 600;
    background-color: #0885C7;
    color: #FFFFFF;
}

.table-admin-servicios .bold {
    font-weight: 600;
}

.table-admin-servicios > thead > tr > td {
    padding: 0px 10px;
}

.table-admin-servicios > tbody > tr {
    line-height: 45px;
}

.table-admin-servicios > tbody > tr > td {
    padding: 0px 10px;
}

.table-admin-servicios .tr-row {
    border-top: 1px #00000099 solid;
}

.table-admin-servicios > tbody > tr:nth-child(even) {
    background-color: #0885C722;
}

.btn-admin-editar {
    background-color: #0885C7;
    color: #FFFFFF;
    border: 1px #FFFFFF solid;
}

.btn-admin-editar:hover {
    background-color: #FFFFFF;
    color: #0885C7;
    border: 1px #0885C7 solid;
}

.btn-admin-eliminar {
    background-color: #EE3333;
    color: #FFFFFF;
    border: 1px #FFFFFF solid;
}

.btn-admin-eliminar:hover {
    background-color: #FFFFFF;
    color: #EE3333;
    border: 1px #EE3333 solid;
}

.label-admin-recomendacion {
    color: #0885C7;
    font-family: "Lato";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    margin-top: -15px;
}