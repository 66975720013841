
.div-modal-notf {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000099;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

}

.img-modal-notf {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    z-index: 1001;
}
.link-modal-notf {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
    z-index: 1002;

}

.cb-modal-notf {
    position: fixed;
    top: 320px;
    left: calc(100% - 50px);
    z-index: 1006;
}

@media (min-width: 576px) {
    .div-modal-notf {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000099;
        z-index: 1001;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .img-modal-notf {
        max-width: 75%;
        max-height: 75%;
        object-fit: contain;
        z-index: 1001;
    }
    .link-modal-notf {
        max-width: 80%;
        max-height: 80%;
        object-fit: contain;
        z-index: 1002;
    
    }
    
    .cb-modal-notf {
        position: fixed;
        top: 290px;
        left: calc(100% - 170px);
        z-index: 2000;
    }
}


@media (min-width: 1000px) {
    .div-modal-notf {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000099;
        z-index: 1001;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .img-modal-notf {
        max-width: 75%;
        max-height: 75%;
        object-fit: contain;
        z-index: 1001;
    }
    .link-modal-notf {
        max-width: 80%;
        max-height: 80%;
        object-fit: contain;
        z-index: 1002;
    
    }
    
    .cb-modal-notf {
        position: fixed;
        top: 240px;
        left: calc(100% - 240px);
        z-index: 2000;
    }
}

@media (min-width: 1400px) {
    .div-modal-notf {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000099;
        z-index: 1001;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .img-modal-notf {
        max-width: 75%;
        max-height: 75%;
        object-fit: contain;
        z-index: 1001;
    }
    .link-modal-notf {
        max-width: 80%;
        max-height: 80%;
        object-fit: contain;
        z-index: 1002;
    
    }
    
    .cb-modal-notf {
        position: fixed;
        top: 140px;
        left: calc(100% - 400px);
        z-index: 2000;
    }
}