.Contactanos {
    text-align: center;
}

.div-contactanos-s {
    width: 100%;
}

.img-contactanos-s {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
}

.div-contactanos-body {
    width: 100%;
    margin: 100px 0px 150px 0px;
}

.row-contactanos-s {
    text-align: left;
}

.title-contactanos-s {
    color: #0885C7;
    font-size: 40px;
    font-family: 'Lato';
    font-weight: 600;
}

.p-contactanos-s {
    color: black;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
}

.r-contactanos-s {
    color: rgba(100, 99, 99, 0.60);
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
    font-style: italic;
}

.modalp-contactanos-s {
    color: white;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}

.subp-contactanos-s {
    color: white;
    font-size: 36px;
    font-family: 'Lato';
    font-weight: 600;
    text-align: center;
    margin-top: 0px;
}
.img-contactanos-s {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
}

.div-contactanos-body {
    width: 100%;
    margin: 100px 0px 150px 0px;
}

.row-contactanos-s {
    text-align: left;
}

.title-contactanos-s {
    color: #0885C7;
    font-size: 40px;
    font-family: 'Lato';
    font-weight: 600;
}

.p-contactanos-s {
    color: black;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
}

.r-contactanos-s {
    color: rgba(100, 99, 99, 0.60);
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
    font-style: italic;
}

.modalp-contactanos-s {
    color: white;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}

.subp-contactanos-s {
    color: white;
    font-size: 36px;
    font-family: 'Lato';
    font-weight: 600;
    text-align: center;
    margin-top: 0px;
}

.img-contactanos-s {
    width: 100%;
    height: auto;
}

.col-align-center {
    text-align: center;
    align-content: center;
    display: grid;
}

.row-content-ctt-s {
    margin-bottom: 0px;
    text-align: justify;
}

.modal-contactanos .modal-content {
    border-radius: 28px;
    padding: 50px 50px 0 50px;
    background-color: #0885C7;
}

.img-modal-close-c {
    margin-left: calc(100% - 40px);
}

.img-modal-close-c:hover {
    opacity: 0.9;
    cursor: pointer;
}

.img-contactanos-logo {
    width: 100%;
    height: auto;
}

.label-contactanos {
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    margin-top: 20px;
}

.form-field-contactanos {
    border: 1px #178DCB99 solid;
}

.form-field-contactanos-invalid {
    border: 1px red solid;
}

.form-text-contactanos-invalid {
    color: red;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-field-contactanos::placeholder {
    color: #64636399;
}

.form-field-contactanos-area {
    border: 1px #178DCB99 solid;
    height: 140px;
}

.form-field-contactanos-area::placeholder {
    color: #64636399;
}

.btn-enviar-contactanos {
    width: 100%;
    background-color: #178DCB; 
    color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    padding: 10px
}

.form-caracteres-contactanos {
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-requeridos-contactanos {
    color: #178DCB;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
}

.img-modal-contactanos {
    height: 30px;
    width: auto;
}

.p-modal-contactanos {
    color: white;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 30px;
    text-align: center;
}

.pdetalle-modal-contactanos {
    color: #FFCD00;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 30px;
    text-align: center;
}

.table-modal-contactanos {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.table-modal-contactanos .modal-separador {
    border-right: 1px white solid;
}

@media (min-width: 576px) {
    .img-contactanos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }
    
    .div-contactanos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .row-contactanos-s {
        text-align: left;
    }
    
    .title-contactanos-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-contactanos-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-contactanos-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-contactanos-s {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }
    
    .subp-contactanos-s {
        color: white;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: 0px;
    }
    
    .img-contactanos-s {
        width: 100%;
        height: auto;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-ctt-s {
        margin-bottom: 0px;
        text-align: justify;
    }
    
    .modal-contactanos .modal-content {
        border-radius: 28px;
        padding: 50px 50px 0 50px;
        background-color: #0885C7;
    }
    
    .img-modal-close-c {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-c:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-contactanos-logo {
        width: 100%;
        height: auto;
    }
    
    .label-contactanos {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 20px;
    }
    
    .form-field-contactanos {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-contactanos-invalid {
        border: 1px red solid;
    }
    
    .form-text-contactanos-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos::placeholder {
        color: #64636399;
    }
    
    .form-field-contactanos-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-contactanos-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-contactanos {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px
    }
    
    .form-caracteres-contactanos {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-requeridos-contactanos {
        color: #178DCB;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .img-modal-contactanos {
        height: 30px;
        width: auto;
    }
    
    .p-modal-contactanos {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .pdetalle-modal-contactanos {
        color: #FFCD00;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .table-modal-contactanos {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .table-modal-contactanos .modal-separador {
        border-right: 1px white solid;
    }
}

@media (min-width: 768px) {
    .img-contactanos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }
    
    .div-contactanos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .row-contactanos-s {
        text-align: left;
    }
    
    .title-contactanos-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-contactanos-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-contactanos-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-contactanos-s {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }
    
    .subp-contactanos-s {
        color: white;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: 0px;
    }
    
    .img-contactanos-s {
        width: 100%;
        height: auto;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-ctt-s {
        margin-bottom: 20px;
        text-align: justify;
    }
    
    .modal-contactanos .modal-content {
        border-radius: 28px;
        padding: 50px 50px 0 50px;
        background-color: #0885C7;
    }
    
    .img-modal-close-c {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-c:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-contactanos-logo {
        width: 100%;
        height: auto;
    }
    
    .label-contactanos {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-contactanos-invalid {
        border: 1px red solid;
    }
    
    .form-text-contactanos-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos::placeholder {
        color: #64636399;
    }
    
    .form-field-contactanos-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-contactanos-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-contactanos {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px
    }
    
    .form-caracteres-contactanos {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-requeridos-contactanos {
        color: #178DCB;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .img-modal-contactanos {
        height: 30px;
        width: auto;
    }
    
    .p-modal-contactanos {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .pdetalle-modal-contactanos {
        color: #FFCD00;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .table-modal-contactanos {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .table-modal-contactanos .modal-separador {
        border-right: 1px white solid;
    }
}

@media (min-width: 992px) {
    .img-contactanos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }
    
    .div-contactanos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .row-contactanos-s {
        text-align: left;
    }
    
    .title-contactanos-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-contactanos-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-contactanos-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-contactanos-s {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }
    
    .subp-contactanos-s {
        color: white;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: 0px;
    }
    
    .img-contactanos-s {
        width: 100%;
        height: auto;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-ctt-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .modal-contactanos .modal-content {
        border-radius: 28px;
        padding: 50px 50px 0 50px;
        background-color: #0885C7;
    }
    
    .img-modal-close-c {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-c:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-contactanos-logo {
        width: 100%;
        height: auto;
    }
    
    .label-contactanos {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-contactanos-invalid {
        border: 1px red solid;
    }
    
    .form-text-contactanos-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos::placeholder {
        color: #64636399;
    }
    
    .form-field-contactanos-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-contactanos-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-contactanos {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px
    }
    
    .form-caracteres-contactanos {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-requeridos-contactanos {
        color: #178DCB;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .img-modal-contactanos {
        height: 30px;
        width: auto;
    }
    
    .p-modal-contactanos {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .pdetalle-modal-contactanos {
        color: #FFCD00;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .table-modal-contactanos {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .table-modal-contactanos .modal-separador {
        border-right: 1px white solid;
    }
}

@media (min-width: 1200px) {
    .img-contactanos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }
    
    .div-contactanos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .row-contactanos-s {
        text-align: left;
    }
    
    .title-contactanos-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-contactanos-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-contactanos-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-contactanos-s {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }
    
    .subp-contactanos-s {
        color: white;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: 0px;
    }
    
    .img-contactanos-s {
        width: 100%;
        height: auto;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-ctt-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .modal-contactanos .modal-content {
        border-radius: 28px;
        padding: 50px 50px 0 50px;
        background-color: #0885C7;
    }
    
    .img-modal-close-c {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-c:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-contactanos-logo {
        width: 100%;
        height: auto;
    }
    
    .label-contactanos {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-contactanos-invalid {
        border: 1px red solid;
    }
    
    .form-text-contactanos-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos::placeholder {
        color: #64636399;
    }
    
    .form-field-contactanos-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-contactanos-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-contactanos {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px
    }
    
    .form-caracteres-contactanos {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-requeridos-contactanos {
        color: #178DCB;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .img-modal-contactanos {
        height: 30px;
        width: auto;
    }
    
    .p-modal-contactanos {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .pdetalle-modal-contactanos {
        color: #FFCD00;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .table-modal-contactanos {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .table-modal-contactanos .modal-separador {
        border-right: 1px white solid;
    }
}

@media (min-width: 1400px) {
    .img-contactanos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }
    
    .div-contactanos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .row-contactanos-s {
        text-align: left;
    }
    
    .title-contactanos-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-contactanos-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-contactanos-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-contactanos-s {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }
    
    .subp-contactanos-s {
        color: white;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: 0px;
    }
    
    .img-contactanos-s {
        width: 100%;
        height: auto;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-ctt-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .modal-contactanos .modal-content {
        border-radius: 28px;
        padding: 50px 50px 0 50px;
        background-color: #0885C7;
    }
    
    .img-modal-close-c {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-c:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-contactanos-logo {
        width: 100%;
        height: auto;
    }
    
    .label-contactanos {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-contactanos-invalid {
        border: 1px red solid;
    }
    
    .form-text-contactanos-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos::placeholder {
        color: #64636399;
    }
    
    .form-field-contactanos-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-contactanos-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-contactanos {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px
    }
    
    .form-caracteres-contactanos {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-requeridos-contactanos {
        color: #178DCB;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .img-modal-contactanos {
        height: 30px;
        width: auto;
    }
    
    .p-modal-contactanos {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .pdetalle-modal-contactanos {
        color: #FFCD00;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .table-modal-contactanos {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .table-modal-contactanos .modal-separador {
        border-right: 1px white solid;
    }
}

@media (min-width: 1600px) {
    .img-contactanos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }
    
    .div-contactanos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .row-contactanos-s {
        text-align: left;
    }
    
    .title-contactanos-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-contactanos-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-contactanos-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-contactanos-s {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }
    
    .subp-contactanos-s {
        color: white;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: 0px;
    }
    
    .img-contactanos-s {
        width: 100%;
        height: auto;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-ctt-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .modal-contactanos .modal-content {
        border-radius: 28px;
        padding: 50px 50px 0 50px;
        background-color: #0885C7;
    }
    
    .img-modal-close-c {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-c:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-contactanos-logo {
        width: 100%;
        height: auto;
    }
    
    .label-contactanos {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-contactanos-invalid {
        border: 1px red solid;
    }
    
    .form-text-contactanos-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-contactanos::placeholder {
        color: #64636399;
    }
    
    .form-field-contactanos-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-contactanos-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-contactanos {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px
    }
    
    .form-caracteres-contactanos {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-requeridos-contactanos {
        color: #178DCB;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .img-modal-contactanos {
        height: 30px;
        width: auto;
    }
    
    .p-modal-contactanos {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .pdetalle-modal-contactanos {
        color: #FFCD00;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
    }
    
    .table-modal-contactanos {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .table-modal-contactanos .modal-separador {
        border-right: 1px white solid;
    }
}