.CMSIAtencion {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-cmsi-informacion-interes-s {
    width: 100%;
}

.separador-cmsi-informacion-interes-s {
    height: 12px;
    border: 2px #FFCD00 solid;
}

.img-cmsi-informacion-interes-s {
    width: 100%;
    height: auto;
}

.div-cmsi-ii-body {
    width: 100%;
    margin: 40px 0px;
}

.row-cmsi-informacion-interes-s {
    text-align: left;
}
    
.separador-cmsi-informacion-interes-s {
    height: 12px;
    border: 2px #FFCD00 solid;
}

.row-cmsi-informacion-interes-s {
    text-align: left;
}

.title-cmsi-informacion-interes-s {
    color: #0885C7;
    font-size: 26px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 60px 0 40px 0;
}

.subtitle-cmsi-informacion-interes-s {
    color: #0885C7;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 40px 0 40px 0;
    text-align: left;
}

.p-cmsi-informacion-interes-s {
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    margin-top: 10px;
    text-align: justify;
}

.p-cmsi-informacion-interes-s .bold {
    font-weight: 600;
}

.p-cmsi-informacion-interes-t {
    color: #0885C7;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 800;
    text-align: left;
    margin: 20px 0px 10px 0px;
}

.p-sub-cmsi-informacion-interes-s {
    color: #0885C7;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 800;
    margin-right: 16px;
}

.separador-cmsi-informacion-interes-s {
    border: 1px #FFCD00 solid;
    width: 0px;
    height: 12px;
    margin-left: 10px;
    margin-right: 10px;
    display: inline;
}

.row-cmsi-informacion-interes-content {
    margin: 40px 0px 30px 0px;
}

.row-centered {
    text-align: center !important;
}

.ii-img-t-l {
    width: 100%;
    height: auto;
    border-top-left-radius: 23px;
    padding: 4px;
}

.ii-img-b-l {
    width: 100%;
    height: auto;
    padding: 4px;
}

.ii-img-c {
    width: 100%;
    height: auto;
    padding: 4px;
}

.ii-img-r {
    width: 100%;
    height: auto;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    padding: 4px;
}

.link-cmsi-informacion-interes:hover {
    cursor: pointer;
}

.div-cmsi-informacion-interes-parque {
    border-radius: 10px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.img-cmsi-informacion-interes-parque {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.link-cmsi-informacion-interes-parque {
    width: 100%;
    min-height: 100%;
    background-color: #00000066;
    line-height: 10px;
    color: white;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 800;
    border-radius: 10px;
    float: left;
    position: absolute;
    left: 0px;
    top: 0px;
    padding-top: 10%;
}

.link-cmsi-informacion-interes-parque:hover {
    cursor: pointer;
    opacity: 0.8;
}

.ii-img-2-t-l {
    width: 37%;
    height: auto;
    padding: 4px;
    border-top-left-radius: 30px;
}

.ii-img-2-t-r {
    width: 62%;
    height: auto;
    padding: 4px;
    border-top-right-radius: 30px;
}

.ii-img-2-b {
    width: 99%;
    height: auto;
    padding: 4px;
    border-bottom-right-radius: 30px;
}

.separador-cmsi-informacion-interes {
    margin-top: 20px
}

.subp-cmsi-informacion-interes-s {
    color: #64636399;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    margin-top: 10px;
    text-align: justify;
}

@media (min-width: 576px) {
    
    .separador-cmsi-informacion-interes-s {
        height: 12px;
        border: 2px #FFCD00 solid;
    }
    
    .row-cmsi-informacion-interes-s {
        text-align: left;
    }
    
    .title-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .subtitle-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-cmsi-informacion-interes-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-informacion-interes-s .bold {
        font-weight: 600;
    }
    
    .p-cmsi-informacion-interes-t {
        color: #0885C7;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        text-align: left;
        margin: 20px 0px 10px 0px;
    }
    
    .p-sub-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 800;
        margin-right: 16px;
    }
    
    .separador-cmsi-informacion-interes-s {
        border: 1px #FFCD00 solid;
        width: 0px;
        height: 12px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-cmsi-informacion-interes-content {
        margin: 40px 0px 30px 0px;
    }
    
    .row-centered {
        text-align: center !important;
    }
    
    .ii-img-t-l {
        width: 100%;
        height: auto;
        border-top-left-radius: 23px;
        padding: 4px;
    }
    
    .ii-img-b-l {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-c {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-r {
        width: 100%;
        height: auto;
        border-top-right-radius: 23px;
        border-bottom-right-radius: 23px;
        padding: 4px;
    }
    
    .link-cmsi-informacion-interes:hover {
        cursor: pointer;
    }
    
    .div-cmsi-informacion-interes-parque {
        border-radius: 10px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .img-cmsi-informacion-interes-parque {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    
    .link-cmsi-informacion-interes-parque {
        width: 100%;
        min-height: 100%;
        background-color: #00000066;
        line-height: 5px;
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 800;
        border-radius: 10px;
        float: left;
        position: absolute;
        left: 0px;
        top: 0px;
        padding-top: 10%;
    }
    
    .link-cmsi-informacion-interes-parque:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .ii-img-2-t-l {
        width: 37%;
        height: auto;
        padding: 4px;
        border-top-left-radius: 30px;
    }
    
    .ii-img-2-t-r {
        width: 62%;
        height: auto;
        padding: 4px;
        border-top-right-radius: 30px;
    }
    
    .ii-img-2-b {
        width: 99%;
        height: auto;
        padding: 4px;
        border-bottom-right-radius: 30px;
    }
    
    .separador-cmsi-informacion-interes {
        margin-top: 20px
    }

    .subp-cmsi-informacion-interes-s {
        color: #64636399;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
}

@media (min-width: 768px) {
    
    .separador-cmsi-informacion-interes-s {
        height: 12px;
        border: 2px #FFCD00 solid;
    }
    
    .row-cmsi-informacion-interes-s {
        text-align: left;
    }
    
    .title-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .subtitle-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-cmsi-informacion-interes-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-informacion-interes-s .bold {
        font-weight: 600;
    }
    
    .p-cmsi-informacion-interes-t {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        text-align: left;
        margin: 20px 0px 10px 0px;
    }
    
    .p-sub-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 800;
        margin-right: 16px;
    }
    
    .separador-cmsi-informacion-interes-s {
        border: 1px #FFCD00 solid;
        width: 0px;
        height: 14px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-cmsi-informacion-interes-content {
        margin: 40px 0px 30px 0px;
    }
    
    .row-centered {
        text-align: center !important;
    }
    
    .ii-img-t-l {
        width: 100%;
        height: auto;
        border-top-left-radius: 23px;
        padding: 4px;
    }
    
    .ii-img-b-l {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-c {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-r {
        width: 100%;
        height: auto;
        border-top-right-radius: 23px;
        border-bottom-right-radius: 23px;
        padding: 4px;
    }
    
    .link-cmsi-informacion-interes:hover {
        cursor: pointer;
    }
    
    .div-cmsi-informacion-interes-parque {
        border-radius: 10px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .img-cmsi-informacion-interes-parque {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    
    .link-cmsi-informacion-interes-parque {
        width: 100%;
        min-height: 100%;
        background-color: #00000066;
        line-height: 10px;
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        border-radius: 10px;
        float: left;
        position: absolute;
        left: 0px;
        top: 0px;
        padding-top: 10%;
    }
    
    .link-cmsi-informacion-interes-parque:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .ii-img-2-t-l {
        width: 37%;
        height: auto;
        padding: 4px;
        border-top-left-radius: 30px;
    }
    
    .ii-img-2-t-r {
        width: 62%;
        height: auto;
        padding: 4px;
        border-top-right-radius: 30px;
    }
    
    .ii-img-2-b {
        width: 99%;
        height: auto;
        padding: 4px;
        border-bottom-right-radius: 30px;
    }
    
    .separador-cmsi-informacion-interes {
        margin-top: 20px
    }

    .subp-cmsi-informacion-interes-s {
        color: #64636399;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
}

@media (min-width: 992px) {
    
    .separador-cmsi-informacion-interes-s {
        height: 12px;
        border: 2px #FFCD00 solid;
    }
    
    .row-cmsi-informacion-interes-s {
        text-align: left;
    }
    
    .title-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .subtitle-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-cmsi-informacion-interes-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-informacion-interes-s .bold {
        font-weight: 600;
    }
    
    .p-cmsi-informacion-interes-t {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        text-align: left;
        margin: 20px 0px 10px 0px;
    }
    
    .p-sub-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 800;
        margin-right: 16px;
    }
    
    .separador-cmsi-informacion-interes-s {
        border: 1px #FFCD00 solid;
        width: 0px;
        height: 14px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-cmsi-informacion-interes-content {
        margin: 40px 0px 30px 0px;
    }
    
    .row-centered {
        text-align: center !important;
    }
    
    .ii-img-t-l {
        width: 100%;
        height: auto;
        border-top-left-radius: 23px;
        padding: 4px;
    }
    
    .ii-img-b-l {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-c {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-r {
        width: 100%;
        height: auto;
        border-top-right-radius: 23px;
        border-bottom-right-radius: 23px;
        padding: 4px;
    }
    
    .link-cmsi-informacion-interes:hover {
        cursor: pointer;
    }
    
    .div-cmsi-informacion-interes-parque {
        border-radius: 10px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .img-cmsi-informacion-interes-parque {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    
    .link-cmsi-informacion-interes-parque {
        width: 100%;
        min-height: 100%;
        background-color: #00000066;
        line-height: 10px;
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        border-radius: 10px;
        float: left;
        position: absolute;
        left: 0px;
        top: 0px;
        padding-top: 10%;
    }
    
    .link-cmsi-informacion-interes-parque:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .ii-img-2-t-l {
        width: 37%;
        height: auto;
        padding: 4px;
        border-top-left-radius: 30px;
    }
    
    .ii-img-2-t-r {
        width: 62%;
        height: auto;
        padding: 4px;
        border-top-right-radius: 30px;
    }
    
    .ii-img-2-b {
        width: 99%;
        height: auto;
        padding: 4px;
        border-bottom-right-radius: 30px;
    }
    
    .separador-cmsi-informacion-interes {
        margin-top: 20px
    }

    .subp-cmsi-informacion-interes-s {
        color: #64636399;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
}

@media (min-width: 1200px) {
    
    .separador-cmsi-informacion-interes-s {
        height: 12px;
        border: 2px #FFCD00 solid;
    }
    
    .row-cmsi-informacion-interes-s {
        text-align: left;
    }
    
    .title-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .subtitle-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-cmsi-informacion-interes-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-informacion-interes-s .bold {
        font-weight: 600;
    }
    
    .p-cmsi-informacion-interes-t {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        text-align: left;
        margin: 20px 0px 10px 0px;
    }
    
    .p-sub-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        margin-right: 16px;
    }
    
    .separador-cmsi-informacion-interes-s {
        border: 2px #FFCD00 solid;
        width: 0px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-cmsi-informacion-interes-content {
        margin: 40px 0px 30px 0px;
    }
    
    .row-centered {
        text-align: center !important;
    }
    
    .ii-img-t-l {
        width: 100%;
        height: auto;
        border-top-left-radius: 23px;
        padding: 4px;
    }
    
    .ii-img-b-l {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-c {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-r {
        width: 100%;
        height: auto;
        border-top-right-radius: 23px;
        border-bottom-right-radius: 23px;
        padding: 4px;
    }
    
    .link-cmsi-informacion-interes:hover {
        cursor: pointer;
    }
    
    .div-cmsi-informacion-interes-parque {
        border-radius: 10px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .img-cmsi-informacion-interes-parque {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    
    .link-cmsi-informacion-interes-parque {
        width: 100%;
        min-height: 100%;
        background-color: #00000066;
        line-height: 15px;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border-radius: 10px;
        float: left;
        position: absolute;
        left: 0px;
        top: 0px;
        padding-top: 10%;
    }
    
    .link-cmsi-informacion-interes-parque:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .ii-img-2-t-l {
        width: 37%;
        height: auto;
        padding: 4px;
        border-top-left-radius: 30px;
    }
    
    .ii-img-2-t-r {
        width: 62%;
        height: auto;
        padding: 4px;
        border-top-right-radius: 30px;
    }
    
    .ii-img-2-b {
        width: 99%;
        height: auto;
        padding: 4px;
        border-bottom-right-radius: 30px;
    }
    
    .separador-cmsi-informacion-interes {
        margin-top: 20px
    }

    .subp-cmsi-informacion-interes-s {
        color: #64636399;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
}

@media (min-width: 1400px) {
    
    .separador-cmsi-informacion-interes-s {
        height: 12px;
        border: 2px #FFCD00 solid;
    }
    
    .row-cmsi-informacion-interes-s {
        text-align: left;
    }
    
    .title-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .subtitle-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-cmsi-informacion-interes-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-informacion-interes-s .bold {
        font-weight: 600;
    }
    
    .p-cmsi-informacion-interes-t {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        text-align: left;
        margin: 20px 0px 10px 0px;
    }
    
    .p-sub-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        margin-right: 16px;
    }
    
    .separador-cmsi-informacion-interes-s {
        border: 2px #FFCD00 solid;
        width: 0px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-cmsi-informacion-interes-content {
        margin: 40px 0px 30px 0px;
    }
    
    .row-centered {
        text-align: center !important;
    }
    
    .ii-img-t-l {
        width: 100%;
        height: auto;
        border-top-left-radius: 23px;
        padding: 4px;
    }
    
    .ii-img-b-l {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-c {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-r {
        width: 100%;
        height: auto;
        border-top-right-radius: 23px;
        border-bottom-right-radius: 23px;
        padding: 4px;
    }
    
    .link-cmsi-informacion-interes:hover {
        cursor: pointer;
    }
    
    .div-cmsi-informacion-interes-parque {
        border-radius: 10px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .img-cmsi-informacion-interes-parque {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    
    .link-cmsi-informacion-interes-parque {
        width: 100%;
        min-height: 100%;
        background-color: #00000066;
        line-height: 15px;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border-radius: 10px;
        float: left;
        position: absolute;
        left: 0px;
        top: 0px;
        padding-top: 10%;
    }
    
    .link-cmsi-informacion-interes-parque:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .ii-img-2-t-l {
        width: 37%;
        height: auto;
        padding: 4px;
        border-top-left-radius: 30px;
    }
    
    .ii-img-2-t-r {
        width: 62%;
        height: auto;
        padding: 4px;
        border-top-right-radius: 30px;
    }
    
    .ii-img-2-b {
        width: 99%;
        height: auto;
        padding: 4px;
        border-bottom-right-radius: 30px;
    }
    
    .separador-cmsi-informacion-interes {
        margin-top: 20px
    }

    .subp-cmsi-informacion-interes-s {
        color: #64636399;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
}

@media (min-width: 1600px) {
    
    .separador-cmsi-informacion-interes-s {
        height: 12px;
        border: 2px #FFCD00 solid;
    }
    
    .row-cmsi-informacion-interes-s {
        text-align: left;
    }
    
    .title-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .subtitle-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-cmsi-informacion-interes-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-informacion-interes-s .bold {
        font-weight: 600;
    }
    
    .p-cmsi-informacion-interes-t {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        text-align: left;
        margin: 20px 0px 10px 0px;
    }
    
    .p-sub-cmsi-informacion-interes-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        margin-right: 16px;
    }
    
    .separador-cmsi-informacion-interes-s {
        border: 2px #FFCD00 solid;
        width: 0px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-cmsi-informacion-interes-content {
        margin: 40px 0px 30px 0px;
    }
    
    .row-centered {
        text-align: center !important;
    }
    
    .ii-img-t-l {
        width: 100%;
        height: auto;
        border-top-left-radius: 23px;
        padding: 4px;
    }
    
    .ii-img-b-l {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-c {
        width: 100%;
        height: auto;
        padding: 4px;
    }
    
    .ii-img-r {
        width: 100%;
        height: auto;
        border-top-right-radius: 23px;
        border-bottom-right-radius: 23px;
        padding: 4px;
    }
    
    .link-cmsi-informacion-interes:hover {
        cursor: pointer;
    }
    
    .div-cmsi-informacion-interes-parque {
        border-radius: 10px;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .img-cmsi-informacion-interes-parque {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
    
    .link-cmsi-informacion-interes-parque {
        width: 100%;
        min-height: 100%;
        background-color: #00000066;
        line-height: 15px;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border-radius: 10px;
        float: left;
        position: absolute;
        left: 0px;
        top: 0px;
        padding-top: 10%;
    }
    
    .link-cmsi-informacion-interes-parque:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .ii-img-2-t-l {
        width: 37%;
        height: auto;
        padding: 4px;
        border-top-left-radius: 30px;
    }
    
    .ii-img-2-t-r {
        width: 62%;
        height: auto;
        padding: 4px;
        border-top-right-radius: 30px;
    }
    
    .ii-img-2-b {
        width: 99%;
        height: auto;
        padding: 4px;
        border-bottom-right-radius: 30px;
    }
    
    .separador-cmsi-informacion-interes {
        margin-top: 20px
    }

    .subp-cmsi-informacion-interes-s {
        color: #64636399;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
}