.content-admin-tarjetas {
    padding: 40px 20px 80px 20px;
}

.form-field-tarjetas {
    margin-bottom: 20px;
}

.btn-close-tarjetas {
    width: 50px;
    height: auto;
}

.btn-close-tarjetas:hover {
    cursor: pointer;
}

.subp-titulo-admin {
    font-family: "Lato";
    font-size: 24px;
    font-weight: 500;
    color: #0885C7;
}

.form-label-tarjetas {
    font-family: "Lato";
    font-size: 14px;
    color: #919EAB;
    position: absolute;
    margin-top: -10px;
    margin-left: 10px;
    background-color: white;
    padding: 0 10px;
}

.form-field-tarjetas {
    font-family: "Lato";
    font-size: 16px;
    color: #434343;
    height: 60px;
    border: 1px #919EAB solid;
    border-radius: 8px;
    margin-bottom: 40px;
}

.form-field-tarjetas.no-margin-tarjetas {
    margin-bottom: 0px;
}

.form-field-tarjetas:focus {
    color: #434343;
}

.form-field-tarjetas::placeholder {
    color: #919EAB;
}

.form-field-file-tarjetas {
    font-family: "Lato";
    font-size: 16px;
    color: #434343;
    height: 40px;
    border: 1px #919EAB solid;
    border-radius: 8px;
}

.form-field-file-tarjetas:focus {
    color: #434343;
}

.form-field-file-tarjetas::placeholder {
    color: #919EAB;
}

.form-area-tarjetas {
    font-family: "Lato";
    font-size: 16px;
    color: #434343;
    height: 300px;
    border: 1px #919EAB solid;
    border-radius: 8px;
    align-content: center;
    margin-bottom: 40px;
}

.form-area-tarjetas:focus {
    color: #434343;
}

.form-area-tarjetas::placeholder {
    color: #919EAB;
}

.btn-admin-ver-qr-tarjetas {
    height: 40px;
    width: 100%;
    font-family: "Lato";
}

.btn-admin-new-tarjetas {
    height: 40px;
    width: 100%;
    font-family: "Lato";
    background-color: #0885C7;
    border-color: #0885C7;
    color: #FFFFFF;
    margin-top: 10px;
}

.btn-admin-new-tarjetas:hover {
    background-color: #0885C799;
    border-color: #0885C7;
    color: #FFFFFF;
}

.btn-admin-editar-tarjetas {
    height: 40px;
    width: 100%;
    font-family: "Lato";
    background-color: #FFCD00;
    border-color: #FFCD00;
    color: #434343;
    margin-top: 10px;
}

.btn-admin-editar-tarjetas:hover {
    background-color: #FFCD0099;
    border-color: #FFCD00;
    color: #434343;
}

.btn-admin-eliminar-tarjetas {
    height: 40px;
    width: 100%;
    font-family: "Lato";
    background-color: #FF3D00;
    border-color: #FF3D00;
    color: #FFFFFF;
    margin-top: 10px;
}

.btn-admin-eliminar-tarjetas:hover {
    background-color: #FF3D0099;
    border-color: #FF3D00;
    color: #FFFFFF;
}

.btn-admin-link-tarjetas {
    height: 40px;
    width: 100%;
    font-family: "Lato";
    background-color: #FFFFFF;
    border: none;
    color: #434343;
    margin-top: 10px;
}

.btn-admin-link-tarjetas:hover {
    background-color: #F5F5F5;
    border: none;
    color: #434343;
}

.table-admin-tarjetas {
    margin-top: 30px;
    width: 100%;
    font-family: "Lato";
}

.table-admin-tarjetas > thead {
    height: 50px;
    background-color: #919EAB;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
}

.table-admin-tarjetas > thead > tr > td {
    padding-left: 10px;
}

.table-admin-tarjetas > tbody > tr {
    height: 50px;
    color: #434343;
    font-size: 16px;
    font-weight: 300;
    border-bottom: 1px #919EAB solid;
}

.table-admin-tarjetas > tbody > tr.tr-row {
    background-color: #F5F5F5 !important;
}

.table-admin-tarjetas > tbody > tr > td {
    padding-left: 10px;
}

.btn-admin-ver-qr-tarjetas-table {
    height: 40px;
    font-family: "Lato";
    background-color: #FFFFFF;
    border-color: #919EAB;
    color: #434343;
    margin: 0 10px;
}

.btn-admin-ver-qr-tarjetas-table:hover {
    background-color: #F5F5F5;
    border-color: #919EAB;
    color: #434343;
}

.btn-admin-editar-tarjetas-table {
    height: 40px;
    font-family: "Lato";
    background-color: #FFCD00;
    border-color: #FFCD00;
    color: #434343;
    margin: 0 10px;
}

.btn-admin-editar-tarjetas-table:hover {
    background-color: #FFCD0099;
    border-color: #FFCD00;
    color: #434343;
}

.btn-admin-eliminar-tarjetas-table {
    height: 40px;
    font-family: "Lato";
    background-color: #FF3D00;
    border-color: #FF3D00;
    color: #FFFFFF;
    margin: 0 10px;
}

.btn-admin-eliminar-tarjetas-table:hover {
    background-color: #FF3D0099;
    border-color: #FF3D00;
}

.qr-code-tarjetas {
    padding: 40px;
    border: 1px #919EAB solid;
    border-radius: 10px;
}

.qr-code-tarjetas-label {
    font-family: "Lato";
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: center;
}

.add-border {
    border: 1px #919EAB solid;
}