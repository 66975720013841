.div-tarjeta {
    width: 100%;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.div-img-tarjeta {
    width: 100%;
}

.img-tarjeta {
    width: 100%;
    height: auto;
}

.div-img-titulo {
    width: 100%;
    max-width: 480px;
    z-index: 100;
    top: 0;
    position: absolute;
    background: linear-gradient(180deg, #0885C700 60%, #0885C7 100%);
}

.div-titulo {
    margin-top: 55%;
    min-height: 120px;
    padding-bottom: 40px;
}

.p-nombre {
    font-family: "Lato";
    font-size: 32px;
    font-weight: 800;
    color: #FFFFFF;
    margin-left: 30px;
    line-height: 40px;
}

.p-nombre::after {
    content: '';
    position: absolute;
    width: 60%;
    color: #FFCD00;
    border: 2px #FFCD00 solid;
    left: 30px;
    display: block;
    clear: both;
    background-color: black;
}

.p-cargo {
    font-family: "Lato";
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    margin-left: 30px;
    line-height: 10px;
}

.div-info {
    width: 100%;
    background-color: #0885C7;
}

.div-icon-buttons {
    width: 100%;
    padding: 30px;
}

.icon-button {
    width: 60px;
    height: auto;
    margin-right: 20px;
    z-index: 200;
    position: relative;
}

.div-contacto {
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: rgba(245, 245, 245, 70%);
    padding: 40px 20px;
    border-radius: 10px;
}

.p-contacto-titulo {
    color: black;
    font-family: "Lato";
    font-size: 28px;
    font-weight: 800;
    text-align: center;
}

.p-contacto-titulo::after {
    content: '';
    position: absolute;
    width: 30%;
    color: #FFCD00;
    border: 2px #FFCD00 solid;
    left: 35%;
    display: block;
    clear: both;
    background-color: black;
}

.div-row-contacto {
    width: 100%;
    padding: 10px 30px;
}

.icon-contacto {
    width: 40px;
    margin-right: 30px;
}

.link-contacto {
    margin-top: 2px;
    font-family: "Lato";
    font-size: 16px;
    color: black;
}

.p-contacto {
    margin-top: 2px;
    font-family: "Lato";
    font-size: 16px;
    color: black;
    text-decoration: none;
}

.div-guardar-contacto {
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.btn-guardar-contacto {
    width: 100%;
    height: 60px;
    background-color: #FFCD00;
    color: #0885C7;
    font-family: "Lato";
    font-size: 20px;
    font-weight: 600;
}

.icon-agregar-contacto {
    width: 50px;
    height: auto;
    margin-top: -4px;
    margin-left: -20%;
    margin-right: 10%;
}

.div-descripcion {
    width: 100%;
    background-color: #FFFFFF;
    padding: 60px 30px;
}

.p-titulo-descripcion {
    color: #0885C7;
    font-family: "Lato";
    font-size: 28px;
    font-weight: 800;
    text-align: center;
}

.p-titulo-descripcion::after {
    content: '';
    position: absolute;
    width: 30%;
    color: #FFCD00;
    border: 2px #FFCD00 solid;
    left: 35%;
    display: block;
    clear: both;
    background-color: black;
}

.p-descripcion {
    font-family: "Lato";
    font-size: 16px;
    color: #434343;
    text-align: justify;
    margin-top: 40px;
    margin-bottom: 40px;
    white-space: pre-line;
}

.div-footer {
    width: 100%;
    height: 50px;
    background-color: rgba(243, 243, 243, 100%);
    align-content: center;
}

.img-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 40px;
}