.Ejecutivos {
    text-align: center;
}

.div-ejecutivos-s {
    width: 100%;
}

.img-ejecutivos-s {
    width: 100%;
    height: auto;
}

.row-ejecutivos-s {
    text-align: left;
}

.div-ejecutivos-body {
    width: 100%;
    margin: 100px 0px 150px 0px;
}

.backrow-ejecutivo-s {
    margin-bottom: 80px;
    background: linear-gradient(0deg, rgba(100,99,99,1) 0%, rgba(100,99,99,1) 95%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
}

.backrow-ejecutivo-s-b {
    margin-bottom: 80px;
    background: linear-gradient(0deg, rgba(67,67,67,1) 0%, rgba(67,67,67,1) 95%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
}

.title-ejecutivos-s {
    color: #0885C7;
    font-size: 28px;
    font-family: 'Lato';
    font-weight: 900;
    margin: 0 0 20px 0;
}

.img-perfil-s {
    width: 70%;
    height: auto;
    box-shadow: 2px 6px 11px rgba(0, 0, 0, 0.34); 
    border-radius: 25px;
    margin-left: 15%;
    margin-bottom: 40px;
}

.title-perfil-s {
    color: white;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 700;
    margin: 20px 0 0 0;
    text-align: center;
}

.title-perfil-s-b {
    color: white;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 700;
    margin: 20px 0 0 0;
    text-align: center;
}

.under-title-perfil-s {
    width: 60px;
    border: 1px #FFCD00 solid;
    margin-left: calc(50% - 30px);
}

.under-title-perfil-s-b {
    width: 60px;
    border: 1px #FFCD00 solid;
    margin-left: calc(50% - 30px);
}

.p-perfil-s {
    color: white;
    font-size: 10px;
    font-family: 'Lato';
    font-weight: 400;
    text-align: justify;
    margin-top: 10px;
}

.p-perfil-s-b {
    color: white;
    font-size: 10px;
    font-family: 'Lato';
    font-weight: 400;
    text-align: justify;
    margin-top: 10px;
}

.bloque-nombre-s {
    width: 100%;
    position: relative;
    display: block;
    margin-top: -90px;
    margin-bottom: 40px;
}

.nombre-perfil-s {
    color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 600;
    z-index: 100;
    text-align: center;
}

@media (min-width: 576px) {

    .backrow-ejecutivo-s {
        margin-bottom: 80px;
        background: linear-gradient(0deg, rgba(100,99,99,1) 0%, rgba(100,99,99,1) 95%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
    }
    
    .backrow-ejecutivo-s-b {
        margin-bottom: 80px;
        background: linear-gradient(0deg, rgba(67,67,67,1) 0%, rgba(67,67,67,1) 95%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
    }
    
    .title-ejecutivos-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .img-perfil-s {
        width: 60%;
        height: auto;
        box-shadow: 2px 6px 11px rgba(0, 0, 0, 0.34); 
        border-radius: 25px;
        margin-left: 20%;
        margin-bottom: 40px;
    }
    
    .title-perfil-s {
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
        text-align: center;
    }
    
    .title-perfil-s-b {
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
        text-align: center;
    }
    
    .under-title-perfil-s {
        width: 60px;
        border: 1px #FFCD00 solid;
        margin-left: calc(50% - 30px);
    }
    
    .under-title-perfil-s-b {
        width: 60px;
        border: 1px #FFCD00 solid;
        margin-left: calc(50% - 30px);
    }
    
    .p-perfil-s {
        color: white;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 10px;
    }
    
    .p-perfil-s-b {
        color: white;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 10px;
    }
    
    .bloque-nombre-s {
        width: 100%;
        position: relative;
        display: block;
        margin-top: -90px;
        margin-bottom: 40px;
    }
    
    .nombre-perfil-s {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        z-index: 100;
        text-align: center;
    }
}

@media (min-width: 768px) {

    .backrow-ejecutivo-s {
        margin-bottom: 80px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 5%, rgba(100,99,99,1) 5%, rgba(100,99,99,1) 95%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
    }
    
    .backrow-ejecutivo-s-b {
        margin-bottom: 80px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 5%, rgba(67,67,67,1) 5%, rgba(67,67,67,1) 95%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
    }
    
    .title-ejecutivos-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .img-perfil-s {
        width: 100%;
        height: auto;
        box-shadow: 2px 6px 11px rgba(0, 0, 0, 0.34); 
        border-radius: 25px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
    
    .title-perfil-s {
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
        text-align: left;
    }
    
    .title-perfil-s-b {
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
        text-align: right;
    }
    
    .under-title-perfil-s {
        width: 60px;
        border: 1px #FFCD00 solid;
        margin-left: 0px;
    }
    
    .under-title-perfil-s-b {
        width: 60px;
        border: 1px #FFCD00 solid;
        margin-left: calc(100% - 60px);
    }
    
    .p-perfil-s {
        color: white;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 10px;
    }
    
    .p-perfil-s-b {
        color: white;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 10px;
    }
    
    .bloque-nombre-s {
        width: 100%;
        position: relative;
        display: block;
        margin-top: -50px;
        margin-bottom: 0px;
    }
    
    .nombre-perfil-s {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        z-index: 100;
        text-align: center;
    }
}

@media (min-width: 992px) {

    .backrow-ejecutivo-s {
        margin-bottom: 110px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 5%, rgba(100,99,99,1) 5%, rgba(100,99,99,1) 95%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
    }
    
    .backrow-ejecutivo-s-b {
        margin-bottom: 110px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 5%, rgba(67,67,67,1) 5%, rgba(67,67,67,1) 95%, rgba(255,255,255,1) 95%, rgba(255,255,255,1) 100%);
    }
    
    .title-ejecutivos-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .img-perfil-s {
        width: 100%;
        height: auto;
        box-shadow: 2px 6px 11px rgba(0, 0, 0, 0.34); 
        border-radius: 25px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
    
    .title-perfil-s {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 30px 0 0 0;
        text-align: left;
    }
    
    .title-perfil-s-b {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 30px 0 0 0;
        text-align: right;
    }
    
    .under-title-perfil-s {
        width: 60px;
        border: 2px #FFCD00 solid;
        margin-left: 0px;
    }
    
    .under-title-perfil-s-b {
        width: 60px;
        border: 2px #FFCD00 solid;
        margin-left: calc(100% - 60px);
    }
    
    .p-perfil-s {
        color: white;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 20px;
    }
    
    .p-perfil-s-b {
        color: white;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 20px;
    }
    
    .bloque-nombre-s {
        width: 100%;
        position: relative;
        display: block;
        margin-top: -60px;
        margin-bottom: 0px;
    }
    
    .nombre-perfil-s {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        z-index: 100;
        text-align: center;
    }
}

@media (min-width: 1200px) {

    .backrow-ejecutivo-s {
        margin-bottom: 120px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 5%, rgba(100,99,99,1) 5%, rgba(100,99,99,1) 90%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    }
    
    .backrow-ejecutivo-s-b {
        margin-bottom: 120px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 5%, rgba(67,67,67,1) 5%, rgba(67,67,67,1) 90%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    }
    
    .title-ejecutivos-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .img-perfil-s {
        width: 100%;
        height: auto;
        box-shadow: 2px 6px 11px rgba(0, 0, 0, 0.34); 
        border-radius: 25px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
    
    .title-perfil-s {
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 60px 0 0 0;
        text-align: left;
    }
    
    .title-perfil-s-b {
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 60px 0 0 0;
        text-align: right;
    }
    
    .under-title-perfil-s {
        width: 60px;
        border: 2px #FFCD00 solid;
        margin-left: 0px;
    }
    
    .under-title-perfil-s-b {
        width: 60px;
        border: 2px #FFCD00 solid;
        margin-left: calc(100% - 60px);
    }
    
    .p-perfil-s {
        color: white;
        font-size: 13px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 20px;
    }
    
    .p-perfil-s-b {
        color: white;
        font-size: 13px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 20px;
    }
    
    .bloque-nombre-s {
        width: 100%;
        position: relative;
        display: block;
        margin-top: -80px;
        margin-bottom: 0px;
    }
    
    .nombre-perfil-s {
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        z-index: 100;
        text-align: center;
    }
}

@media (min-width: 1400px) {

    .backrow-ejecutivo-s {
        margin-bottom: 160px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(100,99,99,1) 10%, rgba(100,99,99,1) 90%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    }
    
    .backrow-ejecutivo-s-b {
        margin-bottom: 160px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(67,67,67,1) 10%, rgba(67,67,67,1) 90%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    }
    
    .title-ejecutivos-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .img-perfil-s {
        width: 100%;
        height: auto;
        box-shadow: 2px 6px 11px rgba(0, 0, 0, 0.34); 
        border-radius: 25px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
    
    .title-perfil-s {
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 60px 0 0 0;
        text-align: left;
    }
    
    .title-perfil-s-b {
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 60px 0 0 0;
        text-align: right;
    }
    
    .under-title-perfil-s {
        width: 60px;
        border: 2px #FFCD00 solid;
        margin-left: 0px;
    }
    
    .under-title-perfil-s-b {
        width: 60px;
        border: 2px #FFCD00 solid;
        margin-left: calc(100% - 60px);
    }
    
    .p-perfil-s {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 20px;
    }
    
    .p-perfil-s-b {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 20px;
    }
    
    .bloque-nombre-s {
        width: 100%;
        position: relative;
        display: block;
        margin-top: -80px;
        margin-bottom: 0px;
    }
    
    .nombre-perfil-s {
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        z-index: 100;
        text-align: center;
    }
}

@media (min-width: 1600px) {

    .backrow-ejecutivo-s {
        margin-bottom: 160px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(100,99,99,1) 10%, rgba(100,99,99,1) 90%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    }
    
    .backrow-ejecutivo-s-b {
        margin-bottom: 160px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(67,67,67,1) 10%, rgba(67,67,67,1) 90%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    }
    
    .title-ejecutivos-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .img-perfil-s {
        width: 100%;
        height: auto;
        box-shadow: 2px 6px 11px rgba(0, 0, 0, 0.34); 
        border-radius: 25px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
    
    .title-perfil-s {
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 60px 0 0 0;
        text-align: left;
    }
    
    .title-perfil-s-b {
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 60px 0 0 0;
        text-align: right;
    }
    
    .under-title-perfil-s {
        width: 60px;
        border: 2px #FFCD00 solid;
        margin-left: 0px;
    }
    
    .under-title-perfil-s-b {
        width: 60px;
        border: 2px #FFCD00 solid;
        margin-left: calc(100% - 60px);
    }
    
    .p-perfil-s {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 20px;
    }
    
    .p-perfil-s-b {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-top: 20px;
    }
    
    .bloque-nombre-s {
        width: 100%;
        position: relative;
        display: block;
        margin-top: -80px;
        margin-bottom: 0px;
    }
    
    .nombre-perfil-s {
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        z-index: 100;
        text-align: center;
    }
}