.CMSIInformacionInteres {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-cmsi-atencion-s {
    width: 100%;
}

.div-cmsi-ii-body {
    width: 100%;
    margin: 40px 0px;
}

.align-left {
    text-align: left;
}
    
.div-cmsi-atencion-body {
    width: 100%;
    margin: 60px 0px;
}

.title-cmsi-atencion-s {
    color: #0885C7;
    font-size: 32px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 60px 0 40px 0;
}

.p-cmsi-atencion-s {
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    margin-top: 10px;
    text-align: justify;
}

.p-cmsi-atencion-s .bold {
    font-weight: 600;
}

.p-sub-cmsi-atencion-s {
    color: #0885C7;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 600;
    line-height: 35.50px;
    text-align: left;
}

.row-cmsi-atencion-content {
    margin: 40px 0px 30px 0px;
}

.cmsi-atencion-table {
    width: calc(100% - 40px);
    text-align: center;
    margin: 0px 20px;
}

.cmsi-atencion-table > thead {
    background-color: #0885C7;
    text-align: left;
    color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    border: 1px #0885C7 solid;
    text-align: center;
}

.cmsi-atencion-table > thead .cmsi-atencion-table-subtitle {
    background-color: #FFCD00;
    border: 1px #FFCD00 solid;
    color: #434343;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
}

.cmsi-atencion-table > thead > tr > td {
    padding: 2px 8px;
}

.cmsi-atencion-table > tbody > tr > td {
    border: 1px #64636399 solid;
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 30px;
}

.cmsi-atencion-table > tfoot {
    background-color: #646363;
    border: 1px #646363 solid;
    text-align: left;
    color: white;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 300;
}

.cmsi-padd-col-atencion {
    margin-top: 30px;
    margin-bottom: 30px;
}

.cmsi-atencion-div-info {
    border-radius: 16px;
    background-color: #EE1B24;
    color: white;
    font-size: 22px;
    font-family: 'Lato';
    font-weight: 800;
    width: 260px;
}

.cmsi-atencion-p-info {
    padding: 0;
    margin: 5px 0 10px 0;
}

.cmsi-atencion-cl-image {
    width: 240px;
    margin: 15px -20px -20px 0;
}

.cmsi-atencion-link-image {
    width: 60px;
    margin-right: 10px;
}

.cmsi-atencion-link-image:hover {
    cursor: pointer;
}

.cmsi-atencion-justify {
    text-align: justify;
}

.atencion-img-t {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    height: auto;
    margin-bottom: 6px;
    width: 100%;
}

.atencion-img-b-l {
    border-bottom-left-radius: 23px;
    height: auto;
    padding-right: 6px;
    width: 100%;
}

.atencion-img-c-r {
    height: auto;
    margin-bottom: 6px;
    width: 100%;
}

.atencion-img-b-r {
    border-bottom-right-radius: 23px;
    height: auto;
    width: 100%;
}

@media (min-width: 576px) {
    
    .div-cmsi-atencion-body {
        width: 100%;
        margin: 60px 0px;
    }
    
    .title-cmsi-atencion-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .p-cmsi-atencion-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-atencion-s .bold {
        font-weight: 600;
    }
    
    .p-sub-cmsi-atencion-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 35.50px;
        text-align: left;
    }
    
    .row-cmsi-atencion-content {
        margin: 40px 0px 30px 0px;
    }

    .cmsi-atencion-table {
        width: calc(100% - 40px);
        text-align: center;
        margin: 0px 20px;
    }
    
    .cmsi-atencion-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .cmsi-atencion-table > thead .cmsi-atencion-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .cmsi-atencion-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-atencion-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 30px;
    }
    
    .cmsi-atencion-table > tfoot {
        background-color: #646363;
        border: 1px #646363 solid;
        text-align: left;
        color: white;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 300;
    }

    .cmsi-padd-col-atencion {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .cmsi-atencion-div-info {
        border-radius: 16px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 260px;
    }
    
    .cmsi-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .cmsi-atencion-cl-image {
        width: 240px;
        margin: 15px -20px -20px 0;
    }
    
    .cmsi-atencion-link-image {
        width: 60px;
        margin-right: 10px;
    }
    
    .cmsi-atencion-link-image:hover {
        cursor: pointer;
    }

    .cmsi-atencion-justify {
        text-align: justify;
    }

    .atencion-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .atencion-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 8px;
        width: 100%;
    }
    
    .atencion-img-c-r {
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .atencion-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
}

@media (min-width: 768px) {
    
    .div-cmsi-atencion-body {
        width: 100%;
        margin: 60px 0px;
    }
    
    .title-cmsi-atencion-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .p-cmsi-atencion-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-atencion-s .bold {
        font-weight: 600;
    }
    
    .p-sub-cmsi-atencion-s {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 35.50px;
        text-align: left;
    }
    
    .row-cmsi-atencion-content {
        margin: 40px 0px 30px 0px;
    }

    .cmsi-atencion-table {
        width: calc(100% - 120px);
        text-align: center;
        margin: 0px 60px;
    }
    
    .cmsi-atencion-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .cmsi-atencion-table > thead .cmsi-atencion-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .cmsi-atencion-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-atencion-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .cmsi-atencion-table > tfoot {
        background-color: #646363;
        border: 1px #646363 solid;
        text-align: left;
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 300;
    }

    .cmsi-padd-col-atencion {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .cmsi-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
    }
    
    .cmsi-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .cmsi-atencion-cl-image {
        width: 240px;
        margin: 15px -50px -20px 0;
    }
    
    .cmsi-atencion-link-image {
        width: 60px;
        margin-right: 10px;
    }
    
    .cmsi-atencion-link-image:hover {
        cursor: pointer;
    }

    .cmsi-atencion-justify {
        text-align: justify;
    }

    .atencion-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .atencion-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 8px;
        width: 100%;
    }
    
    .atencion-img-c-r {
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .atencion-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
}

@media (min-width: 992px) {
    
    .div-cmsi-atencion-body {
        width: 100%;
        margin: 60px 0px;
    }
    
    .title-cmsi-atencion-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .p-cmsi-atencion-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-atencion-s .bold {
        font-weight: 600;
    }
    
    .p-sub-cmsi-atencion-s {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 35.50px;
        text-align: left;
    }
    
    .row-cmsi-atencion-content {
        margin: 40px 0px 30px 0px;
    }

    .cmsi-atencion-table {
        width: calc(100% - 120px);
        text-align: center;
        margin: 0px 60px;
    }
    
    .cmsi-atencion-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .cmsi-atencion-table > thead .cmsi-atencion-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .cmsi-atencion-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-atencion-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .cmsi-atencion-table > tfoot {
        background-color: #646363;
        border: 1px #646363 solid;
        text-align: left;
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 300;
    }

    .cmsi-padd-col-atencion {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .cmsi-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
    }
    
    .cmsi-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .cmsi-atencion-cl-image {
        width: 240px;
        margin: 15px -50px -20px 0;
    }
    
    .cmsi-atencion-link-image {
        width: 60px;
        margin-right: 10px;
    }
    
    .cmsi-atencion-link-image:hover {
        cursor: pointer;
    }

    .cmsi-atencion-justify {
        text-align: justify;
    }

    .atencion-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .atencion-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 8px;
        width: 100%;
    }
    
    .atencion-img-c-r {
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .atencion-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
}

@media (min-width: 1200px) {
    
    .div-cmsi-atencion-body {
        width: 100%;
        margin: 60px 0px;
    }
    
    .title-cmsi-atencion-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .p-cmsi-atencion-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-atencion-s .bold {
        font-weight: 600;
    }
    
    .p-sub-cmsi-atencion-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 35.50px;
        text-align: left;
    }
    
    .row-cmsi-atencion-content {
        margin: 40px 0px 30px 0px;
    }

    .cmsi-atencion-table {
        width: calc(100% - 120px);
        text-align: center;
        margin: 0px 60px;
    }
    
    .cmsi-atencion-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .cmsi-atencion-table > thead .cmsi-atencion-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .cmsi-atencion-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-atencion-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .cmsi-atencion-table > tfoot {
        background-color: #646363;
        border: 1px #646363 solid;
        text-align: left;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 300;
    }

    .cmsi-padd-col-atencion {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .cmsi-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
    }
    
    .cmsi-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .cmsi-atencion-cl-image {
        width: 240px;
        margin: 15px -50px -20px 0;
    }
    
    .cmsi-atencion-link-image {
        width: 60px;
        margin-right: 10px;
    }
    
    .cmsi-atencion-link-image:hover {
        cursor: pointer;
    }

    .cmsi-atencion-justify {
        text-align: justify;
    }

    .atencion-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .atencion-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 10px;
        width: 100%;
    }
    
    .atencion-img-c-r {
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .atencion-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
}

@media (min-width: 1400px) {
    
    .div-cmsi-atencion-body {
        width: 100%;
        margin: 60px 0px;
    }
    
    .title-cmsi-atencion-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .p-cmsi-atencion-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-atencion-s .bold {
        font-weight: 600;
    }
    
    .p-sub-cmsi-atencion-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 35.50px;
        text-align: left;
    }
    
    .row-cmsi-atencion-content {
        margin: 40px 0px 30px 0px;
    }

    .cmsi-atencion-table {
        width: calc(100% - 120px);
        text-align: center;
        margin: 0px 60px;
    }
    
    .cmsi-atencion-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .cmsi-atencion-table > thead .cmsi-atencion-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .cmsi-atencion-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-atencion-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .cmsi-atencion-table > tfoot {
        background-color: #646363;
        border: 1px #646363 solid;
        text-align: left;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 300;
    }

    .cmsi-padd-col-atencion {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .cmsi-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
    }
    
    .cmsi-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .cmsi-atencion-cl-image {
        width: 240px;
        margin: 15px -50px -20px 0;
    }
    
    .cmsi-atencion-link-image {
        width: 60px;
        margin-right: 10px;
    }
    
    .cmsi-atencion-link-image:hover {
        cursor: pointer;
    }

    .cmsi-atencion-justify {
        text-align: justify;
    }

    .atencion-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .atencion-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 10px;
        width: 100%;
    }
    
    .atencion-img-c-r {
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .atencion-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
}

@media (min-width: 1600px) {
    
    .div-cmsi-atencion-body {
        width: 100%;
        margin: 60px 0px;
    }
    
    .title-cmsi-atencion-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 60px 0 40px 0;
    }
    
    .p-cmsi-atencion-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }

    .p-cmsi-atencion-s .bold {
        font-weight: 600;
    }
    
    .p-sub-cmsi-atencion-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 35.50px;
        text-align: left;
    }
    
    .row-cmsi-atencion-content {
        margin: 40px 0px 30px 0px;
    }

    .cmsi-atencion-table {
        width: calc(100% - 120px);
        text-align: center;
        margin: 0px 60px;
    }
    
    .cmsi-atencion-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .cmsi-atencion-table > thead .cmsi-atencion-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .cmsi-atencion-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-atencion-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .cmsi-atencion-table > tfoot {
        background-color: #646363;
        border: 1px #646363 solid;
        text-align: left;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 300;
    }

    .cmsi-padd-col-atencion {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .cmsi-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
    }
    
    .cmsi-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .cmsi-atencion-cl-image {
        width: 240px;
        margin: 15px -50px -20px 0;
    }
    
    .cmsi-atencion-link-image {
        width: 60px;
        margin-right: 10px;
    }
    
    .cmsi-atencion-link-image:hover {
        cursor: pointer;
    }

    .cmsi-atencion-justify {
        text-align: justify;
    }

    .atencion-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .atencion-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 10px;
        width: 100%;
    }
    
    .atencion-img-c-r {
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .atencion-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
}