
.banner-descarga {
    color: white;
    font-size: 22px;
    font-family: 'Lato';
    font-weight: 600;
    margin-bottom: 0;
    line-height: 26px;
    margin-bottom: 8px;
}

.banner-descarga-app {
    color: #646363;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 800;
    border-radius: 16px;
    background-color: white;
    padding: 2px 6px;
}

.banner-descarga-row {
    margin-top: 50px;
    text-align: left;
    padding: 0;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 10%,  #646363 10%, #646363 100%);
}

.row-banner-margin-top {
    margin-top: 25px;
}

.img-banner-celular {
    width: 100%;
    height: auto;
}

.img-banner-flecha {
    width: 75%;
    height: auto;
    top: 30%;
    position: relative;
    left: 0%;
}

.check-icon-banner {
    width: 8px;
    height: auto;
}

.check-text {
    color: white;
    font-size: 6px;
    font-family: 'Lato';
    font-weight: 500;
    margin-bottom: 0;
    line-height: 10px;
}

.check-row {
    line-height: 30px;
}

.img-google-play {
    width: auto;
    height: 38px;
    opacity: 1;
}

.img-google-play:hover {
    opacity: 0.8;
    cursor: pointer;
}

.img-app-store {
    width: auto;
    height: 38px;
    opacity: 1;
    margin-left: 10px;
}

.img-app-store:hover {
    opacity: 0.8;
    cursor: pointer;
}

.row-banner-app-marg {
    margin-top: 0px;
}

.img-qr{
    width: 100px;
    margin-bottom: 30px;
}

@media (min-width: 576px) {
    .banner-descarga {
        color: white;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 36px;
        margin-bottom: 4px;
    }
    
    .banner-descarga-app {
        color: #646363;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 36px;
        border-radius: 34px;
        background-color: white;
        padding: 2px 8px;
    }
    
    .banner-descarga-row {
        margin-top: 100px;
        text-align: left;
    }

    .check-icon-banner {
        width: 20px;
        height: auto;
    }
    
    .check-text {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 500;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 10px;
        padding-left: 20px;
    }

    .check-row {
        line-height: 20px;
    }
    
    .img-google-play {
        width: auto;
        height: 44px;
        opacity: 1;
        margin-left: 0px;
    }
    
    .img-google-play:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-app-store {
        width: auto;
        height: 44px;
        opacity: 1;
        margin-left: 8px;
    }
    
    .img-app-store:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .row-banner-margin-top {
        margin-top: 48px;
    }
    
    .img-banner-celular {
        width: 100%;
        height: auto;
    }
    
    .img-banner-flecha {
        width: 60%;
        height: auto;
        top: 30%;
        position: relative;
        left: 0%;
    }

    .row-banner-app-marg {
        margin-top: 10px;
    }
}

@media (min-width: 768px) {
    .banner-descarga {
        color: white;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 36px;
        margin-bottom: 4px;
    }
    
    .banner-descarga-app {
        color: #646363;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 36px;
        border-radius: 34px;
        background-color: white;
        padding: 2px 8px;
    }
    
    .banner-descarga-row {
        margin-top: 100px;
        text-align: left;
    }

    .check-icon-banner {
        width: 20px;
        height: auto;
    }
    
    .check-text {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 500;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 10px;
        padding-left: 20px;
    }

    .check-row {
        line-height: 20px;
    }
    
    .img-google-play {
        width: auto;
        height: 44px;
        opacity: 1;
        margin-left: 0px;
    }
    
    .img-google-play:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-app-store {
        width: auto;
        height: 44px;
        opacity: 1;
        margin-left: 8px;
    }
    
    .img-app-store:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .row-banner-margin-top {
        margin-top: 48px;
    }
    
    .img-banner-celular {
        width: 100%;
        height: auto;
    }
    
    .img-banner-flecha {
        width: 65%;
        height: auto;
        top: 30%;
        position: relative;
        left: 0%;
    }

    .row-banner-app-marg {
        margin-top: 10px;
    }
}

@media (min-width: 992px) {
    .banner-descarga {
        color: white;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 32px;
        margin-bottom: 4px;
    }
    
    .banner-descarga-app {
        color: #646363;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 32px;
        border-radius: 34px;
        background-color: white;
        padding: 2px 8px;
    }
    
    .banner-descarga-row {
        margin-top: 100px;
        text-align: left;
    }

    .check-icon-banner {
        width: 20px;
        height: auto;
    }
    
    .check-text {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 500;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 10px;
        padding-left: 20px;
    }

    .check-row {
        line-height: 20px;
    }
    
    .img-google-play {
        width: auto;
        height: 36px;
        opacity: 1;
        margin-left: 0px;
    }
    
    .img-google-play:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-app-store {
        width: auto;
        height: 36px;
        opacity: 1;
        margin-left: 8px;
    }
    
    .img-app-store:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .row-banner-margin-top {
        margin-top: 48px;
    }
    
    .img-banner-celular {
        width: 100%;
        height: 100%;
    }
    
    .img-banner-flecha {
        width: 70%;
        height: auto;
        top: 30%;
        position: relative;
        left: 0%;
    }

    .row-banner-app-marg {
        margin-top: 10px;
    }
}

@media (min-width: 1200px) {
    .banner-descarga {
        color: white;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 42px;
        margin-bottom: 8px;
    }
    
    .banner-descarga-app {
        color: #646363;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 42px;
        border-radius: 34px;
        background-color: white;
        padding: 2px 14px;
    }
    
    .banner-descarga-row {
        margin-top: 120px;
        text-align: left;
    }

    .check-icon-banner {
        width: 24px;
        height: auto;
    }
    
    .check-text {
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 500;
        line-height: 30px;
        margin-top: 4px;
        margin-bottom: 10px;
        padding-left: 20px;
    }

    .check-row {
        line-height: 30px;
    }
    
    .img-google-play {
        width: auto;
        height: 48px;
        opacity: 1;
        margin-left: 0px;
    }
    
    .img-google-play:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-app-store {
        width: auto;
        height: 48px;
        opacity: 1;
        margin-left: 10px;
    }
    
    .img-app-store:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .row-banner-margin-top {
        margin-top: 70px;
    }
    
    .img-banner-celular {
        width: 100%;
        height: 100%;
    }
    
    .img-banner-flecha {
        width: 70%;
        height: auto;
        top: 30%;
        position: relative;
        left: 0%;
    }

    .row-banner-app-marg {
        margin-top: 10px;
    }
}

@media (min-width: 1400px) { 
    .banner-descarga {
        color: white;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 43.40px;
        margin-bottom: 10px;
    }
    
    .banner-descarga-app {
        color: #646363;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 43.40px;
        border-radius: 34px;
        background-color: white;
        padding: 2px 14px;
    }
    
    .banner-descarga-row {
        margin-top: 120px;
        text-align: left;
    }

    .check-icon-banner {
        width: 26px;
        height: auto;
    }
    
    .check-text {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 500;
        line-height: 30px;
        margin-top: 4px;
        margin-bottom: 10px;
        padding-left: 20px;
    }

    .check-row {
        line-height: 30px;
    }
    
    .img-google-play {
        width: auto;
        height: 48px;
        opacity: 1;
        margin-left: 0px;
    }
    
    .img-google-play:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-app-store {
        width: auto;
        height: 48px;
        opacity: 1;
        margin-left: 5px;
    }
    
    .img-app-store:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .row-banner-margin-top {
        margin-top: 70px;
    }
    
    .img-banner-celular {
        width: 100%;
        height: 100%;
    }
    
    .img-banner-flecha {
        width: 70%;
        height: auto;
        top: 30%;
        position: relative;
        left: 0%;
    }

    .row-banner-app-marg {
        margin-top: 10px;
    }
}

@media (min-width: 1600px) {
    .banner-descarga {
        color: white;
        font-size: 52px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 60px;
        margin-bottom: 10px;
    }
    
    .banner-descarga-app {
        color: #646363;
        font-size: 44px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 50px;
        border-radius: 34px;
        background-color: white;
        padding: 4px 16px;
    }
    
    .banner-descarga-row {
        margin-top: 150px;
        text-align: left;
    }

    .check-icon-banner {
        width: 30px;
        height: auto;
        margin-top: 15px;
    }
    
    .check-text {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 500;
        line-height: 40px;
        margin-top: 0px;
        margin-bottom: 5px;
        padding-left: 5px;
    }

    .check-row {
        line-height: 30px;
        margin-top: 8px;
    }
    
    .img-google-play {
        width: auto;
        height: 65px;
        opacity: 1;
        margin-left: 0px;
    }
    
    .img-google-play:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-app-store {
        width: auto;
        height: 65px;
        opacity: 1;
        margin-left: 15px;
    }
    
    .img-app-store:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .row-banner-margin-top {
        margin-top: 70px;
    }
    
    .img-banner-celular {
        width: 100%;
        height: 100%;
    }
    
    .img-banner-flecha {
        width: 70%;
        height: auto;
        top: 30%;
        position: relative;
        left: 0%;
    }

    .row-banner-app-marg {
        margin-top: 10px;
    }
}