.Tarifas {
    text-align: center;
}

.div-tarifas-pdf-s {
    width: 100%;
}

.div-tarifas-pdf-body {
    width: 100%;
}

.div-tarifas-pdf-viewer {
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 100vh;
}