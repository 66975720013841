.PRANItinerario {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-pran-itinerario-s {
    width: 100%;
}

.img-pran-itinerario-s {
    width: 100%;
    height: auto;
}

.div-pran-itinerario-body {
    width: 100%;
    margin: 120px 0px;
}

.title-pran-itinerario-s {
    color: #0885C7;
    font-size: 28px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 40px 0 40px 0;
    text-align: left;
}

.p-pran-itinerario-s {
    color: #646363;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 25.60px;
    text-align: left;
}

.p-sub-pran-itinerario-s {
    color: #0885C7;
    font-size: 22px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 30px;
    text-align: center;
    margin-bottom: 40px;
}

.row-pran-itinerario-content {
    margin: 40px 0px 30px 0px;
}

.pran-itinerario-table {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pran-itinerario-table > thead {
    background-color: #0885C7;
    text-align: left;
    color: white;
    font-size: 25px;
    font-family: 'Lato';
    font-weight: 800;
    border: 1px #0885C7 solid;
    text-align: center;
}

.pran-itinerario-table > thead .pran-itinerario-table-subtitle {
    background-color: #FFCD00;
    border: 1px #FFCD00 solid;
    color: #434343;
    font-size: 20px;
    line-height: 42px;
    font-family: 'Lato';
    font-weight: 400;
}

.pran-itinerario-table-td-bold {
    font-weight: 800 !important;
}

.pran-itinerario-table > thead > tr > td {
    padding: 2px 8px;
}

.pran-itinerario-table > tbody > tr > td {
    border-bottom: 1px #64636399 solid;
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 50px;
}

.pran-itinerario-link-image {
    margin-right: 10px;
}

.pran-itinerario-link-image:hover {
    cursor: pointer;
}

.pran-atencion-div-info {
    border-radius: 20px;
    background-color: #EE1B24;
    color: white;
    font-size: 22px;
    font-family: 'Lato';
    font-weight: 800;
    width: 280px;
    padding-top: 5px;
    margin-bottom: 10px;
}

.pran-atencion-div-detalle {
    border-radius: 15px;
    background-color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 500;
    width: 100%;
    color: white;
    margin-bottom: 40px;
    padding: 15px 5px 5px 20px;
    text-align: left;
}

.pran-atencion-div-detalle .list {
    margin-left: -10px;
}

.pran-atencion-div-detalle .p-detalle {
    font-style: italic;
    font-weight: 700;
}

.pran-atencion-p-info {
    padding: 0;
    margin: 5px 0 10px 0;
}

.pran-atencion-cl-image {
    margin: 15px -50px -20px 0;
}

.pran-atencion-p-title-sep {
    margin-top: 100px;
}

@media (min-width: 576px) {

    .div-pran-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pran-itinerario-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-pran-itinerario-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 25.60px;
        text-align: left;
    }
    
    .p-sub-pran-itinerario-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .row-pran-itinerario-content {
        margin: 40px 0px 30px 0px;
    }
    
    .pran-itinerario-table {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .pran-itinerario-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .pran-itinerario-table > thead .pran-itinerario-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 20px;
        line-height: 42px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .pran-itinerario-table-td-bold {
        font-weight: 800 !important;
    }
    
    .pran-itinerario-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .pran-itinerario-table > tbody > tr > td {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pran-itinerario-link-image {
        margin-right: 10px;
    }
    
    .pran-itinerario-link-image:hover {
        cursor: pointer;
    }
    
    .pran-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
        padding-top: 5px;
        margin-bottom: 10px;
    }
    
    .pran-atencion-div-detalle {
        border-radius: 15px;
        background-color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 500;
        width: 100%;
        color: white;
        margin-bottom: 40px;
        padding: 15px 5px 5px 20px;
        text-align: left;
    }
    
    .pran-atencion-div-detalle .list {
        margin-left: -10px;
    }
    
    .pran-atencion-div-detalle .p-detalle {
        font-style: italic;
        font-weight: 700;
    }
    
    .pran-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .pran-atencion-cl-image {
        margin: 15px -50px -20px 0;
    }
    
    .pran-atencion-p-title-sep {
        margin-top: 100px;
    }
}

@media (min-width: 768px) {

    .div-pran-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pran-itinerario-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-pran-itinerario-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 25.60px;
        text-align: left;
    }
    
    .p-sub-pran-itinerario-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .row-pran-itinerario-content {
        margin: 40px 0px 30px 0px;
    }
    
    .pran-itinerario-table {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .pran-itinerario-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .pran-itinerario-table > thead .pran-itinerario-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 20px;
        line-height: 42px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .pran-itinerario-table-td-bold {
        font-weight: 800 !important;
    }
    
    .pran-itinerario-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .pran-itinerario-table > tbody > tr > td {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pran-itinerario-link-image {
        margin-right: 10px;
    }
    
    .pran-itinerario-link-image:hover {
        cursor: pointer;
    }
    
    .pran-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
        padding-top: 5px;
        margin-bottom: 10px;
    }
    
    .pran-atencion-div-detalle {
        border-radius: 15px;
        background-color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 500;
        width: 100%;
        color: white;
        margin-bottom: 40px;
        padding: 15px 5px 5px 20px;
        text-align: left;
    }
    
    .pran-atencion-div-detalle .list {
        margin-left: -10px;
    }
    
    .pran-atencion-div-detalle .p-detalle {
        font-style: italic;
        font-weight: 700;
    }
    
    .pran-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .pran-atencion-cl-image {
        margin: 15px -50px -20px 0;
    }
    
    .pran-atencion-p-title-sep {
        margin-top: 100px;
    }
}

@media (min-width: 992px) {

    .div-pran-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pran-itinerario-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-pran-itinerario-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 25.60px;
        text-align: left;
    }
    
    .p-sub-pran-itinerario-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .row-pran-itinerario-content {
        margin: 40px 0px 30px 0px;
    }
    
    .pran-itinerario-table {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .pran-itinerario-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .pran-itinerario-table > thead .pran-itinerario-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 20px;
        line-height: 42px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .pran-itinerario-table-td-bold {
        font-weight: 800 !important;
    }
    
    .pran-itinerario-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .pran-itinerario-table > tbody > tr > td {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pran-itinerario-link-image {
        margin-right: 10px;
    }
    
    .pran-itinerario-link-image:hover {
        cursor: pointer;
    }
    
    .pran-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
        padding-top: 5px;
        margin-bottom: 10px;
    }
    
    .pran-atencion-div-detalle {
        border-radius: 15px;
        background-color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 500;
        width: 100%;
        color: white;
        margin-bottom: 40px;
        padding: 15px 5px 5px 20px;
        text-align: left;
    }
    
    .pran-atencion-div-detalle .list {
        margin-left: -10px;
    }
    
    .pran-atencion-div-detalle .p-detalle {
        font-style: italic;
        font-weight: 700;
    }
    
    .pran-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .pran-atencion-cl-image {
        margin: 15px -50px -20px 0;
    }
    
    .pran-atencion-p-title-sep {
        margin-top: 100px;
    }
}

@media (min-width: 1200px) {

    .div-pran-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pran-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-pran-itinerario-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 25.60px;
        text-align: left;
    }
    
    .p-sub-pran-itinerario-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .row-pran-itinerario-content {
        margin: 40px 0px 30px 0px;
    }
    
    .pran-itinerario-table {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .pran-itinerario-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .pran-itinerario-table > thead .pran-itinerario-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 20px;
        line-height: 42px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .pran-itinerario-table-td-bold {
        font-weight: 800 !important;
    }
    
    .pran-itinerario-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .pran-itinerario-table > tbody > tr > td {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 15px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pran-itinerario-link-image {
        margin-right: 10px;
    }
    
    .pran-itinerario-link-image:hover {
        cursor: pointer;
    }
    
    .pran-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
        padding-top: 5px;
        margin-bottom: 10px;
    }
    
    .pran-atencion-div-detalle {
        border-radius: 15px;
        background-color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 500;
        width: 100%;
        color: white;
        margin-bottom: 40px;
        padding: 15px 5px 5px 20px;
        text-align: left;
    }
    
    .pran-atencion-div-detalle .list {
        margin-left: -10px;
    }
    
    .pran-atencion-div-detalle .p-detalle {
        font-style: italic;
        font-weight: 700;
    }
    
    .pran-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .pran-atencion-cl-image {
        margin: 15px -50px -20px 0;
    }
    
    .pran-atencion-p-title-sep {
        margin-top: 100px;
    }
}

@media (min-width: 1400px) {

    .div-pran-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pran-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-pran-itinerario-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 25.60px;
        text-align: left;
    }
    
    .p-sub-pran-itinerario-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .row-pran-itinerario-content {
        margin: 40px 0px 30px 0px;
    }
    
    .pran-itinerario-table {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .pran-itinerario-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .pran-itinerario-table > thead .pran-itinerario-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 20px;
        line-height: 42px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .pran-itinerario-table-td-bold {
        font-weight: 800 !important;
    }
    
    .pran-itinerario-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .pran-itinerario-table > tbody > tr > td {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pran-itinerario-link-image {
        margin-right: 10px;
    }
    
    .pran-itinerario-link-image:hover {
        cursor: pointer;
    }
    
    .pran-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
        padding-top: 5px;
        margin-bottom: 10px;
    }
    
    .pran-atencion-div-detalle {
        border-radius: 15px;
        background-color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 500;
        width: 100%;
        color: white;
        margin-bottom: 40px;
        padding: 15px 5px 5px 20px;
        text-align: left;
    }
    
    .pran-atencion-div-detalle .list {
        margin-left: -10px;
    }
    
    .pran-atencion-div-detalle .p-detalle {
        font-style: italic;
        font-weight: 700;
    }
    
    .pran-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .pran-atencion-cl-image {
        margin: 15px -50px -20px 0;
    }
    
    .pran-atencion-p-title-sep {
        margin-top: 100px;
    }
}

@media (min-width: 1600px) {

    .div-pran-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pran-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 40px 0 40px 0;
        text-align: left;
    }
    
    .p-pran-itinerario-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 25.60px;
        text-align: left;
    }
    
    .p-sub-pran-itinerario-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .row-pran-itinerario-content {
        margin: 40px 0px 30px 0px;
    }
    
    .pran-itinerario-table {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .pran-itinerario-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 800;
        border: 1px #0885C7 solid;
        text-align: center;
    }
    
    .pran-itinerario-table > thead .pran-itinerario-table-subtitle {
        background-color: #FFCD00;
        border: 1px #FFCD00 solid;
        color: #434343;
        font-size: 20px;
        line-height: 42px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .pran-itinerario-table-td-bold {
        font-weight: 800 !important;
    }
    
    .pran-itinerario-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .pran-itinerario-table > tbody > tr > td {
        border-bottom: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pran-itinerario-link-image {
        margin-right: 10px;
    }
    
    .pran-itinerario-link-image:hover {
        cursor: pointer;
    }
    
    .pran-atencion-div-info {
        border-radius: 20px;
        background-color: #EE1B24;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        width: 280px;
        padding-top: 5px;
        margin-bottom: 10px;
    }
    
    .pran-atencion-div-detalle {
        border-radius: 15px;
        background-color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 500;
        width: 100%;
        color: white;
        margin-bottom: 40px;
        padding: 15px 5px 5px 20px;
        text-align: left;
    }
    
    .pran-atencion-div-detalle .list {
        margin-left: -10px;
    }
    
    .pran-atencion-div-detalle .p-detalle {
        font-style: italic;
        font-weight: 700;
    }
    
    .pran-atencion-p-info {
        padding: 0;
        margin: 5px 0 10px 0;
    }
    
    .pran-atencion-cl-image {
        margin: 15px -50px -20px 0;
    }
    
    .pran-atencion-p-title-sep {
        margin-top: 100px;
    }
}