.btn-delete-admin {
    position: absolute;
    z-index: 10;
    left: calc(100% - 50px);
}

.btn-delete-admin:hover {
    opacity: 0.8;
    cursor: pointer;
}

.content-admin-carrousel {
    padding: 40px 20px 80px 20px;
}