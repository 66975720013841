.Rutas {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-rutas-s {
    width: 100%;
}

.img-rutas-s {
    width: 100%;
    height: auto;
}

.img-rutas-magdalena {
    width: 50%;
    height: auto;
}
.div-rutas-body {
    width: 100%;
    margin: 20px 0px;
}

.row-rutas-s {
    text-align: left;
}

.title-rutas-s {
    color: #0885C7;
    font-size: 24px;
    font-family: 'Lato';
    font-weight: 700;
    margin: 0;
}

.subtitle-rutas-s {
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 0;
    text-align: left;
    cursor:pointer;
}

.p-rutas-s {
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    margin: 0px 0px 30px 20px;
    text-align: justify;
}

.p-rutas-nave {
    color: #0885C7;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 400;
    text-align: left;
    margin-left: 30px;
    margin-top: 40px;
}

.p-rutas-nave .bold {
    font-weight: 600;
}

.rutas-indicador-nave-s {
    height: 13px;
    width: auto;
}

.icono-nave-s {
    width: 24px;
    height: auto;
}

.button-servicio-s-mag{
    background-color: #FFCD00;
    border: 1px #FFCD00 solid;
    color: #646363 !important;
    font-size: 16px !important;
    font-family: 'Lato' !important;
    font-weight: 600 !important;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15) !important;
}

.button-servicio-s-mag:hover{
    background-color: #FFCD00;
    border: 1px #FFCD00 solid;
    color: #646363 !important;
    font-size: 16px !important;
    font-family: 'Lato' !important;
    font-weight: 600 !important;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15) !important;
}

.separador-rutas-s {
    border: 1px #FFCD00 solid;
    width: 0px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    display: inline;
}

.row-rutas-content {
    margin: 40px 0px 30px 0px;
}

.click-rutas:hover {
    cursor: pointer;
}

@media (min-width: 576px) {
    
    .div-rutas-body {
        width: 100%;
        margin: 80px 0px;
    }
    
    .title-rutas-s {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
    }
    
    .subtitle-rutas-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-rutas-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 40px 30px;
        text-align: justify;
    }
    
    .p-rutas-nave {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
        margin-top: 30px;
    }
    
    .p-rutas-nave .bold {
        font-weight: 600;
    }
    
    .rutas-indicador-nave-s {
        height: 20px;
        width: auto;
    }
    
    .separador-rutas-s {
        border: 1px #FFCD00 solid;
        width: 0px;
        height: 16px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-rutas-content {
        margin: 20px 0px 20px 0px;
    }
}

@media (min-width: 768px) {
    
    .div-rutas-body {
        width: 100%;
        margin: 80px 0px;
    }
    
    .title-rutas-s {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
    }
    
    .subtitle-rutas-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-rutas-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 40px 30px;
        text-align: justify;
    }
    
    .p-rutas-nave {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
        margin-top: 30px;
    }
    
    .p-rutas-nave .bold {
        font-weight: 600;
    }
    
    .rutas-indicador-nave-s {
        height: 20px;
        width: auto;
    }
    
    .separador-rutas-s {
        border: 1px #FFCD00 solid;
        width: 0px;
        height: 16px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-rutas-content {
        margin: 20px 0px 20px 0px;
    }
}

@media (min-width: 992px) {
    
    .div-rutas-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-rutas-s {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
    }
    
    .subtitle-rutas-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-rutas-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 40px 30px;
        text-align: justify;
    }
    
    .p-rutas-nave {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
        margin-top: 0px;
    }
    
    .p-rutas-nave .bold {
        font-weight: 600;
    }
    
    .rutas-indicador-nave-s {
        height: 20px;
        width: auto;
    }
    
    .separador-rutas-s {
        border: 2px #FFCD00 solid;
        width: 0px;
        height: 16px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-rutas-content {
        margin: 20px 0px 20px 0px;
    }
}

@media (min-width: 1200px) {
    
    .div-rutas-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-rutas-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
    }
    
    .subtitle-rutas-s {
        color: #646363;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-rutas-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 40px 30px;
        text-align: justify;
    }
    
    .p-rutas-nave {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
        margin-top: 0px;
    }
    
    .p-rutas-nave .bold {
        font-weight: 600;
    }
    
    .rutas-indicador-nave-s {
        height: 20px;
        width: auto;
    }
    
    .separador-rutas-s {
        border: 2px #FFCD00 solid;
        width: 0px;
        height: 18px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-rutas-content {
        margin: 30px 0px 30px 0px;
    }
}

@media (min-width: 1400px) {
    
    .div-rutas-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-rutas-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
    }
    
    .subtitle-rutas-s {
        color: #646363;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-rutas-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 40px 30px;
        text-align: justify;
    }
    
    .p-rutas-nave {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
        margin-top: 0px;
    }
    
    .p-rutas-nave .bold {
        font-weight: 600;
    }
    
    .rutas-indicador-nave-s {
        height: 20px;
        width: auto;
    }
    
    .separador-rutas-s {
        border: 2px #FFCD00 solid;
        width: 0px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-rutas-content {
        margin: 40px 0px 30px 0px;
    }
}

@media (min-width: 1600px) {
    
    .div-rutas-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-rutas-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 0;
    }
    
    .subtitle-rutas-s {
        color: #646363;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-rutas-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 40px 30px;
        text-align: justify;
    }
    
    .p-rutas-nave {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
        margin-top: 0px;
    }
    
    .p-rutas-nave .bold {
        font-weight: 600;
    }
    
    .rutas-indicador-nave-s {
        height: 20px;
        width: auto;
    }
    
    .separador-rutas-s {
        border: 2px #FFCD00 solid;
        width: 0px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px;
        display: inline;
    }
    
    .row-rutas-content {
        margin: 40px 0px 30px 0px;
    }
}