.img-admin {
    width: 100%;
    height: auto;
    margin-top: 20px;
}


.img-admin-clickable:hover {
    opacity: 0.8;
    cursor: pointer;
}

.p-titulo-admin {
    color: #0885C7;
    font-size: 40px;
    font-family: 'Lato';
    font-weight: 600;
}


.p-subtitulo-admin {
    color: #0885C7;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 600;
}

.label-admin {
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
}

.label-detalle-admin {
    color: #0885C7;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-field-admin {
    border: 1px #178DCB99 solid;
    margin-bottom: 20px;
}

.btn-admin {
    color: white;
    background-color: #0885C7;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 600;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.btn-admin:hover {
    background-color: #0675b0;
}

.btn-admin-back {
    color: #0885C7;
    background-color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 600;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.btn-admin-back:hover {
    background-color: #0675b0;
}