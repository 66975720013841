.btn-admin-add {
    margin-top: 30px;
    background-color: #0885C7;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    font-family: "Lato";
}

.div-coords-admin {
    height: 300px;
    overflow-y: scroll;
}

.btn-admin-remove {
    background-color: #EE3333;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 400;
    font-family: "Lato";
    border: 1px #EE3333 solid;
}

.btn-admin-remove:hover {
    background-color: #FFFFFF;
    color: #EE3333;
    border: 1px #EE3333 solid;
}

.div-pdf-body-admin {
    width: 100%;
}

.div-pdf-viewer-admin {
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 340px;
}

.table-admin-serv {
    width: 100%;
}

.table-admin-serv .td-body {
    border: 1px #646363 solid;
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    margin-top: 10px;
    text-align: justify;
    padding: 5px 15px;
    width: 40%;
}

.ferry-img-t {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    height: auto;
    margin-bottom: 10px;
    width: 100%;
}

.ferry-img-b-l {
    border-bottom-left-radius: 23px;
    height: auto;
    padding-right: 10px;
    width: 100%;
}

.ferry-img-c-r {
    height: auto;
    margin-bottom: 10px;
    width: 100%;
}

.ferry-img-b-r {
    border-bottom-right-radius: 23px;
    height: auto;
    width: 100%;
}

.cmsi-atencion-p-info-centered {
    text-align: center;
}

.cmsi-padd-col-atencion-delete:hover {
    background-color: rgba(255,0,0,0.2);
    border: 1px red solid;
}

.cmsi-padd-col-atencion-delete:hover::after {
    content: "*IMPORTANTE*: Al hacer click eliminarás el elemento.";
    color: red;
}