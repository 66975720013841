
.footer-back {
    background-color: #D9D9D926;
    min-height: 240px;
    padding: 50px 32px
}

.footer-copyrights {
    height: 40px;
    font-family: 'Lato';
    color: #00276C;
    background-color: white;
    font-size: 10px;
    font-weight: 400;
    line-height: 24px;
    justify-content: center;
}

.footer-logo {
    margin-top: 35px;
    width: 140px;
    height: auto;
}

.footer-buttons {
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    text-decoration: none;
    margin-bottom: 20px;
    cursor:pointer;
}

.footer-button-image {
    width: 24px;
    height: auto;
}

.icon-rrss-first {
    margin-left: 0px;
}
.icon-rrss {
    margin-left: 15px
}

.swal-wide {
    width: 600px !important;
}

@media (min-width: 576px) { 
    
    .footer-back {
        background-color: #D9D9D926;
        height: 520px;
        padding: 50px 32px
    }
    
    .footer-copyrights {
        height: 40px;
        font-family: 'Lato';
        color: #00276C;
        background-color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 30px;
        justify-content: center;
    }
    
    .footer-logo {
        margin-top: 35px;
        width: 270px;
        height: auto;
    }
    
    .footer-buttons {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        text-decoration: none;
        margin-bottom: 20px;
        line-height: 30px;
    }
    
    .footer-button-image {
        width: 22px;
        height: auto;
    }
}

@media (min-width: 768px) {
    
    .footer-back {
        background-color: #D9D9D926;
        height: 520px;
        padding: 50px 32px
    }
    
    .footer-copyrights {
        height: 40px;
        font-family: 'Lato';
        color: #00276C;
        background-color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 30px;
        justify-content: center;
    }
    
    .footer-logo {
        margin-top: 35px;
        width: 270px;
        height: auto;
    }
    
    .footer-buttons {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        text-decoration: none;
        margin-bottom: 20px;
        line-height: 30px;
    }
    
    .footer-button-image {
        width: 22px;
        height: auto;
    }

    .icon-rrss-first {
        display: flex;
        justify-content: center;
    }
    .icon-rrss {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 992px) {
    
    .footer-back {
        background-color: #D9D9D926;
        height: 200px;
        padding: 50px 32px
    }
    
    .footer-copyrights {
        height: 40px;
        font-family: 'Lato';
        color: #00276C;
        background-color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        justify-content: center;
    }
    
    .footer-logo {
        margin-top: 35px;
        width: 270px;
        height: auto;
    }
    
    .footer-buttons {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        text-decoration: none;
        margin-bottom: 20px;
        line-height: 24px;
    }
    
    .footer-button-image {
        width: 22px;
        height: auto;
    }

    .icon-rrss-first {
        margin-left: 0px;
    }
    .icon-rrss {
        margin-left: 15px
    }
}

@media (min-width: 1200px) {
    
    .footer-back {
        background-color: #D9D9D926;
        height: 200px;
        padding: 50px 32px
    }
    
    .footer-copyrights {
        height: 40px;
        font-family: 'Lato';
        color: #00276C;
        background-color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        justify-content: center;
    }
    
    .footer-logo {
        margin-top: 35px;
        width: 270px;
        height: auto;
    }
    
    .footer-buttons {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        text-decoration: none;
        margin-bottom: 20px;
        line-height: 24px;
    }
    
    .footer-button-image {
        width: 24px;
        height: auto;
    }

    .icon-rrss-first {
        margin-left: 0px;
    }
    .icon-rrss {
        margin-left: 5px
    }
}

@media (min-width: 1400px) {
    
    .footer-back {
        background-color: #D9D9D926;
        height: 240px;
        padding: 50px 32px
    }
    
    .footer-copyrights {
        height: 40px;
        font-family: 'Lato';
        color: #00276C;
        background-color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        justify-content: center;
    }
    
    .footer-logo {
        margin-top: 35px;
        width: 270px;
        height: auto;
    }
    
    .footer-buttons {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        text-decoration: none;
        margin-bottom: 20px;
        line-height: 24px;
    }
    
    .footer-button-image {
        width: 24px;
        height: auto;
    }

    .icon-rrss-first {
        margin-left: 0px;
    }
    .icon-rrss {
        margin-left: 5px
    }
}

@media (min-width: 1600px) {
    .footer-back {
        background-color: #D9D9D926;
        height: 280px;
        padding: 50px 32px
    }
    
    .footer-copyrights {
        height: 40px;
        font-family: 'Lato';
        color: #00276C;
        background-color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        justify-content: center;
    }
    
    .footer-logo {
        margin-top: 25px;
        width: 300px;
        height: auto;
    }
    
    .footer-buttons {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-decoration: none;
        margin-bottom: 20px;
        line-height: 40px;
    }
    
    .footer-button-image {
        width: 36px;
        height: auto;
    }
    .icon-rrss-first {
        margin-left: 0px;
    }
    .icon-rrss {
        margin-left: 5px
    }
}