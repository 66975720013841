.Historia {
    text-align: center;
}

.div-historia-s {
    width: 100%;
}

.img-historia-s {
    width: 100%;
    height: auto;
}

.div-historia-body {
    width: 100%;
    margin: 100px 0px 100px 0px;
}

.row-historia-s {
    text-align: left;
    margin-bottom: 60px;
}

.row-line-historia {
    margin-bottom: 20px;
}

.title-historia-s {
    color: #0885C7;
    font-size: 28px;
    font-family: 'Lato';
    font-weight: 900;
    margin: 0 0 20px 0;
}

.line-historia {
    background-color: #434343;
    width: 40px;
    height: 270px;
    margin-left: calc(50% - 20px);
    padding-top: 20px;
}

.line-historia-s {
    border-top-right-radius: 23px;
    border-top-left-radius: 23px;
}

.line-historia-l::before {
    content: url(src/line-r-md.svg);
    margin-left: 25%;
}

.line-historia-r {
    background-color: #646363;
}

.line-historia-r::before {
    content: url(src/line-r.svg);
    margin-left: 25%;
}

.line-historia-e {
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
}

.line-title {
    color: #0885C7;
    font-size: 26px;
    font-family: 'Lato';
    font-weight: 900;
    margin-top: 15px;
}

.line-title-r {
    text-align: left;
    color: #646363;
    margin-left: 40px;
}

.line-title-l {
    text-align: left;
    color: #434343;
    margin-left: 40px;
}

.line-text {
    color: black;
    font-size: 13px;
    font-family: 'Lato';
    font-weight: 400;
    text-align: justify;
    margin-bottom: calc(-100% + 40px);
}

.img-line-s {
    width: 100%;
    height: auto;
    margin-top: -65px;
    border-top-right-radius: 25px;
}

.img-line-e {
    width: 100%;
    height: auto;
    border-bottom-left-radius: 25px;
}

@media (min-width: 576px) {

    .img-historia-s {
        width: 100%;
        height: auto;
    }
    
    .div-historia-body {
        width: 100%;
        margin: 100px 0px 100px 0px;
    }
    
    .row-historia-s {
        text-align: left;
        margin-bottom: 80px;
    }
    
    .row-line-historia {
        margin-bottom: 20px;
    }
    
    .title-historia-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .line-historia {
        background-color: #434343;
        width: 40px;
        height: 220px;
        margin-left: calc(50% - 20px);
        padding-top: 20px;
    }
    
    .line-historia-s {
        border-top-right-radius: 23px;
        border-top-left-radius: 23px;
    }
    
    .line-historia-l::before {
        content: url(src/line-r-md.svg);
        margin-left: 25%;
    }
    
    .line-historia-r {
        background-color: #646363;
    }
    
    .line-historia-r::before {
        content: url(src/line-r.svg);
        margin-left: 25%;
    }
    
    .line-historia-e {
        border-bottom-right-radius: 23px;
        border-bottom-left-radius: 23px;
    }
    
    .line-title {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 900;
        margin-top: 15px;
    }
    
    .line-title-r {
        text-align: left;
        color: #646363;
        margin-left: 30px;
    }
    
    .line-title-l {
        text-align: left;
        color: #434343;
        margin-left: 30px;
    }
    
    .line-text {
        color: black;
        font-size: 13px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-bottom: calc(-100% + 40px);
    }
    
    .img-line-s {
        width: 100%;
        height: auto;
        margin-top: -65px;
        border-top-right-radius: 25px;
    }
    
    .img-line-e {
        width: 100%;
        height: auto;
        border-bottom-left-radius: 25px;
    }
}

@media (min-width: 768px) {

    .img-historia-s {
        width: 100%;
        height: auto;
    }
    
    .div-historia-body {
        width: 100%;
        margin: 100px 0px 100px 0px;
    }
    
    .row-historia-s {
        text-align: left;
        margin-bottom: 100px;
    }
    
    .row-line-historia {
        margin-bottom: 20px;
    }
    
    .title-historia-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .line-historia {
        background-color: #434343;
        width: 40px;
        height: 220px;
        margin-left: calc(50% - 20px);
        padding-top: 20px;
    }
    
    .line-historia-s {
        border-top-right-radius: 23px;
        border-top-left-radius: 23px;
    }
    
    .line-historia-l::before {
        content: url(src/line-r-md.svg);
        margin-left: 25%;
    }
    
    .line-historia-r {
        background-color: #646363;
    }
    
    .line-historia-r::before {
        content: url(src/line-r.svg);
        margin-left: 25%;
    }
    
    .line-historia-e {
        border-bottom-right-radius: 23px;
        border-bottom-left-radius: 23px;
    }
    
    .line-title {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 900;
        margin-top: 15px;
    }
    
    .line-title-r {
        text-align: left;
        color: #646363;
        margin-left: 0px;
    }
    
    .line-title-l {
        text-align: left;
        color: #434343;
        margin-left: 0px;
    }
    
    .line-text {
        color: black;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-bottom: calc(-100% + 40px);
    }
    
    .img-line-s {
        width: 100%;
        height: auto;
        margin-top: -65px;
        border-top-right-radius: 25px;
    }
    
    .img-line-e {
        width: 100%;
        height: auto;
        border-bottom-left-radius: 25px;
    }
}

@media (min-width: 992px) {

    .img-historia-s {
        width: 100%;
        height: auto;
    }
    
    .div-historia-body {
        width: 100%;
        margin: 100px 0px 300px 0px;
    }
    
    .row-historia-s {
        text-align: left;
        margin-bottom: 120px;
    }
    
    .row-line-historia {
        margin-bottom: 20px;
    }
    
    .title-historia-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .line-historia {
        background-color: #434343;
        width: 40px;
        height: 170px;
        margin-left: calc(50% - 20px);
        padding-top: 20px;
    }
    
    .line-historia-s {
        border-top-right-radius: 23px;
        border-top-left-radius: 23px;
    }
    
    .line-historia-l::before {
        content: url(src/line-l.svg);
        margin-left: -70px;
    }
    
    .line-historia-r {
        background-color: #646363;
    }
    
    .line-historia-r::before {
        content: url(src/line-r.svg);
        margin-left: 25%;
    }
    
    .line-historia-e {
        border-bottom-right-radius: 23px;
        border-bottom-left-radius: 23px;
    }
    
    .line-title {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 900;
        margin-top: 15px;
    }
    
    .line-title-r {
        text-align: right;
        color: #646363;
        margin-left: 0px;
    }
    
    .line-title-l {
        text-align: left;
        color: #434343;
        margin-left: 0px;
    }
    
    .line-text {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-bottom: calc(-100% + 40px);
    }
    
    .img-line-s {
        width: 100%;
        height: auto;
        margin-top: -65px;
        border-top-right-radius: 25px;
    }
    
    .img-line-e {
        width: 100%;
        height: auto;
        border-bottom-left-radius: 25px;
    }
}

@media (min-width: 1200px) {

    .img-historia-s {
        width: 100%;
        height: auto;
    }
    
    .div-historia-body {
        width: 100%;
        margin: 100px 0px 300px 0px;
    }
    
    .row-historia-s {
        text-align: left;
        margin-bottom: 120px;
    }
    
    .row-line-historia {
        margin-bottom: 20px;
    }
    
    .title-historia-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .line-historia {
        background-color: #434343;
        width: 46px;
        height: 170px;
        margin-left: calc(50% - 23px);
        padding-top: 20px;
    }
    
    .line-historia-s {
        border-top-right-radius: 23px;
        border-top-left-radius: 23px;
    }
    
    .line-historia-l::before {
        content: url(src/line-l.svg);
        margin-left: -105px;
    }
    
    .line-historia-r {
        background-color: #646363;
    }
    
    .line-historia-r::before {
        content: url(src/line-r.svg);
        margin-left: 55%;
    }
    
    .line-historia-e {
        border-bottom-right-radius: 23px;
        border-bottom-left-radius: 23px;
    }
    
    .line-title {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 900;
        margin-top: 15px;
    }
    
    .line-title-r {
        text-align: right;
        color: #646363;
        margin-left: 0px;
    }
    
    .line-title-l {
        text-align: left;
        color: #434343;
        margin-left: 0px;
    }
    
    .line-text {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-bottom: calc(-100% + 40px);
    }
    
    .img-line-s {
        width: 100%;
        height: auto;
        margin-top: -65px;
        border-top-right-radius: 25px;
    }
    
    .img-line-e {
        width: 100%;
        height: auto;
        border-bottom-left-radius: 25px;
    }
}

@media (min-width: 1400px) {

    .img-historia-s {
        width: 100%;
        height: auto;
    }
    
    .div-historia-body {
        width: 100%;
        margin: 100px 0px 300px 0px;
    }
    
    .row-historia-s {
        text-align: left;
        margin-bottom: 120px;
    }
    
    .row-line-historia {
        margin-bottom: 20px;
    }
    
    .title-historia-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .line-historia {
        background-color: #434343;
        width: 46px;
        height: 170px;
        margin-left: calc(50% - 23px);
        padding-top: 20px;
    }
    
    .line-historia-s {
        border-top-right-radius: 23px;
        border-top-left-radius: 23px;
    }
    
    .line-historia-l::before {
        content: url(src/line-l.svg);
        margin-left: -105px;
    }
    
    .line-historia-r {
        background-color: #646363;
    }
    
    .line-historia-r::before {
        content: url(src/line-r.svg);
        margin-left: 55%;
    }
    
    .line-historia-e {
        border-bottom-right-radius: 23px;
        border-bottom-left-radius: 23px;
    }
    
    .line-title {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 900;
        margin-top: 15px;
    }
    
    .line-title-r {
        text-align: right;
        color: #646363;
        margin-left: 0px;
    }
    
    .line-title-l {
        text-align: left;
        color: #434343;
        margin-left: 0px;
    }
    
    .line-text {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-bottom: calc(-100% + 40px);
    }
    
    .img-line-s {
        width: 100%;
        height: auto;
        margin-top: -65px;
        border-top-right-radius: 25px;
    }
    
    .img-line-e {
        width: 100%;
        height: auto;
        border-bottom-left-radius: 25px;
    }
}

@media (min-width: 1600px) {

    .img-historia-s {
        width: 100%;
        height: auto;
    }
    
    .div-historia-body {
        width: 100%;
        margin: 100px 0px 300px 0px;
    }
    
    .row-historia-s {
        text-align: left;
        margin-bottom: 120px;
    }
    
    .row-line-historia {
        margin-bottom: 20px;
    }
    
    .title-historia-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 900;
        margin: 0 0 20px 0;
    }
    
    .line-historia {
        background-color: #434343;
        width: 46px;
        height: 170px;
        margin-left: calc(50% - 23px);
        padding-top: 20px;
    }
    
    .line-historia-s {
        border-top-right-radius: 23px;
        border-top-left-radius: 23px;
    }
    
    .line-historia-l::before {
        content: url(src/line-l.svg);
        margin-left: -105px;
    }
    
    .line-historia-r {
        background-color: #646363;
    }
    
    .line-historia-r::before {
        content: url(src/line-r.svg);
        margin-left: 55%;
    }
    
    .line-historia-e {
        border-bottom-right-radius: 23px;
        border-bottom-left-radius: 23px;
    }
    
    .line-title {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 900;
        margin-top: 15px;
    }
    
    .line-title-r {
        text-align: right;
        color: #646363;
        margin-left: 0px;
    }
    
    .line-title-l {
        text-align: left;
        color: #434343;
        margin-left: 0px;
    }
    
    .line-text {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: justify;
        margin-bottom: calc(-100% + 40px);
    }
    
    .img-line-s {
        width: 100%;
        height: auto;
        margin-top: -65px;
        border-top-right-radius: 25px;
    }
    
    .img-line-e {
        width: 100%;
        height: auto;
        border-bottom-left-radius: 25px;
    }
}