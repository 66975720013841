
.mapouter {
    position: relative;
    height: 520px;
    width: 100%;
    background:#fff;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); 
    border-radius: 16px;
    margin: 0 !important;
}

.maprouter a {
    color:#fff !important;
    position: absolute !important;
    top: 0 !important;
    z-index:0 !important;
}

.gmap_canvas {
    overflow: hidden;
    height: 520px;
    width: 100%;
}

.gmap_canvas iframe {
    position: relative;
    z-index: 2;
    height: 520px;
    width: 100%;
    border-radius: 20px;
}
