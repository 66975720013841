.PAPOItinerario {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-papo-itinerario-s {
    width: 100%;
}

.img-papo-itinerario-s {
    width: 100%;
    height: auto;
}

.div-papo-itinerario-body {
    width: 100%;
    margin: 20px 0px;
}

.div-papo-itinerario-body {
    width: 100%;
    margin: 120px 0px;
}

.title-papo-itinerario-s {
    color: #0885C7;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 500;
    line-height: 30px;
    text-align: left;
}

.papo-itinerario-table {
    width: 100%;
    text-align: center;
}

.papo-itinerario-table .day-col {
    line-height: 50px;
    width: 30%;
}

.papo-itinerario-table .day-col > div {
    background-color: #0885C7;
    width: calc(100% - 3px);
    margin: 3px;
    color: white;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 500;
}

.papo-itinerario-table .departure-col {
    line-height: 50px;
    width: 35%;
}

.papo-itinerario-table .departure-col > div {
    background-color: #FFCD00;
    width: calc(100% - 3px);
    margin: 3px;
    color: #434343;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
}

.papo-itinerario-table > tbody > tr {
    border-bottom: 1px solid #64636399;
    line-height: 30px;
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    align-items: start;
}

@media (min-width: 576px) {

    .div-papo-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }

    .title-papo-itinerario-s {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }

    .papo-itinerario-table {
        width: 100%;
        text-align: center;
    }

    .papo-itinerario-table .day-col {
        line-height: 50px;
        width: 30%;
    }

    .papo-itinerario-table .day-col > div {
        background-color: #0885C7;
        width: calc(100% - 3px);
        margin: 3px;
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table .departure-col {
        line-height: 50px;
        width: 35%;
    }

    .papo-itinerario-table .departure-col > div {
        background-color: #FFCD00;
        width: calc(100% - 3px);
        margin: 3px;
        color: #434343;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table > tbody > tr {
        border-bottom: 1px solid #64636399;
        line-height: 30px;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        align-items: start;
    }
}

@media (min-width: 768px) {

    .div-papo-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }

    .title-papo-itinerario-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }

    .papo-itinerario-table {
        width: 100%;
        text-align: center;
    }

    .papo-itinerario-table .day-col {
        line-height: 50px;
        width: 30%;
    }

    .papo-itinerario-table .day-col > div {
        background-color: #0885C7;
        width: calc(100% - 3px);
        margin: 3px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table .departure-col {
        line-height: 50px;
        width: 35%;
    }

    .papo-itinerario-table .departure-col > div {
        background-color: #FFCD00;
        width: calc(100% - 3px);
        margin: 3px;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table > tbody > tr {
        border-bottom: 1px solid #64636399;
        line-height: 30px;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        align-items: start;
    }
}

@media (min-width: 992px) {

    .div-papo-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }

    .title-papo-itinerario-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }

    .papo-itinerario-table {
        width: 100%;
        text-align: center;
    }

    .papo-itinerario-table .day-col {
        line-height: 50px;
        width: 30%;
    }

    .papo-itinerario-table .day-col > div {
        background-color: #0885C7;
        width: calc(100% - 3px);
        margin: 3px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table .departure-col {
        line-height: 50px;
        width: 35%;
    }

    .papo-itinerario-table .departure-col > div {
        background-color: #FFCD00;
        width: calc(100% - 3px);
        margin: 3px;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table > tbody > tr {
        border-bottom: 1px solid #64636399;
        line-height: 30px;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        align-items: start;
    }
}

@media (min-width: 1200px) {

    .div-papo-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }

    .title-papo-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }

    .papo-itinerario-table {
        width: 100%;
        text-align: center;
    }

    .papo-itinerario-table .day-col {
        line-height: 50px;
        width: 30%;
    }

    .papo-itinerario-table .day-col > div {
        background-color: #0885C7;
        width: calc(100% - 3px);
        margin: 3px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table .departure-col {
        line-height: 50px;
        width: 35%;
    }

    .papo-itinerario-table .departure-col > div {
        background-color: #FFCD00;
        width: calc(100% - 3px);
        margin: 3px;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table > tbody > tr {
        border-bottom: 1px solid #64636399;
        line-height: 30px;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        align-items: start;
    }
}

@media (min-width: 1400px) {

    .div-papo-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }

    .title-papo-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }

    .papo-itinerario-table {
        width: 100%;
        text-align: center;
    }

    .papo-itinerario-table .day-col {
        line-height: 50px;
        width: 30%;
    }

    .papo-itinerario-table .day-col > div {
        background-color: #0885C7;
        width: calc(100% - 3px);
        margin: 3px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table .departure-col {
        line-height: 50px;
        width: 35%;
    }

    .papo-itinerario-table .departure-col > div {
        background-color: #FFCD00;
        width: calc(100% - 3px);
        margin: 3px;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table > tbody > tr {
        border-bottom: 1px solid #64636399;
        line-height: 30px;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        align-items: start;
    }
}

@media (min-width: 1600px) {

    .div-papo-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }

    .title-papo-itinerario-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }

    .papo-itinerario-table {
        width: 100%;
        text-align: center;
    }

    .papo-itinerario-table .day-col {
        line-height: 50px;
        width: 30%;
    }

    .papo-itinerario-table .day-col > div {
        background-color: #0885C7;
        width: calc(100% - 3px);
        margin: 3px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table .departure-col {
        line-height: 50px;
        width: 35%;
    }

    .papo-itinerario-table .departure-col > div {
        background-color: #FFCD00;
        width: calc(100% - 3px);
        margin: 3px;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }

    .papo-itinerario-table > tbody > tr {
        border-bottom: 1px solid #64636399;
        line-height: 30px;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        align-items: start;
    }
}