.CMSIFerry {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-cmsi-ferry-s {
    width: 100%;
}

.img-cmsi-ferry-s {
    width: 100%;
    height: auto;
}

.div-cmsi-ferry-body {
    width: 100%;
    margin: 120px 0px;
}

.p-cmsi-ferry-s {
    color: #646363;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 400;
    margin-top: 10px;
    text-align: justify;
}

.ferry-m-img-t {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    height: auto;
    margin-bottom: 6px;
    width: 100%;
}

.ferry-m-img-b-l {
    border-bottom-left-radius: 23px;
    height: auto;
    padding-right: 6px;
    width: 100%;
}

.ferry-m-img-c-r {
    height: auto;
    margin-bottom: 6px;
    width: 100%;
}

.ferry-m-img-b-r {
    border-bottom-right-radius: 23px;
    height: auto;
    width: 100%;
}

.cmsi-ferry-table {
    width: 100%;
}

.cmsi-ferry-table > thead {
    background-color: #0885C7;
    text-align: left;
    color: white;
    font-size: 22px;
    font-family: 'Lato';
    font-weight: 500;
    border: 1px #0885C7 solid;
}

.cmsi-ferry-table > thead > tr > td {
    padding: 2px 8px;
}

.cmsi-ferry-table > tbody:before {
    content: "-";
    display: block;
    line-height: 10px;
    color: transparent;
}

.cmsi-ferry-table > tbody > tr > td {
    border: 1px #64636399 solid;
    color: #646363;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 400;
    padding: 10px;
}

@media (min-width: 576px) {

    .div-cmsi-ferry-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .p-cmsi-ferry-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
    
    .ferry-m-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .ferry-m-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 8px;
        width: 100%;
    }
    
    .ferry-m-img-c-r {
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .ferry-m-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
    
    .cmsi-ferry-table {
        width: 100%;
    }
    
    .cmsi-ferry-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 500;
        border: 1px #0885C7 solid;
    }
    
    .cmsi-ferry-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-ferry-table > tbody:before {
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    }
    
    .cmsi-ferry-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        padding: 10px;
    }
}

@media (min-width: 768px) {

    .div-cmsi-ferry-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .p-cmsi-ferry-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
    
    .ferry-m-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .ferry-m-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 10px;
        width: 100%;
    }
    
    .ferry-m-img-c-r {
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .ferry-m-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
    
    .cmsi-ferry-table {
        width: 100%;
    }
    
    .cmsi-ferry-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 500;
        border: 1px #0885C7 solid;
    }
    
    .cmsi-ferry-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-ferry-table > tbody:before {
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    }
    
    .cmsi-ferry-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        padding: 10px;
    }
}

@media (min-width: 992px) {

    .div-cmsi-ferry-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .p-cmsi-ferry-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
    
    .ferry-m-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .ferry-m-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 8px;
        width: 100%;
    }
    
    .ferry-m-img-c-r {
        height: auto;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .ferry-m-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
    
    .cmsi-ferry-table {
        width: 100%;
    }
    
    .cmsi-ferry-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 500;
        border: 1px #0885C7 solid;
    }
    
    .cmsi-ferry-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-ferry-table > tbody:before {
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    }
    
    .cmsi-ferry-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        padding: 10px;
    }
}

@media (min-width: 1200px) {

    .div-cmsi-ferry-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .p-cmsi-ferry-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
    
    .ferry-m-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .ferry-m-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 10px;
        width: 100%;
    }
    
    .ferry-m-img-c-r {
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .ferry-m-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
    
    .cmsi-ferry-table {
        width: 100%;
    }
    
    .cmsi-ferry-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 500;
        border: 1px #0885C7 solid;
    }
    
    .cmsi-ferry-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-ferry-table > tbody:before {
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    }
    
    .cmsi-ferry-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        padding: 10px;
    }
}

@media (min-width: 1400px) {

    .div-cmsi-ferry-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .p-cmsi-ferry-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
    
    .ferry-m-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .ferry-m-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 10px;
        width: 100%;
    }
    
    .ferry-m-img-c-r {
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .ferry-m-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
    
    .cmsi-ferry-table {
        width: 100%;
    }
    
    .cmsi-ferry-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 500;
        border: 1px #0885C7 solid;
    }
    
    .cmsi-ferry-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-ferry-table > tbody:before {
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    }
    
    .cmsi-ferry-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        padding: 10px;
    }
}

@media (min-width: 1600px) {

    .div-cmsi-ferry-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .p-cmsi-ferry-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 10px;
        text-align: justify;
    }
    
    .ferry-m-img-t {
        border-top-left-radius: 23px;
        border-top-right-radius: 23px;
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .ferry-m-img-b-l {
        border-bottom-left-radius: 23px;
        height: auto;
        padding-right: 10px;
        width: 100%;
    }
    
    .ferry-m-img-c-r {
        height: auto;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .ferry-m-img-b-r {
        border-bottom-right-radius: 23px;
        height: auto;
        width: 100%;
    }
    
    .cmsi-ferry-table {
        width: 100%;
    }
    
    .cmsi-ferry-table > thead {
        background-color: #0885C7;
        text-align: left;
        color: white;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 500;
        border: 1px #0885C7 solid;
    }
    
    .cmsi-ferry-table > thead > tr > td {
        padding: 2px 8px;
    }
    
    .cmsi-ferry-table > tbody:before {
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    }
    
    .cmsi-ferry-table > tbody > tr > td {
        border: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        padding: 10px;
    }
}