.Services {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-services-s {
    width: 100%;
}

.img-services-s {
    width: 100%;
    height: auto;
}

.row-services-s {
    text-align: left;
}
.div-services-body {
    width: 100%;
    margin: 120px 0px;
}

.title-services-s {
    color: #0885C7;
    font-size: 24px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 0 0 20px 0;
    text-align: left;
    line-height: 30px;
}

.subtitle-services-s {
    color: #646363;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 0;
    text-align: left;
}

.p-services-s {
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    margin: 0px 0px 30px 0px;
    text-align: justify;
}

.p-services-nave {
    color: #0885C7;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 400;
    text-align: left;
    margin-left: 30px;
}

.p-services-nave .bold {
    font-weight: 600;
}

.img-services-t-l {
    border-top-left-radius: 20px;
    height: 130px;
    width: auto;
    margin-right: 8px;
}

.img-services-t-r {
    border-top-right-radius: 20px;
    height: 130px;
    width: auto;
}

.img-services-b {
    border-bottom-right-radius: 20px;
    height: 132px;
    width: auto;
    margin-top: 7px;
}

.row-services-content {
    margin: 40px 0px 30px 0px;
}

@media (min-width: 576px) {
    .div-services-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-services-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
        text-align: left;
        line-height: 30px;
    }
    
    .subtitle-services-s {
        color: #646363;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-services-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 30px 0px;
        text-align: justify;
    }
    
    .p-services-nave {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
    }
    
    .p-services-nave .bold {
        font-weight: 600;
    }
    
    .img-services-t-l {
        border-top-left-radius: 20px;
        height: 240px;
        width: auto;
        margin-right: 8px;
    }
    
    .img-services-t-r {
        border-top-right-radius: 20px;
        height: 240px;
        width: auto;
    }
    
    .img-services-b {
        border-bottom-right-radius: 20px;
        height: 132px;
        width: auto;
        margin-top: 7px;
    }
    
    .row-services-content {
        margin: 40px 0px 30px 0px;
    }
}

@media (min-width: 768px) {
    .div-services-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-services-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
        text-align: left;
        line-height: 30px;
    }
    
    .subtitle-services-s {
        color: #646363;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-services-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 30px 0px;
        text-align: justify;
    }
    
    .p-services-nave {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
    }
    
    .p-services-nave .bold {
        font-weight: 600;
    }
    
    .img-services-t-l {
        border-top-left-radius: 20px;
        height: 280px;
        width: auto;
        margin-right: 8px;
    }
    
    .img-services-t-r {
        border-top-right-radius: 20px;
        height: 280px;
        width: auto;
    }
    
    .img-services-b {
        border-bottom-right-radius: 20px;
        height: 132px;
        width: auto;
        margin-top: 7px;
    }
    
    .row-services-content {
        margin: 40px 0px 30px 0px;
    }
}

@media (min-width: 992px) {
    .div-services-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-services-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
        text-align: left;
        line-height: 30px;
    }
    
    .subtitle-services-s {
        color: #646363;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-services-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 30px 0px;
        text-align: justify;
    }
    
    .p-services-nave {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
    }
    
    .p-services-nave .bold {
        font-weight: 600;
    }
    
    .img-services-t-l {
        border-top-left-radius: 20px;
        height: 180px;
        width: auto;
        margin-right: 8px;
    }
    
    .img-services-t-r {
        border-top-right-radius: 20px;
        height: 180px;
        width: auto;
    }
    
    .img-services-b {
        border-bottom-right-radius: 20px;
        height: 132px;
        width: auto;
        margin-top: 7px;
    }
    
    .row-services-content {
        margin: 40px 0px 30px 0px;
    }
}

@media (min-width: 1200px) {
    .div-services-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-services-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 40px 0;
        text-align: left;
        line-height: 45px;
    }
    
    .subtitle-services-s {
        color: #646363;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-services-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 0px 30px 0px;
        text-align: justify;
    }
    
    .p-services-nave {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
    }
    
    .p-services-nave .bold {
        font-weight: 600;
    }
    
    .img-services-t-l {
        border-top-left-radius: 20px;
        height: 220px;
        width: auto;
        margin-right: 8px;
    }
    
    .img-services-t-r {
        border-top-right-radius: 20px;
        height: 220px;
        width: auto;
    }
    
    .img-services-b {
        border-bottom-right-radius: 20px;
        height: 132px;
        width: auto;
        margin-top: 7px;
    }
    
    .row-services-content {
        margin: 40px 0px 30px 0px;
    }
}

@media (min-width: 1400px) {
    .div-services-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-services-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 40px 0;
        text-align: left;
        line-height: 45px;
    }
    
    .subtitle-services-s {
        color: #646363;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-services-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 40px 30px 0px;
        text-align: justify;
    }
    
    .p-services-nave {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
    }
    
    .p-services-nave .bold {
        font-weight: 600;
    }
    
    .img-services-t-l {
        border-top-left-radius: 20px;
        height: 273px;
        width: auto;
        margin-right: 8px;
    }
    
    .img-services-t-r {
        border-top-right-radius: 20px;
        height: 273px;
        width: auto;
    }
    
    .img-services-b {
        border-bottom-right-radius: 20px;
        height: 132px;
        width: auto;
        margin-top: 7px;
    }
    
    .row-services-content {
        margin: 40px 0px 30px 0px;
    }
}

@media (min-width: 1600px) {
    .div-services-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-services-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 40px 0;
        text-align: left;
        line-height: 45px;
    }
    
    .subtitle-services-s {
        color: #646363;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0;
        text-align: left;
    }
    
    .p-services-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin: 0px 40px 30px 0px;
        text-align: justify;
    }
    
    .p-services-nave {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 400;
        text-align: left;
        margin-left: 30px;
    }
    
    .p-services-nave .bold {
        font-weight: 600;
    }
    
    .img-services-t-l {
        border-top-left-radius: 20px;
        height: 273px;
        width: auto;
        margin-right: 8px;
    }
    
    .img-services-t-r {
        border-top-right-radius: 20px;
        height: 273px;
        width: auto;
    }
    
    .img-services-b {
        border-bottom-right-radius: 20px;
        height: 132px;
        width: auto;
        margin-top: 7px;
    }
    
    .row-services-content {
        margin: 40px 0px 30px 0px;
    }
}