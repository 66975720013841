
.div-loading-admin {
    z-index: 1000;
    background-color: #FFFFFF99;
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.img-loading-admin {
    z-index: 1001;
    width: 100px;
    height: 100px;
    position: fixed;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
}