
.div-notificaciones {
    padding: 10px 10px 15px 10px;
    position: fixed;
    bottom: 0%;
    width: 100%;
    background-color: #D9D9D9;
    z-index: 100;
}

.show-notf {
    visibility: visible !important;
}

.hide-notf {
    visibility: hidden !important;
}

.alert-icon-notificaciones {
    width: 26px;
    height: auto;
    margin: 5px 5px -5px 5px;
}

.alert-text-notificaciones {
    color: #0885C7;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 400;
    margin-bottom: 0;
}

.alert-close-button {
    color: #0885C7;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    position: absolute;
    left: 90%;
    top: 20%;
}

.alert-close-button:hover {
    opacity: 0.8;
    cursor: pointer;
}

@media (min-width: 576px) {
    .div-notificaciones {
        padding: 10px 10px 15px 10px;
        position: fixed;
        bottom: 0%;
        width: 100%;
        background-color: #D9D9D9;
        z-index: 100;
    }
    
    .show-notf {
        visibility: visible !important;
    }
    
    .hide-notf {
        visibility: hidden !important;
    }
    
    .alert-icon-notificaciones {
        width: 26px;
        height: auto;
        margin: 5px 5px -5px 5px;
    }
    
    .alert-text-notificaciones {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 0;
    }
    
    .alert-close-button {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        position: absolute;
        left: 90%;
        top: 20%;
    }
    
    .alert-close-button:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

@media (min-width: 768px) {
    .div-notificaciones {
        padding: 10px 10px 15px 10px;
        position: fixed;
        bottom: 0%;
        width: 100%;
        background-color: #D9D9D9;
        z-index: 100;
    }
    
    .show-notf {
        visibility: visible !important;
    }
    
    .hide-notf {
        visibility: hidden !important;
    }
    
    .alert-icon-notificaciones {
        width: 22px;
        height: auto;
        margin: 5px 5px -5px 5px;
    }
    
    .alert-text-notificaciones {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 0;
    }
    
    .alert-close-button {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        position: absolute;
        left: 90%;
        top: 20%;
    }
    
    .alert-close-button:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

@media (min-width: 992px) {
    .div-notificaciones {
        padding: 10px 10px 15px 10px;
        position: fixed;
        bottom: 0%;
        width: 100%;
        background-color: #D9D9D9;
        z-index: 100;
    }
    
    .show-notf {
        visibility: visible !important;
    }
    
    .hide-notf {
        visibility: hidden !important;
    }
    
    .alert-icon-notificaciones {
        width: 26px;
        height: auto;
        margin: 5px 5px -5px 5px;
    }
    
    .alert-text-notificaciones {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 0;
    }
    
    .alert-close-button {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        position: absolute;
        left: 90%;
        top: 20%;
    }
    
    .alert-close-button:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

@media (min-width: 1200px) {
    .div-notificaciones {
        padding: 10px 10px 15px 10px;
        position: fixed;
        bottom: 0%;
        width: 100%;
        background-color: #D9D9D9;
        z-index: 100;
    }
    
    .show-notf {
        visibility: visible !important;
    }
    
    .hide-notf {
        visibility: hidden !important;
    }
    
    .alert-icon-notificaciones {
        width: 26px;
        height: auto;
        margin: 5px 5px -5px 5px;
    }
    
    .alert-text-notificaciones {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 0;
    }
    
    .alert-close-button {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        position: absolute;
        left: 90%;
        top: 20%;
    }
    
    .alert-close-button:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

@media (min-width: 1400px) {
    .div-notificaciones {
        padding: 10px 10px 20px 10px;
        position: fixed;
        bottom: 0%;
        width: 100%;
        background-color: #D9D9D9;
        z-index: 100;
    }
    
    .show-notf {
        visibility: visible !important;
    }
    
    .hide-notf {
        visibility: hidden !important;
    }
    
    .alert-icon-notificaciones {
        width: 30px;
        height: auto;
        margin: 5px 5px -5px 5px;
    }
    
    .alert-text-notificaciones {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 0;
    }
    
    .alert-close-button {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        position: absolute;
        left: 90%;
        top: 20%;
    }
    
    .alert-close-button:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

@media (min-width: 1600px) {
    .div-notificaciones {
        padding: 20px 10px 30px 10px;
        position: fixed;
        bottom: 0%;
        width: 100%;
        background-color: #D9D9D9;
        z-index: 100;
    }
    
    .show-notf {
        visibility: visible !important;
    }
    
    .hide-notf {
        visibility: hidden !important;
    }
    
    .alert-icon-notificaciones {
        width: 32px;
        height: auto;
        margin: 5px 5px -5px 5px;
    }
    
    .alert-text-notificaciones {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 0;
    }
    
    .alert-close-button {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        position: absolute;
        left: 90%;
        top: 20%;
    }
    
    .alert-close-button:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}