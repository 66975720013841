.QuienesSomos {
    text-align: center;
}

.div-quienes-somos-s {
    width: 100%;
}

.img-quienes-somos-t-s {
    width: 100%;
    height: auto;
}

.img-quienes-somos-s {
    width: 100%;
    height: auto;
    margin-bottom: 4px;
    padding-right: 4px;
}

.div-quienes-somos-body {
    width: 100%;
    margin: 80px 0px 80px 0px;
}

.title-quienes-somos-s {
    color: #0885C7;
    font-size: 32px;
    font-family: 'Lato';
    font-weight: 600;
    margin: 0 0 20px 0;
}

.p-quienes-somos-s {
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 26px;
}

.subp-quienes-somos-s {
    color: #646363;
    font-size: 30px;
    font-family: 'Lato';
    font-weight: 700;
    margin: 20px 0 0 0;
}

.col-align-center {
    text-align: center;
    align-content: center;
    display: grid;
}

.row-content-qss-s {
    margin-bottom: 40px;
    text-align: justify;
}

.col-valores-qs {
    margin-bottom: 60px;
}

.btn-registro {
    background-color: #facd01;
    color: white;
    text-decoration: none;
    border: 0;
    margin: 10px;
    border-radius: 5%;
}

.a-registro {
    color: black;
    text-decoration: none;
    padding: 30px;
}

.btn-inicia {
    background-color: #0885C7;
    color: white;
    text-decoration: none;
    border: 0;
    margin: 10px;
    border-radius: 5%;
}

.a-inicia {
    color: white;
    text-decoration: none;
    padding: 30px;
}

@media (min-width: 576px) {

    .img-quienes-somos-s {
        width: 100%;
        height: auto;
        margin-bottom: 4px;
        padding-right: 4px;
    }
    
    .div-quienes-somos-body {
        width: 100%;
        margin: 100px 0px 100px 0px;
    }
    
    .title-quienes-somos-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
    }
    
    .p-quienes-somos-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 26px;
    }
    
    .subp-quienes-somos-s {
        color: #646363;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-qss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .col-valores-qs {
        margin-bottom: 60px;
    }
}

@media (min-width: 768px) {

    .img-quienes-somos-s {
        width: 100%;
        height: auto;
        margin-bottom: 6px;
        padding-right: 6px;
    }
    
    .div-quienes-somos-body {
        width: 100%;
        margin: 100px 0px 100px 0px;
    }
    
    .title-quienes-somos-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
    }
    
    .p-quienes-somos-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 26px;
    }
    
    .subp-quienes-somos-s {
        color: #646363;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-qss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .col-valores-qs {
        margin-bottom: 60px;
    }
}

@media (min-width: 992px) {

    .img-quienes-somos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
        padding-right: 8px;
    }
    
    .div-quienes-somos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-quienes-somos-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
    }
    
    .p-quienes-somos-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 26px;
    }
    
    .subp-quienes-somos-s {
        color: #646363;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-qss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .col-valores-qs {
        margin-bottom: 60px;
    }
}

@media (min-width: 1200px) {

    .img-quienes-somos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
        padding-right: 8px;
    }
    
    .div-quienes-somos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-quienes-somos-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
    }
    
    .p-quienes-somos-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 26px;
    }
    
    .subp-quienes-somos-s {
        color: #646363;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-qss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .col-valores-qs {
        margin-bottom: 60px;
    }
}

@media (min-width: 1400px) {

    .img-quienes-somos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
        padding-right: 8px;
    }
    
    .div-quienes-somos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-quienes-somos-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
    }
    
    .p-quienes-somos-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 26px;
    }
    
    .subp-quienes-somos-s {
        color: #646363;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-qss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .col-valores-qs {
        margin-bottom: 60px;
    }
}

@media (min-width: 1600px) {

    .img-quienes-somos-s {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
        padding-right: 8px;
    }
    
    .div-quienes-somos-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-quienes-somos-s {
        color: #0885C7;
        font-size: 32px;
        font-family: 'Lato';
        font-weight: 600;
        margin: 0 0 20px 0;
    }
    
    .p-quienes-somos-s {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 26px;
    }
    
    .subp-quienes-somos-s {
        color: #646363;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 700;
        margin: 20px 0 0 0;
    }
    
    .col-align-center {
        text-align: center;
        align-content: center;
        display: grid;
    }
    
    .row-content-qss-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .col-valores-qs {
        margin-bottom: 60px;
    }
}