.PYPNItinerario {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-pypn-itinerario-s {
    width: 100%;
}

.img-pypn-itinerario-s {
    width: 100%;
    height: auto;
}

.div-pypn-itinerario-body {
    width: 100%;
    margin: 20px 0px;
}

.title-pypn-itinerario-s {
    color: #0885C7;
    font-size: 24px;
    font-family: 'Lato';
    font-weight: 600;
    text-align: left;
}

.pypn-itinerario-table {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.pypn-itinerario-table > thead::before {   
    content: "-";
    display: block;
    line-height: 10px;
    color: transparent;
} 

.pypn-itinerario-table-no-top {
    border: none !important;
}

.pypn-itinerario-table > tbody > tr {
    border-top: 1px #64636399 solid;
    color: #646363;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 50px;
}

.pypn-itinerario-table .row-col-titulo {
    color: #434343;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 700;
    line-height: 30px;
    background-color: #FFCD00;
    border: none;
    padding: 0;
} 

.pypn-itinerario-table .row-col-subtitulo {
    color: white;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 30px;
    background-color: #0885C7;
    border: none;
    padding: 0;
} 

.pypn-itinerario-table .row-col-via {
    color: white;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 30px;
    background-color: #646363;
    border: none;
} 

.div-pypn-itinerario-padd {
    width: 100%;
    padding: 10px 20px;
}

@media (min-width: 576px) {
    
}

@media (min-width: 768px) {
    
    .div-pypn-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pypn-itinerario-s {
        color: #0885C7;
        font-size: 28px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .pypn-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
    }
    
    .pypn-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .pypn-itinerario-table-no-top {
        border: none !important;
    }
    
    .pypn-itinerario-table > tbody > tr {
        border-top: 1px #64636399 solid;
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pypn-itinerario-table .row-col-titulo {
        color: #434343;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 700;
        line-height: 40px;
        background-color: #FFCD00;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #0885C7;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-via {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #646363;
        border: none;
        margin: 2px;
    } 
    
    .div-pypn-itinerario-padd {
        width: 100%;
        padding: 10px 20px;
    }
}

@media (min-width: 992px) {
    
    .div-pypn-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pypn-itinerario-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .pypn-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
    }
    
    .pypn-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .pypn-itinerario-table-no-top {
        border: none !important;
    }
    
    .pypn-itinerario-table > tbody > tr {
        border-top: 1px #64636399 solid;
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pypn-itinerario-table .row-col-titulo {
        color: #434343;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 700;
        line-height: 40px;
        background-color: #FFCD00;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #0885C7;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-via {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #646363;
        border: none;
        margin: 2px;
    } 
    
    .div-pypn-itinerario-padd {
        width: 100%;
        padding: 10px 20px;
    }
}

@media (min-width: 1200px) {
    
    .div-pypn-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pypn-itinerario-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .pypn-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
    }
    
    .pypn-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .pypn-itinerario-table-no-top {
        border: none !important;
    }
    
    .pypn-itinerario-table > tbody > tr {
        border-top: 1px #64636399 solid;
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pypn-itinerario-table .row-col-titulo {
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 700;
        line-height: 40px;
        background-color: #FFCD00;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #0885C7;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-via {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #646363;
        border: none;
        margin: 2px;
    } 
    
    .div-pypn-itinerario-padd {
        width: 100%;
        padding: 10px 20px;
    }
}

@media (min-width: 1400px) {
    
    .div-pypn-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pypn-itinerario-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .pypn-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
    }
    
    .pypn-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .pypn-itinerario-table-no-top {
        border: none !important;
    }
    
    .pypn-itinerario-table > tbody > tr {
        border-top: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pypn-itinerario-table .row-col-titulo {
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 700;
        line-height: 40px;
        background-color: #FFCD00;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #0885C7;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-via {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #646363;
        border: none;
        margin: 2px;
    } 
    
    .div-pypn-itinerario-padd {
        width: 100%;
        padding: 10px 20px;
    }
}

@media (min-width: 1600px) {
    
    .div-pypn-itinerario-body {
        width: 100%;
        margin: 120px 0px;
    }
    
    .title-pypn-itinerario-s {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 48px;
        text-align: left;
    }
    
    .pypn-itinerario-table {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
    }
    
    .pypn-itinerario-table > thead::before {   
        content: "-";
        display: block;
        line-height: 10px;
        color: transparent;
    } 
    
    .pypn-itinerario-table-no-top {
        border: none !important;
    }
    
    .pypn-itinerario-table > tbody > tr {
        border-top: 1px #64636399 solid;
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 50px;
    }
    
    .pypn-itinerario-table .row-col-titulo {
        color: #434343;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 700;
        line-height: 40px;
        background-color: #FFCD00;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-subtitulo {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #0885C7;
        border: none;
        margin: 2px;
    } 
    
    .pypn-itinerario-table .row-col-via {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 40px;
        background-color: #646363;
        border: none;
        margin: 2px;
    } 
    
    .div-pypn-itinerario-padd {
        width: 100%;
        padding: 10px 20px;
    }
}