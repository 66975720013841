.OficinasTerminales {
    text-align: center;
}

.div-oficinas-terminales-s {
    width: 100%;
}

.img-oficinas-terminales-s {
    width: 100%;
    height: auto;
}

.div-oficinas-terminales-body {
    width: 100%;
    margin: 100px 0px 150px 0px;
}

.title-oficinas-terminales-s {
    color: #0885C7;
    font-size: 34px;
    font-family: 'Lato';
    font-weight: 600;
}

.subtitle-oficinas-terminales-s {
    color: #0885C7;
    font-size: 24px;
    font-family: 'Lato';
    font-weight: 600;
    text-align: left;
}

.li-title-oficinas-terminales {
    color: black;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    margin-bottom: 30px;
}

.table-oficinas-terminales {
    width: 95%;
    margin-bottom: 40px;
}

.table-oficinas-terminales > thead > tr > td {
    background-color: #0885C7;
    padding: 10px 10px;
    border-left: 5px white solid;
    color: white;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 700;
}

.table-oficinas-terminales > thead > tr > td:nth-child(even) {
    background-color: #FFCD00;
    padding: 10px 10px;
    border-left: 5px white solid;
    color: #434343;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 600;
}

.table-oficinas-terminales > tbody > tr > td {
    padding: 10px 10px;
    color: #434343;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 600;
    border-bottom: 1px #434343 solid;
}

.table-oficinas-terminales > tbody > tr > td:nth-child(even) {
    padding: 10px 10px;
    font-weight: 400;
}

@media (min-width: 576px) {
    .div-oficinas-terminales-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }

    .title-oficinas-terminales-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .subtitle-oficinas-terminales-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: left;
    }

    .li-title-oficinas-terminales {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 30px;
    }

    .table-oficinas-terminales {
        width: 95%;
        margin-bottom: 40px;
    }

    .table-oficinas-terminales > thead > tr > td {
        background-color: #0885C7;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 700;
    }

    .table-oficinas-terminales > thead > tr > td:nth-child(even) {
        background-color: #FFCD00;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: #434343;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .table-oficinas-terminales > tbody > tr > td {
        padding: 10px 10px;
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        border-bottom: 1px #434343 solid;
    }

    .table-oficinas-terminales > tbody > tr > td:nth-child(even) {
        padding: 10px 10px;
        font-weight: 400;
    }
}

@media (min-width: 768px) {
    .div-oficinas-terminales-body {
        width: 100%;
        margin: 100px 0px 120px 0px;
    }

    .title-oficinas-terminales-s {
        color: #0885C7;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .subtitle-oficinas-terminales-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: left;
    }

    .li-title-oficinas-terminales {
        color: black;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 28px;
    }

    .table-oficinas-terminales {
        width: 95%;
        margin-bottom: 40px;
    }

    .table-oficinas-terminales > thead > tr > td {
        background-color: #0885C7;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: white;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 700;
    }

    .table-oficinas-terminales > thead > tr > td:nth-child(even) {
        background-color: #FFCD00;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .table-oficinas-terminales > tbody > tr > td {
        padding: 10px 10px;
        color: #434343;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        border-bottom: 1px #434343 solid;
    }

    .table-oficinas-terminales > tbody > tr > td:nth-child(even) {
        padding: 10px 10px;
        font-weight: 400;
    }
}

@media (min-width: 992px) {
    .div-oficinas-terminales-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }

    .title-oficinas-terminales-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .subtitle-oficinas-terminales-s {
        color: #0885C7;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: left;
    }

    .li-title-oficinas-terminales {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 30px;
    }

    .table-oficinas-terminales {
        width: 95%;
        margin-bottom: 40px;
    }

    .table-oficinas-terminales > thead > tr > td {
        background-color: #0885C7;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 700;
    }

    .table-oficinas-terminales > thead > tr > td:nth-child(even) {
        background-color: #FFCD00;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: #434343;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .table-oficinas-terminales > tbody > tr > td {
        padding: 10px 10px;
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        border-bottom: 1px #434343 solid;
    }

    .table-oficinas-terminales > tbody > tr > td:nth-child(even) {
        padding: 10px 10px;
        font-weight: 400;
    }
}

@media (min-width: 1200px) {
    .div-oficinas-terminales-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }

    .title-oficinas-terminales-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .subtitle-oficinas-terminales-s {
        color: #0885C7;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: left;
    }

    .li-title-oficinas-terminales {
        color: black;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 33px;
    }

    .table-oficinas-terminales {
        width: 95%;
        margin-bottom: 40px;
    }

    .table-oficinas-terminales > thead > tr > td {
        background-color: #0885C7;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 700;
    }

    .table-oficinas-terminales > thead > tr > td:nth-child(even) {
        background-color: #FFCD00;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .table-oficinas-terminales > tbody > tr > td {
        padding: 10px 10px;
        color: #434343;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        border-bottom: 1px #434343 solid;
    }

    .table-oficinas-terminales > tbody > tr > td:nth-child(even) {
        padding: 10px 10px;
        font-weight: 400;
    }
}

@media (min-width: 1400px) {
    .div-oficinas-terminales-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }

    .title-oficinas-terminales-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .subtitle-oficinas-terminales-s {
        color: #0885C7;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: left;
    }

    .li-title-oficinas-terminales {
        color: black;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 33px;
    }

    .table-oficinas-terminales {
        width: 95%;
        margin-bottom: 40px;
    }

    .table-oficinas-terminales > thead > tr > td {
        background-color: #0885C7;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 700;
    }

    .table-oficinas-terminales > thead > tr > td:nth-child(even) {
        background-color: #FFCD00;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .table-oficinas-terminales > tbody > tr > td {
        padding: 10px 10px;
        color: #434343;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        border-bottom: 1px #434343 solid;
    }

    .table-oficinas-terminales > tbody > tr > td:nth-child(even) {
        padding: 10px 10px;
        font-weight: 400;
    }
}

@media (min-width: 1600px) {
    .div-oficinas-terminales-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }

    .title-oficinas-terminales-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .subtitle-oficinas-terminales-s {
        color: #0885C7;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: left;
    }

    .li-title-oficinas-terminales {
        color: black;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 33px;
    }

    .table-oficinas-terminales {
        width: 95%;
        margin-bottom: 40px;
    }

    .table-oficinas-terminales > thead > tr > td {
        background-color: #0885C7;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 700;
    }

    .table-oficinas-terminales > thead > tr > td:nth-child(even) {
        background-color: #FFCD00;
        padding: 10px 10px;
        border-left: 5px white solid;
        color: #434343;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 600;
    }

    .table-oficinas-terminales > tbody > tr > td {
        padding: 10px 10px;
        color: #434343;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        border-bottom: 1px #434343 solid;
    }

    .table-oficinas-terminales > tbody > tr > td:nth-child(even) {
        padding: 10px 10px;
        font-weight: 400;
    }
}