.form-check-login-admin {
    
    color: #0885C7;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-check-login-admin > input {
    border: 2px #0885C7 solid;
}