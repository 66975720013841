.Itinerarios {
    text-align: center;
}

.slider-top-shadow-off {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.div-itinerarios-s {
    width: 100%;
}

.img-itinerarios-s {
    width: 100%;
    height: auto;
}

.div-itinerarios-body {
    width: 100%;
    margin: 100px 0px 150px 0px;
}

.row-itinerarios-s {
    text-align: left;
}

.title-itinerario-s {
    color: #00276C;
    font-size: 28px;
    font-family: 'Lato';
    font-weight: 900;
    margin: 0;
}

.backtitle-itinerario-s {
    background-color: #69AFFF;
    padding: 6px;
    margin-bottom: 6px;
    text-align: center;
}

.subtitle-itinerario-s {
    color: white;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 900;
    margin: 0;
}

.backsubtitle-itinerario-s {
    background-color: #00276C;
    padding: 12px;
    margin-bottom: 6px;
    text-align: center;
}

.margin-itinerario-l {
    margin: 0 0 0 3px !important;
}

.margin-itinerario-r {
    margin: 0 3px 0 0 !important;
}

.back-horario-s {
    background-color: #D9D9D9;
    padding: 2px;
    margin-bottom: 3px;
    text-align: center;
    justify-content: center;
}

.p-itinerario-s {
    color: #00276C;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 900;
    margin-top: 10px;
}

.row-itinerario-s {
    color: black;
    border-top: 1px #666666 solid;
}

.col-itinerario-l {
    color: black;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 900;
    text-align: left;
    margin-top: 5px;
}

.col-itinerario-r {
    color: black;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 900;
    text-align: right;
    margin-top: 5px;
}

.block-itinerario-s {
    margin-bottom: 50px;
}

.ftitle-itinerario-s {
    color: #00276C;
    font-size: 36px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    margin-bottom: 60px;
}

.ftitle-itinerario-s-bold {
    font-weight: 700;
}