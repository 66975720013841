.TrabajaNosotros {
    text-align: center;
}

.div-trabaja-nosotros-s {
    width: 100%;
}

.img-trabaja-nosotros-s {
    width: 100%;
    height: auto;
}

.div-trabaja-nosotros-body {
    width: 100%;
    margin: 100px 0px 150px 0px;
}

.title-trabaja-nosotros-s {
    color: #0885C7;
    font-size: 34px;
    font-family: 'Lato';
    font-weight: 600;
}

.row-content-tcn-s {
    margin-bottom: 0px;
    text-align: justify;
}

.label-trabaja-nosotros {
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    margin-top: 20px;
}

.form-field-trabaja-nosotros {
    border: 1px #178DCB99 solid;
}

.form-field-trabaja-nosotros-invalid {
    border: 1px red solid;
}

.form-text-trabaja-nosotros-invalid {
    color: red;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-field-trabaja-nosotros::placeholder {
    color: #64636399;
}

.form-field-trabaja-nosotros-area {
    border: 1px #178DCB99 solid;
    height: 140px;
}

.form-field-trabaja-nosotros-area::placeholder {
    color: #64636399;
}

.btn-enviar-trabaja-nosotros {
    width: 100%;
    background-color: #178DCB; 
    color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    padding: 10px;
    margin-top: 40px;
}

.labeldetalle-trabaja-nosotros {
    color: rgba(100, 99, 99, 0.60);
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
}

@media (min-width: 576px) {

    .div-trabaja-nosotros-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-trabaja-nosotros-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-tcn-s {
        margin-bottom: 0px;
        text-align: justify;
    }
    
    .label-trabaja-nosotros {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 20px;
    }
    
    .form-field-trabaja-nosotros {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-trabaja-nosotros-invalid {
        border: 1px red solid;
    }
    
    .form-text-trabaja-nosotros-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-trabaja-nosotros::placeholder {
        color: #64636399;
    }
    
    .form-field-trabaja-nosotros-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-trabaja-nosotros-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-trabaja-nosotros {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 40px;
    }
    
    .labeldetalle-trabaja-nosotros {
        color: rgba(100, 99, 99, 0.60);
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
}

@media (min-width: 768px) {

    .div-trabaja-nosotros-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-trabaja-nosotros-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-tcn-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-trabaja-nosotros {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-trabaja-nosotros {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-trabaja-nosotros-invalid {
        border: 1px red solid;
    }
    
    .form-text-trabaja-nosotros-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-trabaja-nosotros::placeholder {
        color: #64636399;
    }
    
    .form-field-trabaja-nosotros-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-trabaja-nosotros-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-trabaja-nosotros {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
    
    .labeldetalle-trabaja-nosotros {
        color: rgba(100, 99, 99, 0.60);
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
}

@media (min-width: 992px) {

    .div-trabaja-nosotros-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-trabaja-nosotros-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-tcn-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-trabaja-nosotros {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-trabaja-nosotros {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-trabaja-nosotros-invalid {
        border: 1px red solid;
    }
    
    .form-text-trabaja-nosotros-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-trabaja-nosotros::placeholder {
        color: #64636399;
    }
    
    .form-field-trabaja-nosotros-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-trabaja-nosotros-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-trabaja-nosotros {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
    
    .labeldetalle-trabaja-nosotros {
        color: rgba(100, 99, 99, 0.60);
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
}

@media (min-width: 1200px) {

    .div-trabaja-nosotros-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-trabaja-nosotros-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-tcn-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-trabaja-nosotros {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-trabaja-nosotros {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-trabaja-nosotros-invalid {
        border: 1px red solid;
    }
    
    .form-text-trabaja-nosotros-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-trabaja-nosotros::placeholder {
        color: #64636399;
    }
    
    .form-field-trabaja-nosotros-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-trabaja-nosotros-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-trabaja-nosotros {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
    
    .labeldetalle-trabaja-nosotros {
        color: rgba(100, 99, 99, 0.60);
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
}

@media (min-width: 1400px) {

    .div-trabaja-nosotros-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-trabaja-nosotros-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-tcn-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-trabaja-nosotros {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-trabaja-nosotros {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-trabaja-nosotros-invalid {
        border: 1px red solid;
    }
    
    .form-text-trabaja-nosotros-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-trabaja-nosotros::placeholder {
        color: #64636399;
    }
    
    .form-field-trabaja-nosotros-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-trabaja-nosotros-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-trabaja-nosotros {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
    
    .labeldetalle-trabaja-nosotros {
        color: rgba(100, 99, 99, 0.60);
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
}

@media (min-width: 1600px) {

    .div-trabaja-nosotros-body {
        width: 100%;
        margin: 100px 0px 150px 0px;
    }
    
    .title-trabaja-nosotros-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .row-content-tcn-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .label-trabaja-nosotros {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-top: 0px;
    }
    
    .form-field-trabaja-nosotros {
        border: 1px #178DCB99 solid;
    }
    
    .form-field-trabaja-nosotros-invalid {
        border: 1px red solid;
    }
    
    .form-text-trabaja-nosotros-invalid {
        color: red;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-trabaja-nosotros::placeholder {
        color: #64636399;
    }
    
    .form-field-trabaja-nosotros-area {
        border: 1px #178DCB99 solid;
        height: 140px;
    }
    
    .form-field-trabaja-nosotros-area::placeholder {
        color: #64636399;
    }
    
    .btn-enviar-trabaja-nosotros {
        width: 100%;
        background-color: #178DCB; 
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        padding: 10px;
        margin-top: 0px;
    }
    
    .labeldetalle-trabaja-nosotros {
        color: rgba(100, 99, 99, 0.60);
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
}