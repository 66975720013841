.sticky-bar {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0) !important;
}

.sticky-bar-text {
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
    background-color: rgba(0, 0, 0, 0) !important;
    text-align: left;
}

.sticky-bar-text .nav-link {
    color: #646363;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
    background-color: rgba(0, 0, 0, 0) !important;
    text-align: left;
}

.sticky-bar-text .nav-link:hover {
    font-weight: 600;
}

.sticky-bar-text .dropdown-menu {
    background-color: #FFFFFF;
    border: none;
    border-radius: 0px;
    padding: 0;
    margin-left: 15px;
}
.sticky-bar-text .dropdown-item {
    color: #646363;
    padding: 15px
}

.sticky-bar-text .dropdown-item:hover {
    background-color: #0885C7 !important;
    color: #FFFFFF;
}

.sticky-bar-text .nav-link {
    padding: 8px 30px !important;
}

.logo {
    width: 170px;
    height: auto;
}

.language-icon {
    width: 40px !important;
    height: auto;
}

.arrow-language {
    width: 5px;
    height: auto;
}

.button-language {
    opacity: 1;
}

.button-language:hover {
    opacity: 0.8;
    cursor: pointer;
}

.select-language-div {
    position: absolute;
    margin-top: 10px;
    width: 120px;
    padding: 20px 20px 0 0;
    background-color: #FFFFFF;
    border-radius: 6px;
}

.select-language {
    opacity: 1;
    margin-bottom: 20px;
}

.select-language-row:hover {
    opacity: 0.8;
    cursor: pointer;
}

.img-check-lang {
    width: 22px;
    margin-left: 8px;
    margin-top: 3px;
    height: auto;
}

.user-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.user-icon{
    padding-right: 5px;
}

.user-login-div {
    position: absolute;
    margin-top: 10px;
    width: 340px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-left: -300px;
}

.user-login-div::before {
    content: url(./src/top-arrow.svg);
    margin-top: -30px;
    display: flex;
    margin-left: calc(100% - 22px);
}

.img-btn-login-icon {
    width: 20px;
    height: auto;
    margin-right: 5px;
    margin-top: -4px;
}

.user-login-title {
    color: #0885C7;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 700;
    margin-bottom: 0;
}

.user-login-acceso-alert {
    color: #D7282A;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-field-login {
    padding-left: 50px;
    background-color: #0885C7 !important;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 37px;
    border: 1px #0885C7 solid;
    color: #FFFFFF;
    z-index: 100;
}

.form-field-login::placeholder {
    color: rgba(255, 255, 255, 0.60);
}

.form-field-login:focus {
    color: #FFFFFF;
}

.form-field-icon {
    display: flex;
    margin-top: -37px;
    background-color: #FFFFFF;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.show-password-icon {
    display: flex;
    width: 22px;
    height: auto;
    margin-top: -29px;
    margin-left: calc(100% - 32px);
}

.show-password-icon:hover {
    cursor: pointer;
}

.form-field-row {
    margin-bottom: 10px;
}

.form-check-login {
    color: #0885C7;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-check-login .form-check-label {
    margin-left: -30px;
}

.form-check-login > input {
    border: 2px #0885C7 solid;
}

.form-check-login > input:checked {
    border: 2px #0885C7 solid;
    background-color: #0885C7;
}

.form-links-login {
    color: #0885C7;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    margin-bottom: 6px;
}

.form-links-login:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
}

.form-button-login {
    color: white;
    background-color: #0885C7;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 800;
    margin-top: 25px !important;
    width: 100%;
}

.form-button-login:hover {
    background-color: #0675b0;
}
    
.navbar-toggler {
    border: none !important;
}
    
.navbar-toggler:focus {
    box-shadow: none !important;
}

.navbar-toggler-icon {
    background-image: url(./src/menu-icon-xs.svg) !important;
}

.navbar-collapse {
    background-color: #FFFFFF;
    height: 1000px;
    width: 100%;
    margin: -100px 0 0 0;
    padding: 80px 0 0 0;
    --bs-gutter-x: 0;
    z-index: 100;
}

.logo-xs {
    width: 180px;
    height: auto;
    margin-bottom: 40px;
    margin-left: calc(190px - 100%);
}

.logo-login-xs {
    width: 180px;
    height: auto;
    margin-top: 140px;
}

.copyright-login-xs {
    color: #646363;
    font-size: 7px;
    font-family: 'Lato';
    font-weight: 400;
}

.close-button-xs {
    margin-left: calc(100% - 80px);
}

.user-login-div-xs {
    width: 100%;
    height: 1000px;
    background-color: #FFFFFF;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    padding: 50px;
}

.close-button-user-xs {
    margin-top: -20px;
    margin-bottom: 20px;
    margin-left: calc(100% - 10px);
}

.text-login {
    color:white;
    
}

.nav-link-acceso{
    margin-left: 50px;
}

@media (min-width: 576px) {
    
}


@media (min-width: 768px) {
    .sticky-bar {
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text {
        color: white;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link {
        color: white;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link:hover {
        font-weight: 400;
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"] {
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"]:before {
        content: "__";
        left: 17%;
        position: absolute;
        margin-top: -5px;
        font-size: 35px;
        width: 5px;
        color: #FFFFFF;
    }

    
    .sticky-bar-text .dropdown-menu {
        background-color: #FFFFFF;
        border: none;
        border-radius: 0px;
        padding: 0;
        margin-left: 15px;
    }
    .sticky-bar-text .dropdown-item {
        color: #646363;
        padding: 10px
    }
    
    .sticky-bar-text .dropdown-item:hover {
        background-color: #0885C7 !important;
        color: #FFFFFF;
    }
    
    .sticky-bar-text .nav-link {
        padding: 8px 10px !important;
    }

    .dropdown-toggle::after {
        content: url(./src/arrow-link.svg) !important;
        border-top: 0 !important;
        vertical-align: 0px;
    }

    .sticky-bar-text-home .nav-link {
        text-align: left;
        padding: 4px 10px 4px 10px !important;
    }
    
    .logo {
        width: 80px;
        height: auto;
    }
    
    .language-icon {
        width: 35px;
        height: auto;
    }
    
    .arrow-language {
        width: 12px;
        height: auto;
    }
    
    .button-language {
        opacity: 1;
    }
    
    .button-language:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .select-language-div {
        position: absolute;
        margin-top: 10px;
        margin-left: -60px;
        width: 100px;
        padding: 10px 10px 0 0;
        background-color: #FFFFFF;
        border-radius: 6px;
    }
    
    .select-language {
        opacity: 1;
        margin-bottom: 20px;
    }
    
    .select-language-row:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-check-lang {
        width: 22px;
        margin-left: 8px;
        margin-top: 3px;
        height: auto;
    }
    
    .user-icon:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .user-login-div {
        position: absolute;
        width: 340px;
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 20px;
        margin-left: -300px;
    }
    
    .user-login-div::before {
        content: url(./src/top-arrow.svg);
        margin-top: -30px;
        display: flex;
        margin-left: calc(100% - 22px);
    }
    
    .img-btn-login-icon {
        width: 20px;
        height: auto;
        margin-right: 5px;
        margin-top: -4px;
    }
    
    .user-login-title {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 700;
        margin-bottom: 0;
    }
    
    .user-login-acceso-alert {
        color: #D7282A;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-login {
        padding-left: 50px;
        background-color: #0885C7 !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 37px;
        border: 1px #0885C7 solid;
        color: #FFFFFF;
    }
    
    .form-field-login::placeholder {
        color: rgba(255, 255, 255, 0.60);
    }
    
    .form-field-login:focus {
        color: #FFFFFF;
    }
    
    .form-field-icon {
        display: flex;
        margin-top: -37px;
        background-color: #FFFFFF;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .form-field-row {
        margin-bottom: 10px;
    }
    
    .form-check-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-check-login .form-check-label {
        margin-left: -30px;
    }
    
    .form-check-login > input {
        border: 2px #0885C7 solid;
    }
    
    .form-check-login > input:checked {
        border: 2px #0885C7 solid;
        background-color: #0885C7;
    }
    
    .form-links-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 6px;
    }
    
    .form-links-login:hover {
        cursor: pointer;
        opacity: 0.8;
        text-decoration: underline;
    }
    
    .form-button-login {
        color: white;
        background-color: #0885C7;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        width: 100%;
        margin-top: 20px !important;
    }

    .form-button-login:hover {
        background-color: #0675b0;
    }
    
    .navbar-toggler {
        border: inherit !important;
    }
    
    .navbar-toggler:focus {
        box-shadow: inherit !important;
    }

    .navbar-toggler-icon {
        background-image: inherit !important;
    }

    .navbar-collapse {
        background-color: inherit !important;
        height: inherit !important;
        width:  inherit !important;
        margin:  inherit !important;
        padding:  inherit !important;
        --bs-gutter-x:  inherit !important;
        z-index:  inherit !important;
    }
}


@media (min-width: 992px) {
    .sticky-bar {
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text {
        color: white;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link {
        color: white;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link:hover {
        font-weight: 400;
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"] {
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"]:before {
        content: "__";
        left: 17%;
        position: absolute;
        margin-top: -5px;
        font-size: 35px;
        width: 5px;
        color: #FFFFFF;
    }

    
    .sticky-bar-text .dropdown-menu {
        background-color: #FFFFFF;
        border: none;
        border-radius: 0px;
        padding: 0;
        margin-left: 15px;
    }
    .sticky-bar-text .dropdown-item {
        color: #646363;
        padding: 10px
    }
    
    .sticky-bar-text .dropdown-item:hover {
        background-color: #0885C7 !important;
        color: #FFFFFF;
    }
    
    .sticky-bar-text .nav-link {
        padding: 8px 20px !important;
    }

    .dropdown-toggle::after {
        content: url(./src/arrow-link.svg) !important;
        border-top: 0 !important;
        vertical-align: 0px;
    }

    .sticky-bar-text-home .nav-link {
        text-align: left;
        padding: 8px 40px 8px 20px !important;
    }
    
    .logo {
        width: 120px;
        height: auto;
    }
    
    .language-icon {
        width: 40px;
        height: auto;
    }
    
    .arrow-language {
        width: 14px;
        height: auto;
        margin-left: 10px;
    }
    
    .button-language {
        opacity: 1;
    }
    
    .button-language:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .select-language-div {
        position: absolute;
        margin-top: 10px;
        margin-left: -20px;
        width: 120px;
        padding: 20px 20px 0 0;
        background-color: #FFFFFF;
        border-radius: 6px;
    }
    
    .select-language {
        opacity: 1;
        margin-bottom: 20px;
    }
    
    .select-language-row:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-check-lang {
        width: 22px;
        margin-left: 8px;
        margin-top: 3px;
        height: auto;
    }
    

    .user-icon:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .user-login-div {
        position: absolute;
        margin-top: 10px;
        width: 340px;
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 20px;
        margin-left: -300px;
    }
    
    .user-login-div::before {
        content: url(./src/top-arrow.svg);
        margin-top: -30px;
        display: flex;
        margin-left: calc(100% - 22px);
    }
    
    .img-btn-login-icon {
        width: 20px;
        height: auto;
        margin-right: 5px;
        margin-top: -4px;
    }
    
    .user-login-title {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 700;
        margin-bottom: 0;
    }
    
    .user-login-acceso-alert {
        color: #D7282A;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-login {
        padding-left: 50px;
        background-color: #0885C7 !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 37px;
        border: 1px #0885C7 solid;
        color: #FFFFFF;
    }
    
    .form-field-login::placeholder {
        color: rgba(255, 255, 255, 0.60);
    }
    
    .form-field-login:focus {
        color: #FFFFFF;
    }
    
    .form-field-icon {
        display: flex;
        margin-top: -37px;
        background-color: #FFFFFF;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .form-field-row {
        margin-bottom: 10px;
    }
    
    .form-check-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-check-login .form-check-label {
        margin-left: -30px;
    }
    
    .form-check-login > input {
        border: 2px #0885C7 solid;
    }
    
    .form-check-login > input:checked {
        border: 2px #0885C7 solid;
        background-color: #0885C7;
    }
    
    .form-links-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 6px;
    }
    
    .form-links-login:hover {
        cursor: pointer;
        opacity: 0.8;
        text-decoration: underline;
    }
    
    .form-button-login {
        color: white;
        background-color: #0885C7;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        width: 100%;
    }

    .form-button-login:hover {
        background-color: #0675b0;
    }
    
    .navbar-toggler {
        border: inherit !important;
    }
    
    .navbar-toggler:focus {
        box-shadow: inherit !important;
    }

    .navbar-toggler-icon {
        background-image: inherit !important;
    }

    .navbar-collapse {
        background-color: inherit !important;
        height: inherit !important;
        width:  inherit !important;
        margin:  inherit !important;
        padding:  inherit !important;
        --bs-gutter-x:  inherit !important;
        z-index:  inherit !important;
    }
}


@media (min-width: 1200px) {
    .sticky-bar {
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link:hover {
        font-weight: 400;
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"] {
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"]:before {
        content: "__";
        left: 17%;
        position: absolute;
        margin-top: -5px;
        font-size: 35px;
        width: 5px;
        color: #FFFFFF;
    }

    
    .sticky-bar-text .dropdown-menu {
        background-color: #FFFFFF;
        border: none;
        border-radius: 0px;
        padding: 0;
        margin-left: 15px;
    }
    .sticky-bar-text .dropdown-item {
        color: #646363;
        padding: 15px
    }
    
    .sticky-bar-text .dropdown-item:hover {
        background-color: #0885C7 !important;
        color: #FFFFFF;
    }
    
    .sticky-bar-text .nav-link {
        padding: 8px 30px !important;
    }

    .dropdown-toggle::after {
        content: url(./src/arrow-link.svg) !important;
        border-top: 0 !important;
        vertical-align: 0px;
    }

    .sticky-bar-text-home .nav-link {
        text-align: left;
        padding: 8px 40px 8px 20px !important;
    }
    
    .logo {
        width: 150px;
        height: auto;
    }
    
    .language-icon {
        width: 45px;
        height: auto;
    }
    
    .arrow-language {
        width: 14px;
        height: auto;
        margin-left: 10px;
    }
    
    .button-language {
        opacity: 1;
    }
    
    .button-language:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .select-language-div {
        position: absolute;
        margin-top: 10px;
        margin-left: 0px;
        width: 120px;
        padding: 20px 20px 0 0;
        background-color: #FFFFFF;
        border-radius: 6px;
    }
    
    .select-language {
        opacity: 1;
        margin-bottom: 20px;
    }
    
    .select-language-row:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-check-lang {
        width: 22px;
        margin-left: 8px;
        margin-top: 3px;
        height: auto;
    }
    
    .user-icon:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .user-login-div {
        position: absolute;
        margin-top: 10px;
        width: 340px;
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 20px;
        margin-left: -300px;
    }
    
    .user-login-div::before {
        content: url(./src/top-arrow.svg);
        margin-top: -30px;
        display: flex;
        margin-left: calc(100% - 22px);
    }
    
    .img-btn-login-icon {
        width: 20px;
        height: auto;
        margin-right: 5px;
        margin-top: -4px;
    }
    
    .user-login-title {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 700;
        margin-bottom: 0;
    }
    
    .user-login-acceso-alert {
        color: #D7282A;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-login {
        padding-left: 50px;
        background-color: #0885C7 !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 37px;
        border: 1px #0885C7 solid;
        color: #FFFFFF;
    }
    
    .form-field-login::placeholder {
        color: rgba(255, 255, 255, 0.60);
    }
    
    .form-field-login:focus {
        color: #FFFFFF;
    }
    
    .form-field-icon {
        display: flex;
        margin-top: -37px;
        background-color: #FFFFFF;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .form-field-row {
        margin-bottom: 10px;
    }
    
    .form-check-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-check-login .form-check-label {
        margin-left: -30px;
    }
    
    .form-check-login > input {
        border: 2px #0885C7 solid;
    }
    
    .form-check-login > input:checked {
        border: 2px #0885C7 solid;
        background-color: #0885C7;
    }
    
    .form-links-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 6px;
    }
    
    .form-links-login:hover {
        cursor: pointer;
        opacity: 0.8;
        text-decoration: underline;
    }
    
    .form-button-login {
        color: white;
        background-color: #0885C7;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        width: 100%;
    }

    .form-button-login:hover {
        background-color: #0675b0;
    }
    
    .navbar-toggler {
        border: inherit !important;
    }
    
    .navbar-toggler:focus {
        box-shadow: inherit !important;
    }

    .navbar-toggler-icon {
        background-image: inherit !important;
    }

    .navbar-collapse {
        background-color: inherit !important;
        height: inherit !important;
        width:  inherit !important;
        margin:  inherit !important;
        padding:  inherit !important;
        --bs-gutter-x:  inherit !important;
        z-index:  inherit !important;
    }
}

@media (min-width: 1400px) {
    .sticky-bar {
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link:hover {
        font-weight: 400;
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"] {
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"]:before {
        content: "__";
        left: 17%;
        position: absolute;
        margin-top: -5px;
        font-size: 35px;
        width: 5px;
        color: #FFFFFF;
    }

    
    .sticky-bar-text .dropdown-menu {
        background-color: #FFFFFF;
        border: none;
        border-radius: 0px;
        padding: 0;
        margin-left: 15px;
    }
    .sticky-bar-text .dropdown-item {
        color: #646363;
        padding: 15px
    }
    
    .sticky-bar-text .dropdown-item:hover {
        background-color: #0885C7 !important;
        color: #FFFFFF;
    }
    
    .sticky-bar-text .nav-link {
        padding: 8px 30px !important;
    }

    .dropdown-toggle::after {
        content: url(./src/arrow-link.svg) !important;
        border-top: 0 !important;
        vertical-align: 0px;
    }

    .sticky-bar-text-home .nav-link {
        text-align: left;
        padding: 8px 40px 8px 20px !important;
    }
    
    .logo {
        width: 170px;
        height: auto;
    }
    
    .language-icon {
        width: 45px;
        height: auto;
    }
    
    .arrow-language {
        width: 12px;
        height: auto;
        margin-left: 10px;
    }
    
    .button-language {
        opacity: 1;
    }
    
    .button-language:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .select-language-div {
        position: absolute;
        margin-top: 10px;
        margin-left: 0px;
        width: 120px;
        padding: 20px 20px 0 0;
        background-color: #FFFFFF;
        border-radius: 6px;
    }
    
    .select-language {
        opacity: 1;
        margin-bottom: 20px;
    }
    
    .select-language-row:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-check-lang {
        width: 22px;
        margin-left: 8px;
        margin-top: 3px;
        height: auto;
    }
    
    .user-icon:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .user-login-div {
        position: absolute;
        margin-top: 10px;
        width: 340px;
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 20px;
        margin-left: -300px;
    }
    
    .user-login-div::before {
        content: url(./src/top-arrow.svg);
        margin-top: -30px;
        display: flex;
        margin-left: calc(100% - 22px);
    }
    
    .img-btn-login-icon {
        width: 20px;
        height: auto;
        margin-right: 5px;
        margin-top: -4px;
    }
    
    .user-login-title {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 700;
        margin-bottom: 0;
    }
    
    .user-login-acceso-alert {
        color: #D7282A;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-login {
        padding-left: 50px;
        background-color: #0885C7 !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 37px;
        border: 1px #0885C7 solid;
        color: #FFFFFF;
    }
    
    .form-field-login::placeholder {
        color: rgba(255, 255, 255, 0.60);
    }
    
    .form-field-login:focus {
        color: #FFFFFF;
    }
    
    .form-field-icon {
        display: flex;
        margin-top: -37px;
        background-color: #FFFFFF;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .form-field-row {
        margin-bottom: 10px;
    }
    
    .form-check-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-check-login .form-check-label {
        margin-left: -30px;
    }
    
    .form-check-login > input {
        border: 2px #0885C7 solid;
    }
    
    .form-check-login > input:checked {
        border: 2px #0885C7 solid;
        background-color: #0885C7;
    }
    
    .form-links-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 6px;
    }
    
    .form-links-login:hover {
        cursor: pointer;
        opacity: 0.8;
        text-decoration: underline;
    }
    
    .form-button-login {
        color: white;
        background-color: #0885C7;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        width: 100%;
    }

    .form-button-login:hover {
        background-color: #0675b0;
    }
    
    .navbar-toggler {
        border: inherit !important;
    }
    
    .navbar-toggler:focus {
        box-shadow: inherit !important;
    }

    .navbar-toggler-icon {
        background-image: inherit !important;
    }

    .navbar-collapse {
        background-color: inherit !important;
        height: inherit !important;
        width:  inherit !important;
        margin:  inherit !important;
        padding:  inherit !important;
        --bs-gutter-x:  inherit !important;
        z-index:  inherit !important;
    }
}

@media (min-width: 1600px) {
    .sticky-bar {
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link {
        color: white;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    
    .sticky-bar-text .nav-link:hover {
        font-weight: 400;
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"] {
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    }
    
    .sticky-bar-text .nav-link[aria-expanded="true"]:before {
        content: "__";
        left: 17%;
        position: absolute;
        margin-top: -5px;
        font-size: 35px;
        width: 5px;
        color: #FFFFFF;
    }

    
    .sticky-bar-text .dropdown-menu {
        background-color: #FFFFFF;
        border: none;
        border-radius: 0px;
        padding: 0;
        margin-left: 15px;
    }
    .sticky-bar-text .dropdown-item {
        color: #646363;
        padding: 20px
    }
    
    .sticky-bar-text .dropdown-item:hover {
        background-color: #0885C7 !important;
        color: #FFFFFF;
    }
    
    .sticky-bar-text .nav-link {
        padding: 8px 30px !important;
    }

    .dropdown-toggle::after {
        content: url(./src/arrow-link.svg) !important;
        border-top: 0 !important;
        vertical-align: 0px;
    }

    .sticky-bar-text-home .nav-link {
        text-align: left;
        padding: 8px 40px 8px 20px !important;
    }
    
    .logo {
        width: 200px;
        height: auto;
    }
    
    .language-icon {
        width: 60px;
        height: auto;
    }
    
    .arrow-language {
        width: 14px;
        height: auto;
        margin-left: 10px;
    }
    
    .button-language {
        opacity: 1;
    }
    
    .button-language:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .select-language-div {
        position: absolute;
        margin-top: 10px;
        margin-left: 0px;
        width: 120px;
        padding: 20px 20px 0 0;
        background-color: #FFFFFF;
        border-radius: 6px;
    }
    
    .select-language {
        opacity: 1;
        margin-bottom: 20px;
    }
    
    .select-language-row:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    
    .img-check-lang {
        width: 22px;
        margin-left: 8px;
        margin-top: 3px;
        height: auto;
    }
    
    .user-icon:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    
    .user-login-div {
        position: absolute;
        margin-top: 10px;
        width: 340px;
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 20px;
        margin-left: -300px;
    }
    
    .user-login-div::before {
        content: url(./src/top-arrow.svg);
        margin-top: -30px;
        display: flex;
        margin-left: calc(100% - 22px);
    }
    
    .img-btn-login-icon {
        width: 20px;
        height: auto;
        margin-right: 5px;
        margin-top: -4px;
    }
    
    .user-login-title {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 700;
        margin-bottom: 0;
    }
    
    .user-login-acceso-alert {
        color: #D7282A;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field-login {
        padding-left: 50px;
        background-color: #0885C7 !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 37px;
        border: 1px #0885C7 solid;
        color: #FFFFFF;
    }
    
    .form-field-login::placeholder {
        color: rgba(255, 255, 255, 0.60);
    }
    
    .form-field-login:focus {
        color: #FFFFFF;
    }
    
    .form-field-icon {
        display: flex;
        margin-top: -37px;
        background-color: #FFFFFF;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .form-field-row {
        margin-bottom: 10px;
    }
    
    .form-check-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-check-login .form-check-label {
        margin-left: -30px;
    }
    
    .form-check-login > input {
        border: 2px #0885C7 solid;
    }
    
    .form-check-login > input:checked {
        border: 2px #0885C7 solid;
        background-color: #0885C7;
    }
    
    .form-links-login {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 6px;
    }
    
    .form-links-login:hover {
        cursor: pointer;
        opacity: 0.8;
        text-decoration: underline;
    }
    
    .form-button-login {
        color: white;
        background-color: #0885C7;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        width: 100%;
    }

    .form-button-login:hover {
        background-color: #0675b0;
    }
    
    .navbar-toggler {
        border: inherit !important;
    }
    
    .navbar-toggler:focus {
        box-shadow: inherit !important;
    }

    .navbar-toggler-icon {
        background-image: inherit !important;
    }

    .navbar-collapse {
        background-color: inherit !important;
        height: inherit !important;
        width:  inherit !important;
        margin:  inherit !important;
        padding:  inherit !important;
        --bs-gutter-x:  inherit !important;
        z-index:  inherit !important;
    }

    .container {
        max-width: 1620px;
        margin-top:  10px;
    }
}
