
.row-margin {
    margin-top: 5px;
}

.back-home-button {
    height: 50px;
    width: 45px;
    background-color: #FF0000;
    position: fixed;
    top: 25%;
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 8px 0 0 0;
    text-align: center;
    align-items: center;
    display: grid;
}

.back-home-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.back-home-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.back-home-icon {
    width: 28px;
    height: auto;
}

@media (min-width: 576px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .back-home-button {
        height: 50px;
        width: 45px;
        background-color: #FF0000;
        position: fixed;
        top: 25%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 10px 10px 0px;
        opacity: 1;
        padding: 8px 0 0 0;
        text-align: center;
        align-items: center;
        display: grid;
    }
    
    .back-home-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .back-home-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: none;
    }
    
    .back-home-icon {
        width: 30px;
        height: auto;
    }
}

@media (min-width: 768px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .back-home-button {
        height: 50px;
        width: 45px;
        background-color: #FF0000;
        position: fixed;
        top: 25%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 10px 10px 0px;
        opacity: 1;
        padding: 8px 0 0 0;
        text-align: center;
        align-items: center;
        display: grid;
    }
    
    .back-home-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .back-home-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: none;
    }
    
    .back-home-icon {
        width: 30px;
        height: auto;
    }
}

@media (min-width: 992px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .back-home-button {
        height: 90px;
        width: 60px;
        background-color: #FF0000;
        position: fixed;
        top: 40%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
        text-align: center;
        align-items: center;
        display: grid;
    }
    
    .back-home-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .back-home-text {
        color: #FFFFFF;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: inherit !important;
    }

    .back-home-icon {
        width: 26px;
        height: auto;
    }
}

@media (min-width: 1200px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .back-home-button {
        height: 100px;
        width: 70px;
        background-color: #FF0000;
        position: fixed;
        top: 40%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
        text-align: center;
        align-items: center;
        display: grid;
    }
    
    .back-home-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .back-home-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: inherit !important;
    }

    .back-home-icon {
        width: 32px;
        height: auto;
    }
}

@media (min-width: 1400px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .back-home-button {
        height: 120px;
        width: 85px;
        background-color: #FF0000;
        position: fixed;
        top: 40%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
        text-align: center;
        align-items: center;
        display: grid;
    }
    
    .back-home-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .back-home-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: inherit !important;
    }

    .back-home-icon {
        width: 38px;
        height: auto;
    }
}

@media (min-width: 1600px) {
    .row-margin {
        margin-top: 5px;
    }
    
    .back-home-button {
        height: 140px;
        width: 100px;
        background-color: #FF0000;
        position: fixed;
        top: 40%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
        text-align: center;
        align-items: center;
        display: grid;
    }
    
    .back-home-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .back-home-text {
        color: #FFFFFF;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: inherit !important;
    }

    .back-home-icon {
        width: 44px;
        height: auto;
    }
}