
.div-chat {
    position: fixed;
    top: 80%;
    right: 0%;
    z-index: 10;
    opacity: 1;
}

.div-chat:hover {
    opacity: 0.9;
    cursor: pointer;
}