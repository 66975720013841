.TerminosCondiciones {
    text-align: center;
}

.div-terminos-condiciones-pdf-s {
    width: 100%;
}

.div-terminos-condiciones-pdf-body {
    width: 100%;
}

.div-terminos-condiciones-pdf-viewer {
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 100vh;
}

