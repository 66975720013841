
.unselectable {
    display: none;
}

.slider-viajes {
    width: 100%;
    height: auto;
    color: black;
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 600;
}

.slider-image {
    width: 100%;
}

@media only screen and (max-width: 600px) {

    .slider-viajes {
        width: 100%;
        height: 250px;
        color: black;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 600;
    }

    .slider-image {
        width: 100%;
        height: 740px;
        object-fit: cover;

    }

    .react-calendar{
        width:80%;
    }

}

.slider-top-shadow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    margin-top: 64px;
    background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
}

.carousel-control-next, .carousel-control-prev {
    top: 80% !important;
}

.busqueda-viajes {
    position: absolute;
    top: 0%;
    left: 10%;
    width: 80%;
    height: auto;
    border-radius: 24px;
    margin-top: 164px;
    padding: 20px 30px;
    background: rgba(255, 255, 255, 0.90); 
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.43);
}

.busqueda-viajes-off {
    margin-top: 100px !important;
}

.busqueda-viajes-title {
    font-size: 16px;
    font-weight: 800;
    color: #0885C7;
}

.busqueda-viajes-fechas {
    font-size: 14px;
    font-weight: 600;
    color: #0885C7;
    margin: 15px 0px 0px 0px;
    text-align: left;
}

.busqueda-viajes-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #0885C7;
}

.check-vehiculo .form-check-input {
    width: 70px;
    height: 26px;
}

.check-vehiculo .form-check-input:checked {
    background-color: #0885C7;
}

.check-vehiculo-checked .form-check-label {
    color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 20px;
    display: flex;
    margin-left: -20px;
    margin-top: 5px;
    position: absolute;
}

.check-vehiculo-unchecked .form-check-label {
    color: #0885C7;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 20px;
    display: flex;
    margin-left: 10px;
    margin-top: 5px;
    position: absolute;
}

.form-col-padleft {
    text-align: left;
    padding-left: 3px;
}

.form-col-padright {
    text-align: left;
    padding-right: 3px;
}

.form-field {
    border-radius: 6px; 
    border: 1px rgba(8, 133, 199, 0.60) solid;
    height: 36px;
    color: #64636399;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
}

.form-field::placeholder {
    color: #64636399;
}

.button-select-active {
    border: none;
    background-color: rgba(8, 133, 199, 0.25);
    color: #0885C7;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 20px;
    width: 95%;
}

.button-select-active:hover {
    background-color: #0675b0;
}

.button-select-inactive {
    border: none;
    background-color: rgba(8, 133, 199, 0);
    color: #0885C7;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 20px;
    width: 95%;
}

.button-select-inactive:hover {
    background-color: #0675b0;
}

.button-confirm {
    border: none;
    background-color: #0885C7;
    color: white;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.button-confirm:hover {
    background-color: #0675b0;
}

.calendarIcon {
    width: 24px;
    height: auto;
    position: absolute;
    margin-top: 7px;
    right: 12%;
}

.calendarIcon:hover {
    cursor: pointer;
}

.floating-calendar {
    z-index: 100;
    position: fixed;
    width: 350px;
}

.react-calendar {
    border-radius: 6px;
    font-family: 'Lato';
}

.react-calendar__navigation {
    font-size: 14px;
    font-weight: 400;
    color: #0885C7;
}

.react-calendar__navigation__label {
    color: #0885C7;
}

.react-calendar__navigation__arrow {
    color: #0885C7;
}

.react-calendar__month-view__weekdays {
    font-size: 10px;
    font-weight: 400;
    color: #0885C7;
}

.react-calendar__month-view__days {
    font-size: 12px;
    font-weight: 400;
}

.carousel-control-prev {
    display: none !important;
}

.carousel-control-next {
    display: none !important;
}
    
.carousel-indicators {
    display: none !important;
}

.ida-vuelta-dates {
    margin: 0px;
    margin-top: 5px;
    margin-left: 5px;
    color: #64636399;
}

.ida-vuelta-icons {
    width: 20px;
    height: auto;
    margin-right: 5px;
    margin-top: -4px;
}

.card-ida-vuelta {
    margin: 10px 0px;
    width: 100%;
    border: 1px solid #0885C799;
    border-radius: 6px;
    height: 46px;
    /*padding: 20px 10px; */
    background-color: #FFFFFF;
    text-align: left;   
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 400;
}

.calendar-datepicker {
    margin-left: calc(50% - 128px);
}

.rmdp-range {
    background-color: #0885C766 !important;
    box-shadow: none !important;
} 

.start > span {
    background-color: #0885C7 !important;
    top: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
} 

.end > span {
    background-color: #0885C7 !important;
    top: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
} 

@media (min-width: 568px) {
    
}

@media (min-width: 768px) {
    .unselectable {
        display: none;
    }
    
    .slider-viajes {
        width: 100%;
        height: auto;
        color: black;
        font-family: 'Lato';
        font-size: 11px;
        font-weight: 600;
    }
    
    .slider-image {
        width: 100%;
    }
    
    .slider-top-shadow {
        position: absolute;
        top: 0;
        width: 100%;
        height: 200px;
        margin-top: 64px;
        background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
    }
    
    .carousel-control-next, .carousel-control-prev {
        top: 80% !important;
    }
    
    .busqueda-viajes {
        position: absolute;
        top: 0%;
        left: 15%;
        width: 45%;
        height: auto;
        border-radius: 24px;
        margin-top: 124px;
        padding: 20px 30px;
        background: rgba(255, 255, 255, 0.90); 
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.43);
        z-index: 20;
    }
    
    .busqueda-viajes-off {
        margin-top: 60px !important;
    }
    
    .busqueda-viajes-title {
        font-size: 12px;
        font-weight: 800;
        color: #0885C7;
    }

    .busqueda-viajes-fechas {
        font-size: 12px;
        font-weight: 600;
        color: #0885C7;
        margin: 10px 0px 0px 0px;
        text-align: left;
    }
    
    .busqueda-viajes-subtitle {
        font-size: 9px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .check-vehiculo .form-check-input {
        width: 50px;
        height: 20px;
    }
    
    .check-vehiculo .form-check-input:checked {
        background-color: #0885C7;
    }
    
    .check-vehiculo-checked .form-check-label {
        color: white;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 16px;
        display: flex;
        margin-left: -20px;
        margin-top: 5px;
        position: absolute;
    }
    
    .check-vehiculo-unchecked .form-check-label {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 16px;
        display: flex;
        margin-left: -5px;
        margin-top: 5px;
        position: absolute;
    }
    
    .form-col-padleft {
        text-align: left;
        padding-left: 3px;
    }
    
    .form-col-padright {
        text-align: left;
        padding-right: 3px;
    }
    
    .form-field {
        border-radius: 6px; 
        border: 1px rgba(8, 133, 199, 0.60) solid;
        height: 26px;
        color: #64636399;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field::placeholder {
        color: #64636399;
    }
    
    .button-select-active {
        border: none;
        background-color: rgba(8, 133, 199, 0.25);
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        width: 95%;
    }

    .button-select-active:hover {
        background-color: #0675b0;
    }
    
    .button-select-inactive {
        border: none;
        background-color: rgba(8, 133, 199, 0);
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 16px;
        width: 95%;
    }

    .button-select-inactive:hover {
        background-color: #0675b0;
    }
    
    .button-confirm {
        border: none;
        background-color: #0885C7;
        color: white;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .button-confirm:hover {
        background-color: #0675b0;
    }
    
    .calendarIcon {
        width: 20px;
        height: auto;
        position: absolute;
        margin-top: 7px;
        right: 12%;
    }
    
    .calendarIcon:hover {
        cursor: pointer;
    }
    
    .floating-calendar {
        z-index: 100;
        position: fixed;
        width: 350px;
    }
    
    .react-calendar {
        border-radius: 6px;
        font-family: 'Lato';
    }
    
    .react-calendar__navigation {
        font-size: 14px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__navigation__label {
        color: #0885C7;
    }
    
    .react-calendar__navigation__arrow {
        color: #0885C7;
    }
    
    .react-calendar__month-view__weekdays {
        font-size: 10px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__month-view__days {
        font-size: 12px;
        font-weight: 400;
    }

    .carousel-control-prev {
        display: inherit !important;
        opacity: 1;
        margin-left: 20px;
        top: 87% !important
    }

    .carousel-control-prev:hover {
        opacity: 0.7;
    }
    
    .carousel-control-next {
        display: inherit !important;
        opacity: 1;
        top: 87% !important
    }

    .carousel-control-next:hover {
        opacity: 0.7;
    }
    
    .carousel-indicators {
        display: inherit !important;
        opacity: 1;
        top: 90% !important
    }
    
    .carousel-indicators > button {
        border-radius: 50%;
        width: 16px !important;
        height: 16px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

@media (min-width: 992px) {
    .unselectable {
        display: none;
    }
    
    .slider-viajes {
        width: 100%;
        height: auto;
        color: black;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 600;
    }
    
    .slider-image {
        width: 100%;
    }
    
    .slider-top-shadow {
        position: absolute;
        top: 0;
        width: 100%;
        height: 200px;
        margin-top: 64px;
        background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
    }
    
    .carousel-control-next, .carousel-control-prev {
        top: 80% !important;
    }
    
    .busqueda-viajes {
        position: absolute;
        top: 0%;
        left: 15%;
        width: 40%;
        height: auto;
        border-radius: 24px;
        margin-top: 124px;
        padding: 20px 30px;
        background: rgba(255, 255, 255, 0.90); 
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.43);
        z-index: 20;
    }
    
    .busqueda-viajes-off {
        margin-top: 60px !important;
    }
    
    .busqueda-viajes-title {
        font-size: 14px;
        font-weight: 800;
        color: #0885C7;
    }

    .busqueda-viajes-fechas {
        font-size: 12px;
        font-weight: 600;
        color: #0885C7;
        margin: 10px 0px 0px 0px;
        text-align: left;
    }

    .busqueda-viajes-title2{
        padding-left: 5px;
        font-size: 13px;
        font-weight: 800;
        color: #0885C7;
    }
    
    .busqueda-viajes-subtitle {
        font-size: 10px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .check-vehiculo .form-check-input {
        width: 50px;
        height: 22px;
    }
    
    .check-vehiculo .form-check-input:checked {
        background-color: #0885C7;
    }
    
    .check-vehiculo-checked .form-check-label {
        color: white;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        display: flex;
        margin-left: -20px;
        margin-top: 5px;
        position: absolute;
    }
    
    .check-vehiculo-unchecked .form-check-label {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        display: flex;
        margin-left: -5px;
        margin-top: 5px;
        position: absolute;
    }
    
    .form-col-padleft {
        text-align: left;
        padding-left: 3px;
    }
    
    .form-col-padright {
        text-align: left;
        padding-right: 3px;
    }
    
    .form-field {
        border-radius: 6px; 
        border: 1px rgba(8, 133, 199, 0.60) solid;
        height: 30px;
        color: #64636399;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field::placeholder {
        color: #64636399;
    }
    
    .button-select-active {
        border: none;
        background-color: rgba(8, 133, 199, 0.25);
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        width: 95%;
    }

    .button-select-active:hover {
        background-color: #0675b0;
    }
    
    .button-select-inactive {
        border: none;
        background-color: rgba(8, 133, 199, 0);
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        width: 95%;
    }

    .button-select-inactive:hover {
        background-color: #0675b0;
    }
    
    .button-confirm {
        border: none;
        background-color: #0885C7;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 16px;
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .button-confirm:hover {
        background-color: #0675b0;
    }
    
    .calendarIcon {
        width: 20px;
        height: auto;
        position: absolute;
        margin-top: 7px;
        right: 12%;
    }
    
    .calendarIcon:hover {
        cursor: pointer;
    }
    
    .floating-calendar {
        z-index: 100;
        position: fixed;
        width: 350px;
    }
    
    .react-calendar {
        border-radius: 6px;
        font-family: 'Lato';
    }
    
    .react-calendar__navigation {
        font-size: 14px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__navigation__label {
        color: #0885C7;
    }
    
    .react-calendar__navigation__arrow {
        color: #0885C7;
    }
    
    .react-calendar__month-view__weekdays {
        font-size: 10px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__month-view__days {
        font-size: 12px;
        font-weight: 400;
    }

    .carousel-control-prev {
        display: inherit !important;
        opacity: 1;
        margin-left: 20px;
        top: 87% !important
    }

    .carousel-control-prev:hover {
        opacity: 0.7;
    }
    
    .carousel-control-next {
        display: inherit !important;
        opacity: 1;
        top: 87% !important
    }

    .carousel-control-next:hover {
        opacity: 0.7;
    }
    
    .carousel-indicators {
        display: inherit !important;
        opacity: 1;
        top: 90% !important
    }
    
    .carousel-indicators > button {
        border-radius: 50%;
        width: 16px !important;
        height: 16px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

@media (min-width: 1200px) { 
    .unselectable {
        display: none;
    }
    
    .slider-viajes {
        width: 100%;
        height: auto;
        color: black;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 600;
    }
    
    .slider-image {
        width: 100%;
    }
    
    .slider-top-shadow {
        position: absolute;
        top: 0;
        width: 100%;
        height: 200px;
        margin-top: 64px;
        background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
    }
    
    .carousel-control-next, .carousel-control-prev {
        top: 80% !important;
    }
    
    .busqueda-viajes {
        position: absolute;
        top: 0%;
        left: 15%;
        width: 35%;
        height: auto;
        border-radius: 24px;
        margin-top: 164px;
        padding: 20px 30px;
        background: rgba(255, 255, 255, 0.90); 
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.43);
    }
    
    .busqueda-viajes-off {
        margin-top: 100px !important;
    }
    
    .busqueda-viajes-title {
        font-size: 16px;
        font-weight: 800;
        color: #0885C7;
    }

    .busqueda-viajes-fechas {
        font-size: 14px;
        font-weight: 600;
        color: #0885C7;
        margin: 15px 0px 0px 0px;
        text-align: left;
    }
    
    .busqueda-viajes-subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .check-vehiculo .form-check-input {
        width: 70px;
        height: 26px;
    }
    
    .check-vehiculo .form-check-input:checked {
        background-color: #0885C7;
    }
    
    .check-vehiculo-checked .form-check-label {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        display: flex;
        margin-left: -20px;
        margin-top: 5px;
        position: absolute;
    }
    
    .check-vehiculo-unchecked .form-check-label {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        display: flex;
        margin-left: 10px;
        margin-top: 5px;
        position: absolute;
    }
    
    .form-col-padleft {
        text-align: left;
        padding-left: 3px;
    }
    
    .form-col-padright {
        text-align: left;
        padding-right: 3px;
    }
    
    .form-field {
        border-radius: 6px; 
        border: 1px rgba(8, 133, 199, 0.60) solid;
        height: 36px;
        color: #64636399;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field::placeholder {
        color: #64636399;
    }
    
    .button-select-active {
        border: none;
        background-color: rgba(8, 133, 199, 0.25);
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        width: 95%;
    }

    .button-select-active:hover {
        background-color: #0675b0;
    }
    
    .button-select-inactive {
        border: none;
        background-color: rgba(8, 133, 199, 0);
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        width: 95%;
    }

    .button-select-inactive:hover {
        background-color: #0675b0;
    }
    
    .button-confirm {
        border: none;
        background-color: #0885C7;
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .button-confirm:hover {
        background-color: #0675b0;
    }
    
    .calendarIcon {
        width: 24px;
        height: auto;
        position: absolute;
        margin-top: 7px;
        right: 12%;
    }
    
    .calendarIcon:hover {
        cursor: pointer;
    }
    
    .floating-calendar {
        z-index: 100;
        position: fixed;
        width: 350px;
    }
    
    .react-calendar {
        border-radius: 6px;
        font-family: 'Lato';
    }
    
    .react-calendar__navigation {
        font-size: 14px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__navigation__label {
        color: #0885C7;
    }
    
    .react-calendar__navigation__arrow {
        color: #0885C7;
    }
    
    .react-calendar__month-view__weekdays {
        font-size: 10px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__month-view__days {
        font-size: 12px;
        font-weight: 400;
    }

    .carousel-control-prev {
        display: inherit !important;
        opacity: 1;
        margin-left: 20px;
        top: 87% !important
    }

    .carousel-control-prev:hover {
        opacity: 0.7;
    }
    
    .carousel-control-next {
        display: inherit !important;
        opacity: 1;
        top: 87% !important
    }

    .carousel-control-next:hover {
        opacity: 0.7;
    }
    
    .carousel-indicators {
        display: inherit !important;
        opacity: 1;
        top: 90% !important
    }
    
    .carousel-indicators > button {
        border-radius: 50%;
        width: 16px !important;
        height: 16px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

@media (min-width: 1400px) { 
    .unselectable {
        display: none;
    }
    
    .slider-viajes {
        width: 100%;
        height: auto;
        color: black;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 600;
    }
    
    .slider-image {
        width: 100%;
    }
    
    .slider-top-shadow {
        position: absolute;
        top: 0;
        width: 100%;
        height: 200px;
        margin-top: 64px;
        background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
    }
    
    .carousel-control-next, .carousel-control-prev {
        top: 80% !important;
    }
    
    .busqueda-viajes {
        position: absolute;
        top: 0%;
        left: 15%;
        width: 25%;
        height: auto;
        border-radius: 24px;
        margin-top: 164px;
        padding: 20px 30px;
        background: rgba(255, 255, 255, 0.90); 
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.43);
    }
    
    .busqueda-viajes-off {
        margin-top: 100px !important;
    }
    
    .busqueda-viajes-title {
        font-size: 16px;
        font-weight: 800;
        color: #0885C7;
    }

    .busqueda-viajes-fechas {
        font-size: 14px;
        font-weight: 600;
        color: #0885C7;
        margin: 15px 0px 0px 0px;
        text-align: left;
    }
    
    .busqueda-viajes-subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .check-vehiculo .form-check-input {
        width: 70px;
        height: 26px;
    }
    
    .check-vehiculo .form-check-input:checked {
        background-color: #0885C7;
    }
    
    .check-vehiculo-checked .form-check-label {
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        display: flex;
        margin-left: -20px;
        margin-top: 5px;
        position: absolute;
    }
    
    .check-vehiculo-unchecked .form-check-label {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        display: flex;
        margin-left: 10px;
        margin-top: 5px;
        position: absolute;
    }
    
    .form-col-padleft {
        text-align: left;
        padding-left: 3px;
    }
    
    .form-col-padright {
        text-align: left;
        padding-right: 3px;
    }
    
    .form-field {
        border-radius: 6px; 
        border: 1px rgba(8, 133, 199, 0.60) solid;
        height: 36px;
        color: #64636399;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field::placeholder {
        color: #64636399;
    }
    
    .button-select-active {
        border: none;
        background-color: rgba(8, 133, 199, 0.25);
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        width: 95%;
    }

    .button-select-active:hover {
        background-color: #0675b0;
    }
    
    .button-select-inactive {
        border: none;
        background-color: rgba(8, 133, 199, 0);
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        width: 95%;
    }

    .button-select-inactive:hover {
        background-color: #0675b0;
    }
    
    .button-confirm {
        border: none;
        background-color: #0885C7;
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .button-confirm:hover {
        background-color: #0675b0;
    }
    
    .calendarIcon {
        width: 24px;
        height: auto;
        position: absolute;
        margin-top: 7px;
        right: 12%;
    }
    
    .calendarIcon:hover {
        cursor: pointer;
    }
    
    .floating-calendar {
        z-index: 100;
        position: fixed;
        width: 350px;
    }
    
    .react-calendar {
        border-radius: 6px;
        font-family: 'Lato';
    }
    
    .react-calendar__navigation {
        font-size: 14px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__navigation__label {
        color: #0885C7;
    }
    
    .react-calendar__navigation__arrow {
        color: #0885C7;
    }
    
    .react-calendar__month-view__weekdays {
        font-size: 10px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__month-view__days {
        font-size: 12px;
        font-weight: 400;
    }

    .carousel-control-prev {
        display: inherit !important;
        opacity: 1;
        margin-left: 20px;
        top: 87% !important
    }

    .carousel-control-prev:hover {
        opacity: 0.7;
    }
    
    .carousel-control-next {
        display: inherit !important;
        opacity: 1;
        top: 87% !important
    }

    .carousel-control-next:hover {
        opacity: 0.7;
    }
    
    .carousel-indicators {
        display: inherit !important;
        opacity: 1;
        top: 90% !important
    }
    
    .carousel-indicators > button {
        border-radius: 50%;
        width: 16px !important;
        height: 16px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

@media (min-width: 1600px) { 
    .unselectable {
        display: none;
    }
    
    .slider-viajes {
        width: 100%;
        height: auto;
        color: black;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 600;
    }
    
    .slider-image {
        width: 100%;
    }
    
    .slider-top-shadow {
        position: absolute;
        top: 0;
        width: 100%;
        height: 200px;
        margin-top: 64px;
        background-image: linear-gradient(0deg, rgba(39, 36, 37, 0) 0%, rgba(50, 50, 50, 1) 100%);
    }
    
    .carousel-control-next, .carousel-control-prev {
        top: 80% !important;
    }
    
    .busqueda-viajes {
        position: absolute;
        top: 0%;
        left: 15%;
        width: 25%;
        height: auto;
        border-radius: 24px;
        margin-top: 204px;
        padding: 20px 30px;
        background: rgba(255, 255, 255, 0.90); 
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.43);
    }
    
    .busqueda-viajes-off {
        margin-top: 140px !important;
    }
    
    .busqueda-viajes-title {
        font-size: 20px;
        font-weight: 800;
        color: #0885C7;
    }

    .busqueda-viajes-fechas {
        font-size: 16px;
        font-weight: 600;
        color: #0885C7;
        margin: 15px 0px 0px 0px;
        text-align: left;
    }
    
    .busqueda-viajes-subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .check-vehiculo .form-check-input {
        width: 80px;
        height: 36px;
    }
    
    .check-vehiculo .form-check-input:checked {
        background-color: #0885C7;
    }
    
    .check-vehiculo-checked .form-check-label {
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        display: flex;
        margin-left: -20px;
        margin-top: 5px;
        position: absolute;
    }
    
    .check-vehiculo-unchecked .form-check-label {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        display: flex;
        margin-left: 10px;
        margin-top: 5px;
        position: absolute;
    }
    
    .form-col-padleft {
        text-align: left;
        padding-left: 3px;
    }
    
    .form-col-padright {
        text-align: left;
        padding-right: 3px;
    }
    
    .form-field {
        border-radius: 6px; 
        border: 1px rgba(8, 133, 199, 0.60) solid;
        height: 46px;
        color: #64636399;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
    }
    
    .form-field::placeholder {
        color: #64636399;
    }
    
    .button-select-active {
        border: none;
        background-color: rgba(8, 133, 199, 0.25);
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        width: 95%;
    }

    .button-select-active:hover {
        background-color: #0675b0;
    }
    
    .button-select-inactive {
        border: none;
        background-color: rgba(8, 133, 199, 0);
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 30px;
        width: 95%;
    }

    .button-select-inactive:hover {
        background-color: #0675b0;
    }
    
    .button-confirm {
        border: none;
        background-color: #0885C7;
        color: white;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 20px;
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .button-confirm:hover {
        background-color: #0675b0;
    }
    
    .calendarIcon {
        width: 32px;
        height: auto;
        position: absolute;
        margin-top: 8px;
        right: 10%;
    }
    
    .calendarIcon:hover {
        cursor: pointer;
    }
    
    .floating-calendar {
        z-index: 100;
        position: fixed;
        width: 350px;
    }
    
    .react-calendar {
        border-radius: 6px;
        font-family: 'Lato';
    }
    
    .react-calendar__navigation {
        font-size: 16px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__navigation__label {
        color: #0885C7;
    }
    
    .react-calendar__navigation__arrow {
        color: #0885C7;
    }
    
    .react-calendar__month-view__weekdays {
        font-size: 12px;
        font-weight: 400;
        color: #0885C7;
    }
    
    .react-calendar__month-view__days {
        font-size: 14px;
        font-weight: 400;
    }

    .carousel-control-prev {
        display: inherit !important;
        opacity: 1;
        margin-left: 20px;
        top: 87% !important
    }

    .carousel-control-prev:hover {
        opacity: 0.7;
    }
    
    .carousel-control-next {
        display: inherit !important;
        opacity: 1;
        top: 87% !important
    }

    .carousel-control-next:hover {
        opacity: 0.7;
    }
    
    .carousel-indicators {
        display: inherit !important;
        opacity: 1;
        top: 90% !important
    }
    
    .carousel-indicators > button {
        border-radius: 50%;
        width: 20px !important;
        height: 20px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
    
}