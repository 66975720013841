.PreguntasFrecuentes {
    text-align: center;
}

.div-preguntas-frecuentes-s {
    width: 100%;
}

.row-preguntas-frecuentes-s {
    text-align: left;
}

.img-preguntas-frecuentes-s {
    width: 100%;
    height: auto;
}

.div-preguntas-frecuentes-body {
    width: 100%;
    margin: 100px 0px 150px 0px;
}

.title-preguntas-frecuentes-s {
    color: #0885C7;
    font-size: 22px;
    font-family: 'Lato';
    font-weight: 600;
}

.p-preguntas-frecuentes-s {
    color: black;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
}

.r-preguntas-frecuentes-s {
    color: rgba(100, 99, 99, 0.60);
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
    font-style: italic;
}

.modalp-preguntas-frecuentes-s {
    color: #646363;
    font-size: 10px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
}

.subp-preguntas-frecuentes-s {
    color: #0885C7;
    font-size: 20px;
    font-family: 'Lato';
    font-weight: 600;
    text-align: center;
    margin-top: -40px;
    margin-bottom: 40px;
}

.row-content-pgs-s {
    margin-bottom: 40px;
    text-align: justify;
}

.link-preguntas-frecuentes-s {
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    color: rgba(100, 99, 99, 0.60);;
}

.link-preguntas-frecuentes-s:hover {
    cursor: pointer;
    opacity: 0.9;
}

.link-tyc-preguntas-frecuentes-s {
    color: #0885C7;
    font-size: 12px;
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
}

.link-tyc-preguntas-frecuentes-s:hover {
    cursor: pointer;
    opacity: 0.9;
}

.modal-content {
    border-radius: 28px;
    padding: 50px;
}

.img-modal-close-pf {
    margin-left: calc(100% - 5px);
}

.img-modal-close-pf:hover {
    opacity: 0.9;
    cursor: pointer;
}

@media (min-width: 576px) {

    .title-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 26px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-preguntas-frecuentes-s {
        color: black;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-preguntas-frecuentes-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-preguntas-frecuentes-s {
        color: #646363;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .subp-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: -40px;
        margin-bottom: 40px;
    }
    
    .row-content-pgs-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .link-preguntas-frecuentes-s {
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        color: rgba(100, 99, 99, 0.60);;
    }
    
    .link-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .link-tyc-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 12px;
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
    }
    
    .link-tyc-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .modal-content {
        border-radius: 28px;
        padding: 50px;
    }
    
    .img-modal-close-pf {
        margin-left: calc(100% - 5px);
    }
    
    .img-modal-close-pf:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

@media (min-width: 768px) {

    .title-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 30px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-preguntas-frecuentes-s {
        color: black;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-preguntas-frecuentes-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-preguntas-frecuentes-s {
        color: #646363;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .subp-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 22px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: -40px;
        margin-bottom: 40px;
    }
    
    .row-content-pgs-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .link-preguntas-frecuentes-s {
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        color: rgba(100, 99, 99, 0.60);;
    }
    
    .link-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .link-tyc-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
    }
    
    .link-tyc-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .modal-content {
        border-radius: 28px;
        padding: 50px;
    }
    
    .img-modal-close-pf {
        margin-left: calc(100% - 5px);
    }
    
    .img-modal-close-pf:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

@media (min-width: 992px) {

    .title-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 34px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-preguntas-frecuentes-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-preguntas-frecuentes-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-preguntas-frecuentes-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .subp-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: -40px;
        margin-bottom: 40px;
    }
    
    .row-content-pgs-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .link-preguntas-frecuentes-s {
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        color: rgba(100, 99, 99, 0.60);;
    }
    
    .link-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .link-tyc-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
    }
    
    .link-tyc-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .modal-content {
        border-radius: 28px;
        padding: 50px;
    }
    
    .img-modal-close-pf {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-pf:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

@media (min-width: 1200px) {

    .title-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-preguntas-frecuentes-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-preguntas-frecuentes-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-preguntas-frecuentes-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .subp-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: -40px;
        margin-bottom: 40px;
    }
    
    .row-content-pgs-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .link-preguntas-frecuentes-s {
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        color: rgba(100, 99, 99, 0.60);;
    }
    
    .link-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .link-tyc-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
    }
    
    .link-tyc-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .modal-content {
        border-radius: 28px;
        padding: 50px;
    }
    
    .img-modal-close-pf {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-pf:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

@media (min-width: 1400px) {

    .title-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-preguntas-frecuentes-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-preguntas-frecuentes-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-preguntas-frecuentes-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .subp-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: -40px;
        margin-bottom: 40px;
    }
    
    .row-content-pgs-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .link-preguntas-frecuentes-s {
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        color: rgba(100, 99, 99, 0.60);;
    }
    
    .link-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .link-tyc-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
    }
    
    .link-tyc-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .modal-content {
        border-radius: 28px;
        padding: 50px;
    }
    
    .img-modal-close-pf {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-pf:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

@media (min-width: 1600px) {

    .title-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
    }
    
    .p-preguntas-frecuentes-s {
        color: black;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .r-preguntas-frecuentes-s {
        color: rgba(100, 99, 99, 0.60);
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
    }
    
    .modalp-preguntas-frecuentes-s {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
    }
    
    .subp-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 25px;
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: -40px;
        margin-bottom: 40px;
    }
    
    .row-content-pgs-s {
        margin-bottom: 40px;
        text-align: justify;
    }
    
    .link-preguntas-frecuentes-s {
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        color: rgba(100, 99, 99, 0.60);;
    }
    
    .link-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .link-tyc-preguntas-frecuentes-s {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
    }
    
    .link-tyc-preguntas-frecuentes-s:hover {
        cursor: pointer;
        opacity: 0.9;
    }
    
    .modal-content {
        border-radius: 28px;
        padding: 50px;
    }
    
    .img-modal-close-pf {
        margin-left: calc(100% - 40px);
    }
    
    .img-modal-close-pf:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}