
.estadocruce-header {
    height: 64px;
    width: 100%;
    color: black;
    font-family: 'Lato';
    font-size: 8px;
    font-weight: 600;
    padding: 15px 10px;
}

.estadocruce-header .cruces {
    color: #0885C7;
    transition: 1s;
    bottom: 0;
    animation: animatebottom 0.5s;
}

.estadocruce-header-hidden {
    visibility: hidden;
}

@keyframes animatebottom {
    from { opacity: 0 } 
    to { opacity: 1 } 
}

.icono-estado {
    margin-top: -15px;
    width: 20px;
    height: 20px;
}

.icono-col {
    display: "flex";
    justify-content:'right';
    text-align: right;
}

@media (min-width: 576px) {
    .estadocruce-header {
        height: 64px;
        width: 100%;
        color: black;
        font-family: 'Lato';
        font-size: 9px;
        font-weight: 600;
        padding: 15px 10px;
    }
    
    .estadocruce-header .cruces {
        color: #0885C7;
        transition: 1s;
        bottom: 0;
        animation: animatebottom 0.5s;
    }
    
    .estadocruce-header-hidden {
        visibility: hidden;
    }
    
    @keyframes animatebottom {
        from { opacity: 0 } 
        to { opacity: 1 } 
    }
    
    .icono-estado {
        margin-top: -15px;
        width: 20px;
        height: 20px;
    }
    
    .icono-col {
        display: "flex";
        justify-content:'right';
        text-align: right;
    }
}

@media (min-width: 768px) {
    .estadocruce-header {
        height: 64px;
        width: 100%;
        color: black;
        font-family: 'Lato';
        font-size: 10px;
        font-weight: 600;
        padding: 15px 10px;
    }
    
    .estadocruce-header .cruces {
        color: #0885C7;
        transition: 1s;
        bottom: 0;
        animation: animatebottom 0.5s;
    }
    
    .estadocruce-header-hidden {
        visibility: hidden;
    }
    
    @keyframes animatebottom {
        from { opacity: 0 } 
        to { opacity: 1 } 
    }
    
    .icono-estado {
        margin-top: -15px;
        width: 20px;
        height: 20px;
    }
    
    .icono-col {
        display: "flex";
        justify-content:'right';
        text-align: right;
    }
}

@media (min-width: 992px) {
    .estadocruce-header {
        height: 64px;
        width: 100%;
        color: black;
        font-family: 'Lato';
        font-size: 10px;
        font-weight: 600;
        padding: 15px 10px;
    }
    
    .estadocruce-header .cruces {
        color: #0885C7;
        transition: 1s;
        bottom: 0;
        animation: animatebottom 0.5s;
    }
    
    .estadocruce-header-hidden {
        visibility: hidden;
    }
    
    @keyframes animatebottom {
        from { opacity: 0 } 
        to { opacity: 1 } 
    }
    
    .icono-estado {
        margin-top: -15px;
        width: 20px;
        height: 20px;
    }
    
    .icono-col {
        display: "flex";
        justify-content:'right';
        text-align: right;
    }
}

@media (min-width: 1200px) {
    .estadocruce-header {
        height: 64px;
        width: 100%;
        color: black;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 600;
        padding: 10px;
    }
    
    .estadocruce-header .cruces {
        color: #0885C7;
        transition: 1s;
        bottom: 0;
        animation: animatebottom 0.5s;
    }
    
    .estadocruce-header-hidden {
        visibility: hidden;
    }
    
    @keyframes animatebottom {
        from { opacity: 0 } 
        to { opacity: 1 } 
    }
    
    .icono-estado {
        margin-top: -15px;
        width: 20px;
        height: 20px;
    }
    
    .icono-col {
        display: "flex";
        justify-content:'right';
        text-align: right;
    }
}

@media (min-width: 1400px) {
    .estadocruce-header {
        height: 64px;
        width: 100%;
        color: black;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 600;
        padding: 10px;
    }
    
    .estadocruce-header .cruces {
        color: #0885C7;
        transition: 1s;
        bottom: 0;
        animation: animatebottom 0.5s;
    }
    
    .estadocruce-header-hidden {
        visibility: hidden;
    }
    
    @keyframes animatebottom {
        from { opacity: 0 } 
        to { opacity: 1 } 
    }
    
    .icono-estado {
        margin-top: -15px;
        width: 20px;
        height: 20px;
    }
    
    .icono-col {
        display: "flex";
        justify-content:'right';
        text-align: right;
    }
}

@media (min-width: 1600px) {
    .estadocruce-header {
        height: 64px;
        width: 100%;
        color: black;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 600;
        padding: 10px;
    }
    
    .estadocruce-header .cruces {
        color: #0885C7;
        transition: 1s;
        bottom: 0;
        animation: animatebottom 0.5s;
    }
    
    .estadocruce-header-hidden {
        visibility: hidden;
    }
    
    @keyframes animatebottom {
        from { opacity: 0 } 
        to { opacity: 1 } 
    }
    
    .icono-estado {
        margin-top: -15px;
        width: 20px;
        height: 20px;
    }
    
    .icono-col {
        display: "flex";
        justify-content:'right';
        text-align: right;
    }
}