
.div-servicios {
    padding-top: 540px;
    padding-bottom: 40px;
}

.div-listado-servicios {
    padding-top: 20px;
    padding-bottom: 0px;
}

.title-servicios {
    color: #0885C7;
    font-size: 23px;
    font-family: 'Lato';
    font-weight: 600;
    line-height: 20px;
}

.subtitle-servicios {
    color: #646363;
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 300;
    display: none;
}

.line-servicios {
    background-color: #0885C7;
    width: 80px;
    height: 6px;
}

.col-card-servicio {
    margin-bottom: 35px;
}

.card-servicio {
    width: 320px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 0px !important;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
    border: none;
    text-align: left;
    margin-left: calc(50% - 160px);
}

.card-servicio:hover {
    opacity: 0.9;
    cursor: pointer;
}

.img-servicio {
    width: 100%;
    height: auto;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.button-servicio {
    width: 302px;
    color: white;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
    background-color: #0885C7;
    border: none;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.img-button {
    cursor: pointer;
}

.img-button:hover {
    opacity: 0.8;
}
 
.p-nombre-servicio {
    color: #0885C7;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    margin-bottom: 5px;
}

.p-nombre-servicio .p-b {
    font-weight: 600;
}

.p-detalle-servicio {
    color: #646363;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: 300;
    margin-bottom: 5px;
}

.p-nave-servicio {
    color: #646363;
    font-size: 9px;
    font-family: 'Lato';
    font-weight: 300;
    margin-bottom: 0;
}

.div-detalle-servicio {
    margin: 15px;
}

@media (min-width: 576px) {
    .div-servicios {
        padding-top: 140px;
        padding-bottom: 40px;
    }
    
    .div-listado-servicios {
        padding-top: 40px;
        padding-bottom: 0px;
        margin-bottom: -20px;
    }
    
    .title-servicios {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 20px;
    }
    
    .subtitle-servicios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 300;
        display: block;
    }
    
    .line-servicios {
        background-color: #0885C7;
        width: 80px;
        height: 6px;
    }
    
    .col-card-servicio {
        margin-bottom: 75px;
    }
    
    .card-servicio {
        width: 330px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 0px !important;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        border: none;
        text-align: left;
        margin-left: calc(50% - 165px);
    }
    
    .card-servicio:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-servicio {
        width: 100%;
        height: auto;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    
    .button-servicio {
        width: 302px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: #0885C7;
        border: none;
        border-radius: 8px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .img-button {
        cursor: pointer;
    }

    .img-button-left {
        margin-left: calc(100% - 30px);
    }
    
    .img-button:hover {
        opacity: 0.8;
    }
     
    .p-nombre-servicio {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 5px;
    }
    
    .p-nombre-servicio .p-b {
        font-weight: 600;
    }
    
    .p-detalle-servicio {
        color: #646363;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 5px;
    }
    
    .p-nave-servicio {
        color: #646363;
        font-size: 9px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 0;
    }
    
    .div-detalle-servicio {
        margin: 15px;
    }
}

@media (min-width: 768px) {
    .div-servicios {
        padding-top: 260px;
        padding-bottom: 40px;
    }
    
    .div-listado-servicios {
        padding-top: 40px;
        padding-bottom: 0px;
        margin-bottom: -20px;
    }
    
    .title-servicios {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 20px;
    }
    
    .subtitle-servicios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 300;
        display: block;
    }
    
    .line-servicios {
        background-color: #0885C7;
        width: 80px;
        height: 6px;
    }
    
    .col-card-servicio {
        margin-bottom: 75px;
    }
    
    .card-servicio {
        width: 330px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 0px !important;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        border: none;
        text-align: left;
        margin-left: calc(50% - 165px);
    }
    
    .card-servicio:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-servicio {
        width: 100%;
        height: auto;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    
    .button-servicio {
        width: 302px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: #0885C7;
        border: none;
        border-radius: 8px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .img-button {
        cursor: pointer;
    }

    .img-button-left {
        margin-left: calc(100% - 30px);
    }
    
    .img-button:hover {
        opacity: 0.8;
    }
     
    .p-nombre-servicio {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 5px;
    }
    
    .p-nombre-servicio .p-b {
        font-weight: 600;
    }
    
    .p-detalle-servicio {
        color: #646363;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 5px;
    }
    
    .p-nave-servicio {
        color: #646363;
        font-size: 9px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 0;
    }
    
    .div-detalle-servicio {
        margin: 15px;
    }
}

@media (min-width: 992px) {
    .div-servicios {
        padding-top: 140px;
        padding-bottom: 40px;
    }
    
    .div-listado-servicios {
        padding-top: 40px;
        padding-bottom: 0px;
        margin-bottom: -20px;
    }
    
    .title-servicios {
        color: #0885C7;
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 20px;
    }
    
    .subtitle-servicios {
        color: #646363;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 300;
        display: block;
    }
    
    .line-servicios {
        background-color: #0885C7;
        width: 80px;
        height: 6px;
    }
    
    .col-card-servicio {
        margin-bottom: 75px;
    }
    
    .card-servicio {
        width: 280px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 0px !important;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        border: none;
        text-align: left;
        margin-left: 0px;
    }
    
    .card-servicio:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-servicio {
        width: 100%;
        height: auto;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    
    .button-servicio {
        width: 302px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: #0885C7;
        border: none;
        border-radius: 8px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .img-button {
        cursor: pointer;
    }

    .img-button-left {
        margin-left: calc(100% - 30px);
    }
    
    .img-button:hover {
        opacity: 0.8;
    }
     
    .p-nombre-servicio {
        color: #0885C7;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 5px;
    }
    
    .p-nombre-servicio .p-b {
        font-weight: 600;
    }
    
    .p-detalle-servicio {
        color: #646363;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 5px;
    }
    
    .p-nave-servicio {
        color: #646363;
        font-size: 9px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 0;
    }
    
    .div-detalle-servicio {
        margin: 15px;
    }
}

@media (min-width: 1200px) {
    .div-servicios {
        padding-top: 140px;
        padding-bottom: 40px;
    }
    
    .div-listado-servicios {
        padding-top: 40px;
        padding-bottom: 0px;
        margin-bottom: -20px;
    }
    
    .title-servicios {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 20px;
    }
    
    .subtitle-servicios {
        color: #646363;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 300;
        display: block;
    }
    
    .line-servicios {
        background-color: #0885C7;
        width: 80px;
        height: 6px;
    }
    
    .col-card-servicio {
        margin-bottom: 75px;
    }
    
    .card-servicio {
        width: 330px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 0px !important;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        border: none;
        text-align: left;
        margin-left: 0px;
    }
    
    .card-servicio:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-servicio {
        width: 100%;
        height: auto;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    
    .button-servicio {
        width: 302px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: #0885C7;
        border: none;
        border-radius: 8px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .img-button {
        cursor: pointer;
    }

    .img-button-left {
        margin-left: calc(100% - 30px);
    }
    
    .img-button:hover {
        opacity: 0.8;
    }
     
    .p-nombre-servicio {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 5px;
    }
    
    .p-nombre-servicio .p-b {
        font-weight: 600;
    }
    
    .p-detalle-servicio {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 5px;
    }
    
    .p-nave-servicio {
        color: #646363;
        font-size: 9px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 0;
    }
    
    .div-detalle-servicio {
        margin: 15px;
    }
}

@media (min-width: 1400px) { 
    .div-servicios {
        padding-top: 140px;
        padding-bottom: 40px;
    }
    
    .div-listado-servicios {
        padding-top: 40px;
        padding-bottom: 0px;
        margin-bottom: -20px;
    }
    
    .title-servicios {
        color: #0885C7;
        font-size: 40px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 20px;
    }
    
    .subtitle-servicios {
        color: #646363;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 300;
        display: block;
    }
    
    .line-servicios {
        background-color: #0885C7;
        width: 80px;
        height: 6px;
    }
    
    .col-card-servicio {
        margin-bottom: 75px;
    }
    
    .card-servicio {
        width: 350px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 0px !important;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        border: none;
        text-align: left;
        margin-left: 0px;
    }
    
    .card-servicio:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-servicio {
        width: 100%;
        height: auto;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    
    .button-servicio {
        width: 302px;
        color: white;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: #0885C7;
        border: none;
        border-radius: 8px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .img-button {
        cursor: pointer;
    }

    .img-button-left {
        margin-left: calc(100% - 30px);
    }
    
    .img-button:hover {
        opacity: 0.8;
    }
     
    .p-nombre-servicio {
        color: #0885C7;
        font-size: 16px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 5px;
    }
    
    .p-nombre-servicio .p-b {
        font-weight: 600;
    }
    
    .p-detalle-servicio {
        color: #646363;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 5px;
    }
    
    .p-nave-servicio {
        color: #646363;
        font-size: 9px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 0;
    }
    
    .div-detalle-servicio {
        margin: 15px;
    }
}

@media (min-width: 1600px) { 
    .div-servicios {
        padding-top: 140px;
        padding-bottom: 40px;
    }
    
    .div-listado-servicios {
        padding-top: 40px;
        padding-bottom: 0px;
        margin-bottom: -20px;
    }
    
    .title-servicios {
        color: #0885C7;
        font-size: 48px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 20px;
    }
    
    .subtitle-servicios {
        color: #646363;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 300;
        display: block;
    }
    
    .line-servicios {
        background-color: #0885C7;
        width: 80px;
        height: 6px;
    }
    
    .col-card-servicio {
        margin-bottom: 75px;
    }
    
    .card-servicio {
        width: 350px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 0px !important;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        border: none;
        text-align: left;
        margin-left: 0px;
    }
    
    .card-servicio:hover {
        opacity: 0.9;
        cursor: pointer;
    }
    
    .img-servicio {
        width: 100%;
        height: auto;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
    
    .button-servicio {
        width: 302px;
        color: white;
        font-size: 18px;
        font-family: 'Lato';
        font-weight: 400;
        line-height: 20px;
        background-color: #0885C7;
        border: none;
        border-radius: 8px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .img-button {
        cursor: pointer;
    }

    .img-button-left {
        margin-left: calc(100% - 30px);
    }
    
    .img-button:hover {
        opacity: 0.8;
    }
     
    .p-nombre-servicio {
        color: #0885C7;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 400;
        margin-bottom: 5px;
    }
    
    .p-nombre-servicio .p-b {
        font-weight: 600;
    }
    
    .p-detalle-servicio {
        color: #646363;
        font-size: 15px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 5px;
    }
    
    .p-nave-servicio {
        color: #646363;
        font-size: 9px;
        font-family: 'Lato';
        font-weight: 300;
        margin-bottom: 0;
    }
    
    .div-detalle-servicio {
        margin: 15px;
    }
}